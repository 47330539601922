import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';

import Tools from '../tools';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	typeUserName: string;
	userName: string;
	username: string;

	homeOptions = Tools.homeOptions;
	homeOptionsDown = Tools.homeOptionsDown;
	options = [];
	optionsTop = [];
	optionsDown = [];

	constructor(private activeRoute: ActivatedRoute, private authServ: AuthService, private router: Router) {
		// USER DATA FROM DB
		this.username = localStorage.getItem("username");
		this.typeUserName = localStorage.getItem("typeUserName");
		this.setOptions(this.typeUserName);
	}

	ngOnInit() {

	}

	onSelectionInit() {
		for (const i in this.options) {
			let opcion = this.options[i].position;
			document.getElementById(opcion).classList.remove("selectedOption");
		}
		this.router.navigate(['/home/userProfile']);
	}


	setOptions(tipoUsuario) {
		this.homeOptions.forEach(element => {
			element.rol.forEach(rol => {
				if (localStorage.getItem('name') == "DPA Valle") {
					if (element['desc'] != "Profesores") {
						(rol == tipoUsuario) ? this.options.push(element) : null;
						(rol == tipoUsuario) ? this.optionsTop.push(element) : null;
					}
				} else {
					(rol == tipoUsuario) ? this.options.push(element) : null;
					(rol == tipoUsuario) ? this.optionsTop.push(element) : null;
				}
			});
		});
		this.homeOptionsDown.forEach(element => {
			element.rol.forEach(rol => {
				(rol == tipoUsuario) ? this.options.push(element) : null;
				(rol == tipoUsuario) ? this.optionsDown.push(element) : null;
			});
		});
	}

	// recibir(any:any){
	// console.log("lo que llego: ", any);
	// }

	onSelectOption(optionIndex: any) {
		if (optionIndex == 9) {
			this.router.navigate(['/home/download']);
		} else if (optionIndex == 10) {
			this.onExit();
		} else {
			for (const i in this.options) {
				let opcion = this.options[i].position;
				opcion == optionIndex ? document.getElementById(opcion).classList.add("selectedOption") : document.getElementById(opcion).classList.remove("selectedOption");
			}
			let params: NavigationExtras = {
				queryParams: {
					"option": optionIndex
				}
			};
			let option = Tools.homeOptions[optionIndex];
			this.router.navigate(['/home/' + option.ruta], params);
		}
	}

	onExit() {
		for (const i in this.options) {
			let opcion = this.options[i].position;
			document.getElementById(opcion).classList.remove("selectedOption");
		}
		var self = this;
		Tools.mail = null;
		Tools.institution = null;
		localStorage.removeItem("username");
		localStorage.clear();
		self.router.navigate(['/login']);
	
	}

	onDownload() {
		this.router.navigate(['/home/download']);
	}

	onMenu_toggle() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		document.getElementById("menu").classList.toggle("ocultar");
		document.getElementById("menu-black-background").classList.toggle("hide");
	}

}
