import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import Tools from '../tools';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {

  //** Inputs */
  parameter: string = "";
  title: string = "Profesores";
  queryParams: any = "";
  data: any[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    Tools.showMask();
    try {
      let params: NavigationExtras = {
        queryParams: {
          "from": "teachers",
          "id": 3,
          "name": "Profesores"
        }
      };
      this.router.navigate(['/home/chart'], params);
      // this.listService.getProjects(localStorage.getItem('name')).subscribe(result => {
      //   console.log("result", result['project']);
      //   this.data = result['project'];
      //   this.parameter = "name";
      //   this.queryParams = {
      //     "parameter": ['id', 'name'],
      //     "from": "projects"
      //   }
      // });
    } catch (error) {
      console.log("Error", error);
      Tools.hideMask();
    }
    Tools.hideMask();
  }

}
