import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	template: '<mask></mask><router-outlet></router-outlet>'
	//  template: '<app-chart (sendJson)="recibir($event)"></app-chart>'

})

export class AppComponent {
	title = 'Desafíos para la inclusión';
	
}

