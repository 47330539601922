import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'popupcarga',
    templateUrl: 'popupcarga.html',
  })
  export class Popupcarga {

    accept : Boolean = true;
    cancel : Boolean = false;
  
    constructor(
      public dialogRef: MatDialogRef<Popupcarga>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  }