import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import Tools from '../tools';
import { isUndefined } from 'util';

// import * as jsPDF from 'jspdf';
declare var jsPDF: any; // Important

@Component({
  selector: 'app-list-estudents',
  templateUrl: './list-estudents.component.html',
  styleUrls: ['./list-estudents.component.scss']
})
export class ListEstudentsComponent implements OnInit {
  excelInput: any;
  fileInput: any;
  excelReader: FileReader;
  title: String;
  option: number;
  allIds: Array<String>;
  activeIds: Array<String>;
  auxIds: Array<String>;
  pageSize: number = 10;
  length: number;
  searchText: string = '';
  pageEvent: PageEvent;
  titleList:string= 'Listado de estudiantes';
  image:string = '../../assets/logo.png';

  condition:boolean=false;

  students = [

    { id: 1, name: 'Juan Camilo', lastName: 'Trillos', username: 'JCTRILLOS', headquarters:' Sede 1' },
    { id: 2, name: 'Luis Fernando', lastName: 'Jojoa', username: 'LFJOJOA', headquarters:' Sede 1' },
    { id: 3, name: 'Juan Sebastian', lastName: 'Correa', username: 'JSCORREA' , headquarters:' Sede 1'},
    { id: 4, name: 'Claudia Catalina', lastName: 'Londoño', username: 'CCLONDOÑO', headquarters:' Sede 1' },
    { id: 5, name: 'Daniel Alejandro', lastName: 'Martínez', username: 'DAMARTINEZ', headquarters:' Sede 1' }

  ];
  rol:String;
  sede:String;

  row = [];
  columns = ["Nombre", "Apellido", "Usuario", "Sede"];
  
  
  constructor( private location: Location, 
    private authServ: AuthService, private router: Router, 
    private activeRoute: ActivatedRoute, public dialog: MatDialog) { 
      this.activeRoute.queryParams.subscribe(params => {
        console.log("Parametros",params);
        
    
        this.students.forEach(element => {
          let PrimerNombre = element.name;
          let PrimerApellido = element.lastName;
          let alias = element.username;
          let sede = element.headquarters;
          if (!isUndefined(alias)) {
            this.row.push([PrimerNombre, PrimerApellido, alias, sede]);
          }

          // console.log("instituciones : ", institucion);
          // console.log("Alias : ", alias);
          // console.log("nombre completo: ", PrimerNombre, "-", SegundoNombre, "-", PrimerApellido, "-", SegundoApellido);
        });
        // this.option = Tools.homeOptions[params.option];
        console.log("PARAMETROS ->",params.queryParams);
        this.rol= params.queryParams[1];
        this.sede = params.queryParams[0];
      });
      
    }
    compareInstitution(user,item,rol){
      return Tools.compareInstitution(user,item,rol);
    }

    ngOnInit() {
      // let self = this;
      // Tools.showMask();
      //   this.DBServ.getList('alias', ref => ref.orderByChild('ESTABLECIMIENTO')/*.equalTo(Tools.institution)*/,
      // data => {
      //   // self.condition=true;
      //   let validatedData = [];
      //   // this.condition==true;
      //   for (const key in data) {
      //     if (data.hasOwnProperty(key)) {
      //       const element = data[key];
      //       // console.log("alias nombre:",element.PRI_NOM);
      //       let isMyStudent = this.compareInstitution(this.sede,element.CONS_SEDE,this.rol);
			// 			(element.PRI_NOM!=undefined && isMyStudent)?validatedData.push(element):null;
      //       // (element.PRI_NOM != undefined) ? validatedData.push(element) : null;
      //     }
      //   }
      //   console.log("esto es data : ",data);
        
      //   validatedData.forEach(element => {
      //     let PrimerNombre = element.PRI_NOM;
      //     let SegundoNombre = element.SEG_NOM;
      //     let PrimerApellido = element.PRI_APE;
      //     let SegundoApellido = element.SEG_APE;
      //     let alias = element.alias;
      //     let institucion = element.ESTABLECIMIENTO;
      //     if (!isUndefined(alias)) {
      //       this.row.push([PrimerNombre, SegundoNombre, PrimerApellido, SegundoApellido, alias, institucion]);
      //     }
  
      //     // console.log("instituciones : ", institucion);
      //     // console.log("Alias : ", alias);
      //     // console.log("nombre completo: ", PrimerNombre, "-", SegundoNombre, "-", PrimerApellido, "-", SegundoApellido);
      //   });
      //   // this.setStudentList(validatedData);
      //   // this.setStudentList(data);
      //   // self.printListStudent();
      //   Tools.hideMask();
      // });

    // console.log("este es el condicional: ", self.condition);

    // this.printListStudent();
  }

 

  printListStudent(this:any){
    document.getElementById('button-print').classList.add('hidebutton');
    // window.print();
    window.print();
    // window.close();
    this.location.back();
  }

  // setStudentList(studentsData: Object) {
  //   var self = this,
  //     allIds = Object.keys(studentsData),
  //     length = allIds.length;
  //   console.log("studentdata:", studentsData);
  //   console.log("allids:", allIds);
  //   self.activeIds = allIds.slice(0, self.pageSize);
  //   if (self.pageEvent) {
  //     self.pageEvent.pageIndex = 0;
  //   }
  //   self.allIds = allIds;
  //   self.auxIds = allIds;
  //   self.students = studentsData;
  //   self.length = length;
    
  // }
  

  downloadListStudent(){
    console.log("esto tiene row: ", this.row);
    let doc = new jsPDF('p', 'pt');
    // jsPDFAPI.addImage = function (imageData, format, x, y, w, h, alias, compression, rotation)
    

    // let image = doc.addImage(this.image, 'PNG', 40, 20);
    let splitTitle = doc.splitTextToSize(this.titleList, 180);
    doc.text(40, 30, splitTitle);

    doc.autoTable(this.columns, this.row,{
      // theme: 'striped', // 'striped', 'grid' or 'plain'
      styles:{
        cellPadding: 5, // a number, array or object (see margin below)
        fontSize: 6,
        font: "helvetica", // helvetica, times, courier
        lineColor: 200,
        lineWidth: 0,
        fontStyle: 'normal', // normal, bold, italic, bolditalic
        overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
        // fillColor: [92,111,254], // false for transparent or a color as described below
        // textColor: 20,
        halign: 'center', // left, center, right
        valign: 'middle', // top, middle, bottom
        columnWidth: 'auto' // 'auto', 'wrap' or a number
      }
    });
    // doc.text(this.titleList);
    doc.save('Lista de estudiantes CityKnowPro.pdf');
    this.location.back();

  }
}
