import { InstitutionsComponent } from './../institutions/institutions.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
declare var jsPDF: any; // Important
import { Location } from '@angular/common';
import { Chart } from 'chart.js';
import Tools from '../tools';


// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"


import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-get-report',
  templateUrl: './get-report.component.html',
  styleUrls: ['./get-report.component.scss']
})
export class GetReportComponent implements OnInit {

  @ViewChild('content') content: ElementRef;
  checked1: boolean = true;
  bigJson: Object;

  public recivedDataFromChart: any;
  //for chart compare
  chart: any = []; // This will hold our chart info
  chartArrayGeneralLC = [];
  chartArrayGeneralRC = [];
  chartArrayGeneralCE = [];
  labelArrayGeneral = [];
  LabelObjectKey = [];
  ///----------------------------
  indeterminate: boolean = false;
  playerHavePlayedForHTM = "";
  // checked: Object = {
  //     "elnombre":false,
  //     "elnombre2":true
  // };
  checkedInstitution: Object = {};

  //for competences
  checkedCompetences: Object = {
    lc: true,
    rc: true,
    ce: true
  }

  //for data town cycles
  checkedCyclesTown: Object = {
    cycleTotal: false,
    cycle2: false,
    cycle3: false,
    cycle4: false,
    cycle5: false,
    cycle6: false
  }

  //for town students
  checkedListStudentTown: Object = {
    generalTown: false,
    diagnostic: false,
    advance1: false,
    advance2: false,
    advance3: false,
    advance4: false,
    advance5: false,
    advance6: false,
    test: false,
    generalTest: false
  }

  //for other options
  checkedOtherOptions: object = {
    separateCompetences: true,
    comparedCompetences: false
  }

  //for button state
  state: boolean = true;
  /// this
  self = this;
  ////-------------------------------
  townName: string;
  institutionsTowns: any[];
  institutionTownCycles: any[];
  institutionKey: any[];
  generalTown: any[];
  diagnostic: any[];
  advance1: any[];
  advance2: any[];
  advance3: any[];
  advance4: any[];
  advance5: any[];
  advance6: any[];
  generalTest: any[];
  test: any[];
  cycleTotals: any[];
  townCycle2: any[];
  townCycle3: any[];
  townCycle4: any[];
  townCycle5: any[];
  townCycle6: any[];
  ///-------------------------------------
  institutionAll: any = [];
  //Variables que utilizo para imprimir en el html

  chartInstitution: any[];
  objectKey: any[];

  totalPlayer: number;
  playerHavedPlayed: number;
  responseChartTown: any[];

  generalColumns = [
    { text: 'Institución', style: 'tableHeader', alignment: 'center' },
    { text: 'Sede', style: 'tableHeader', alignment: 'center' },
    { text: 'Grupo', style: 'tableHeader', alignment: 'center' },
    { text: 'Juego', style: 'tableHeader', alignment: 'center' },
    { text: 'Nombre', style: 'tableHeader', alignment: 'center' },
    { text: 'Apellido', style: 'tableHeader', alignment: 'center' },
    { text: 'Competencia', style: 'tableHeader', alignment: 'center' },
    { text: 'Tipo', style: 'tableHeader', alignment: 'center' }
  ];

  columnsAdvance = [
    { text: 'Institución', style: 'tableHeader', alignment: 'center' },
    { text: 'Sede', style: 'tableHeader', alignment: 'center' },
    { text: 'Grupo', style: 'tableHeader', alignment: 'center' },
    { text: 'Ciclo', style: 'tableHeader', alignment: 'center' },
    { text: 'Nombre', style: 'tableHeader', alignment: 'center' },
    { text: 'Apellido', style: 'tableHeader', alignment: 'center' },
    { text: 'Lectura Crítica', style: 'tableHeader', alignment: 'center' },
    { text: 'Razonamiento Cuantitativo', style: 'tableHeader', alignment: 'center' },
    { text: 'Comunicación Escrita', style: 'tableHeader', alignment: 'center' }
  ];


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location, ) { }

  recibir(algo: any) {
    let self = this;
    this.townName = algo["name"];
    this.institutionsTowns = algo["institutionTowns"];
    this.institutionTownCycles = algo["institutionTownCycles"];
    this.institutionKey = Object.keys(this.institutionTownCycles);
    for (let p in this.institutionTownCycles) {
      if (this.institutionTownCycles.hasOwnProperty(p)) {
        let element = this.institutionTownCycles[p];
        let cycles = {};
        element.forEach(cycle => {
          cycles["c" + cycle.cycleId] = false;
        });
        let code = null;
        this.institutionsTowns.forEach(element => {
          if (p == element["institutionName"]) {
            code = element["institutionCode"];
          }
        });
        self.checkedInstitution[p] = {
          "institutionCode": code,
          "check": false,
          "indeterminate": false,
          "cycles": cycles
        };
      }
    }
    this.generalTown = algo["general"];
    this.diagnostic = algo["diagnostico"];
    this.advance1 = algo["avance1"];
    this.advance2 = algo["avance2"];
    this.advance3 = algo["avance3"];
    this.advance4 = algo["avance4"];
    this.advance5 = algo["avance5"];
    this.advance6 = algo["avance6"];
    this.test = algo["evaluacion"];
    this.generalTest = algo["evaluacionGeneral"];
    this.cycleTotals = algo["cycleTotals"];
    if (parseInt(this.cycleTotals["Cycle2"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle2"]["Competence2"]) > 0
      || parseInt(this.cycleTotals["Cycle2"]["Competence3"]) > 0) {
      this.townCycle2 = this.cycleTotals["Cycle2"];
    } else {
      this.townCycle2 = null;
    }
    if (parseInt(this.cycleTotals["Cycle3"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle3"]["Competence2"]) > 0
      || parseInt(this.cycleTotals["Cycle3"]["Competence3"]) > 0) {
      this.townCycle3 = this.cycleTotals["Cycle3"];
    } else {
      this.townCycle3 = null;
    }
    if (parseInt(this.cycleTotals["Cycle4"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle4"]["Competence2"]) > 0
      || parseInt(this.cycleTotals["Cycle4"]["Competence3"]) > 0) {
      this.townCycle4 = this.cycleTotals["Cycle4"];
    } else {
      this.townCycle4 = null;
    }
    if (parseInt(this.cycleTotals["Cycle5"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle5"]["Competence2"]) > 0
      || parseInt(this.cycleTotals["Cycle5"]["Competence3"]) > 0) {
      this.townCycle5 = this.cycleTotals["Cycle5"];
    } else {
      this.townCycle5 = null;
    }
    if (parseInt(this.cycleTotals["Cycle6"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle6"]["Competence2"]) > 0
      || parseInt(this.cycleTotals["Cycle6"]["Competence3"]) > 0) {
      this.townCycle6 = this.cycleTotals["Cycle6"];
    } else {
      this.townCycle6 = null;
    }    //----------------------------------------------- 
  }

  ngOnInit() {
    this.recivedDataFromChart = JSON.parse(window.localStorage.getItem('bigJson'));
    window.localStorage.removeItem('bigJson');
    console.log(this.recivedDataFromChart);
    this.recibir(this.recivedDataFromChart);
  }

  onSelectInstitutionName(evt: any, institutionName: any) {
    for (let p in this.checkedInstitution[institutionName].cycles) {
      if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
        this.checkedInstitution[institutionName].cycles[p] = (evt == true) ? true : false;
      }
      // console.log("el evento: ",evt);
    }
    this.allState();
  }

  onSelectInstitutionCycle(institutionName: any) {
    let countTrue = 0;
    for (const p in this.checkedInstitution[institutionName].cycles) {
      if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
        countTrue = (this.checkedInstitution[institutionName].cycles[p] == true) ? countTrue + 1 : countTrue;
      }
    }
    if (countTrue == 0) {
      this.checkedInstitution[institutionName].check = false;
      this.checkedInstitution[institutionName].indeterminate = false;
    } else if (countTrue == Object.keys(this.checkedInstitution[institutionName].cycles).length) {
      this.checkedInstitution[institutionName].check = true;
      this.checkedInstitution[institutionName].indeterminate = false;
    } else if (countTrue < Object.keys(this.checkedInstitution[institutionName].cycles).length) {
      this.checkedInstitution[institutionName].check = true;
      this.checkedInstitution[institutionName].indeterminate = true;
    }
    this.allState();
  }
  allState() {
    //ENuso
    if (this.checkedListStudentTown['generalTown'] == true || this.checkedListStudentTown['diagnostic'] == true ||
      this.checkedListStudentTown['advance1'] == true || this.checkedListStudentTown['advance2'] == true ||
      this.checkedListStudentTown['advance3'] == true || this.checkedListStudentTown['advance4'] == true ||
      this.checkedListStudentTown['advance5'] == true || this.checkedListStudentTown['advance6'] == true ||
      this.checkedListStudentTown['test'] == true || this.checkedListStudentTown['generalTest'] == true) {
      this.state = false;

      //Nouso
    } else if (this.checkedCyclesTown['cycleTotal'] || this.checkedCyclesTown['cycle2'] ||
      this.checkedCyclesTown['cycle3'] || this.checkedCyclesTown['cycle4'] ||
      this.checkedCyclesTown['cycle5'] || this.checkedCyclesTown['cycle6'] ||

      //USO
      this.checkedCompetences['lc'] || this.checkedCompetences['rc'] ||
      this.checkedCompetences['ce']) {
      this.state = false;
    } else {
      this.state = true;
    }
    console.log("el state: ", this.state);

    console.log("El json", this.bigJson);

  }

  print() {
    Tools.showMask();
    let listTownStudent: any;
    let playersPerInstitution: any;
    let auxArrayCompareLC = [];
    let auxArrayCompareRC = [];
    let auxArrayCompareCE = [];
    let auxLabelsCompare = [];
    let self = this;
    this.bigJson = {
      "name": this.townName,
      "chartTown": this.checkedCyclesTown,
      "listTownStudent": this.checkedListStudentTown,
      "chartInstitution": this.checkedInstitution,
      "competences": this.checkedCompetences,
      "otherOptions": this.checkedOtherOptions
    }

    console.log("ESTE SI ES EL BIG YEISON: ", this.bigJson);
    // this.authService.getReportData(this.bigJson).subscribe(
    //   result => {
    //     let self = this;
    //     console.log("lo que me mando la mkita: ", result);
    //     this.totalPlayer = result["allPlayers"];
    //     this.playerHavedPlayed = result["playersHavePlayed"];
    //     this.playerHavePlayedForHTM = result["playersHavePlayed"]["generalTown"];
    //     this.responseChartTown = result["chartTown"];
    //     listTownStudent = result["listTownStudent"];
    //     playersPerInstitution = result["playersPerInstitution"];
    //     this.chartInstitution = result["chartInstitution"];
    //     this.objectKey = Object.keys(this.chartInstitution);
    //     for (const institution in this.chartInstitution) {
    //       if (this.chartInstitution.hasOwnProperty(institution)) {
    //         const element = this.chartInstitution[institution];
    //         let c1T = element["cycleTotal"]["Competence1"] * 0.05;
    //         let c2T = element["cycleTotal"]["Competence2"] * 0.05;
    //         let c3T = element["cycleTotal"]["Competence3"] * 0.05;

    //         let colorChart = ["#E87587", "#54BCCF", "#BFCC3D"];
    //         let labelsChart = ["Lectura Crítica", "Comunicación Escrita", "Razonamiento Cuantitativo"];
    //         // console.log("nombre de la institución: ", institution);
    //         // //PARA general institution
    //         // ////////////////////////////////////////////////////////////////////////
    //         this.institutionDataG[institution] = [];
    //         this.institutionDataC2[institution] = [];
    //         this.institutionDataC3[institution] = [];
    //         this.institutionDataC4[institution] = [];
    //         this.institutionDataC5[institution] = [];
    //         this.institutionDataC6[institution] = [];

    //         if (this.checkedCompetences["lc"] == true) {
    //           this.institutionDataG[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] });
    //           this.institutionDataC2[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(element["cycle2"]["Competence1"]) * 0.05] });
    //           this.institutionDataC3[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(element["cycle2"]["Competence1"]) * 0.05] });
    //           this.institutionDataC4[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(element["cycle2"]["Competence1"]) * 0.05] });
    //           this.institutionDataC5[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(element["cycle2"]["Competence1"]) * 0.05] });
    //           this.institutionDataC6[institution].push({ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(element["cycle2"]["Competence1"]) * 0.05] });
    //         }
    //         if (this.checkedCompetences["rc"] == true) {
    //           this.institutionDataG[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] });
    //           this.institutionDataC2[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(element["cycle2"]["Competence2"]) * 0.05] });
    //           this.institutionDataC3[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(element["cycle2"]["Competence2"]) * 0.05] });
    //           this.institutionDataC4[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(element["cycle2"]["Competence2"]) * 0.05] });
    //           this.institutionDataC5[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(element["cycle2"]["Competence2"]) * 0.05] });
    //           this.institutionDataC6[institution].push({ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(element["cycle2"]["Competence2"]) * 0.05] });
    //         }

    //         if (this.checkedCompetences["ce"] == true) {
    //           this.institutionDataG[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] });
    //           this.institutionDataC2[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(element["cycle2"]["Competence3"]) * 0.05] });
    //           this.institutionDataC3[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(element["cycle2"]["Competence3"]) * 0.05] });
    //           this.institutionDataC4[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(element["cycle2"]["Competence3"]) * 0.05] });
    //           this.institutionDataC5[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(element["cycle2"]["Competence3"]) * 0.05] });
    //           this.institutionDataC6[institution].push({ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(element["cycle2"]["Competence3"]) * 0.05] });
    //           console.log("mira ve: ", this.institutionDataG[institution]);
    //         }
    //         this.generateChart('Institución' + institution, colorChart, institution, this.institutionDataG[institution], labelsChart);
    //         auxArrayCompareLC.push([c1T]);
    //         auxArrayCompareRC.push([c2T]);
    //         auxArrayCompareCE.push([c3T]);
    //         auxLabelsCompare.push([institution]);
    //         // self.institutionCompareDataG.push([{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] }])
    //         // this.institutionAll.push([institution, this.institutionDataG, this.institutionDataC2, this.institutionDataC3, this.institutionDataC4, this.institutionDataC5, this.institutionDataC6]);
    //       }
    //     }
    //     console.log("institution data G: ", this.institutionDataG);
    //     this.chartArrayGeneralLC = auxArrayCompareLC;
    //     this.chartArrayGeneralRC = auxArrayCompareRC;
    //     this.chartArrayGeneralCE = auxArrayCompareCE;
    //     this.labelArrayGeneral = auxLabelsCompare;
    //     this.LabelObjectKey = Object.keys(this.labelArrayGeneral);

    //     // if (this.checkedOtherOptions["comparedCompetences"]==true) {

    //     this.generateChart('Lectura Crítica', '#E87587', 'barChartLC', this.chartArrayGeneralLC, this.LabelObjectKey);
    //     this.generateChart('Razonamiento Cuantitativo', '#54BCCF', 'barChartRC', this.chartArrayGeneralRC, this.LabelObjectKey);
    //     this.generateChart('Comunicación Escrita', '#BFCC3D', 'barChartCE', this.chartArrayGeneralCE, this.LabelObjectKey);

    //     // }else{
    //     // document.getElementById("chartTown").style.display="none";
    //     // }

    //     // console.log("aquí van los lectura crítica Comparación General: ", this.chartArrayGeneralLC);
    //     // console.log("aquí van los Razonamiento cuantitativo Comparación General: ", this.chartArrayGeneralRC);
    //     // console.log("aquí van los Comunicación escrita Comparación General: ", this.chartArrayGeneralCE);
    //     // console.log("aquí van los labels para Comparación General: ", this.labelArrayGeneral);
    //     // console.log("aquí van los Object Keys para lectura crítica Comparación General: ", this.LabelObjectKey);

    //     this.downloadListStudentForTown(listTownStudent, playersPerInstitution, this.totalPlayer, this.playerHavedPlayed);
    //     // let c1T = this.responseChartTown["cycleTotal"]["Competence1"] * 0.05;
    //     // let c2T = this.responseChartTown["cycleTotal"]["Competence2"] * 0.05;
    //     // let c3T = this.responseChartTown["cycleTotal"]["Competence3"] * 0.05;
    //     // //gráfica general
    //     // self.townDataG = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
    //     //   { label: "comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
    //     // ];
    //     // // this.generateChart('Comunicación Escrita', '#BFCC3D', 'barChartCE', this.chartArrayGeneralCE, this.LabelObjectKey);
    //     // // console.log("towndataG", self.townDataG);
    //     // // //PARA CICLO 2 TOWNS
    //     // // ////////////////////////////////////////////////////////////////////////
    //     // this.townDataC2 = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(self.responseChartTown["cycle2"]["Competence1"]) * 0.05] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(self.responseChartTown["cycle2"]["Competence2"]) * 0.05] },
    //     //   { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(self.responseChartTown["cycle2"]["Competence3"]) * 0.05] }
    //     // ];
    //     // // console.log("towndataC2", self.townDataC2);
    //     // // //PARA CICLO 3 TOWNS
    //     // // ////////////////////////////////////////////////////////////////////////
    //     // this.townDataC3 = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(self.responseChartTown["cycle3"]["Competence1"]) * 0.05] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(self.responseChartTown["cycle3"]["Competence2"]) * 0.05] },
    //     //   { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(self.responseChartTown["cycle3"]["Competence3"]) * 0.05] }
    //     // ];
    //     // // console.log("towndataC3", self.townDataC3);
    //     // // //PARA CICLO 4 TOWNS
    //     // // ////////////////////////////////////////////////////////////////////////
    //     // this.townDataC4 = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(self.responseChartTown["cycle4"]["Competence1"]) * 0.05] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(self.responseChartTown["cycle4"]["Competence2"]) * 0.05] },
    //     //   { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(self.responseChartTown["cycle4"]["Competence3"]) * 0.05] }
    //     // ];
    //     // // console.log("towndataC4", self.townDataC4);
    //     // // //PARA CICLO 5TOWNS
    //     // // ////////////////////////////////////////////////////////////////////////
    //     // this.townDataC5 = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(self.responseChartTown["cycle5"]["Competence1"]) * 0.05] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(self.responseChartTown["cycle5"]["Competence2"]) * 0.05] },
    //     //   { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(self.responseChartTown["cycle5"]["Competence3"]) * 0.05] }
    //     // ];
    //     // // console.log("towndataC5", self.townDataC5);
    //     // // //PARA CICLO 6 TOWNS
    //     // // ////////////////////////////////////////////////////////////////////////
    //     // this.townDataC6 = [
    //     //   { label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(self.responseChartTown["cycle6"]["Competence1"]) * 0.05] },
    //     //   { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(self.responseChartTown["cycle6"]["Competence2"]) * 0.05] },
    //     //   { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(self.responseChartTown["cycle6"]["Competence3"]) * 0.05] }
    //     // ];
    //     // // console.log("towndataC6", self.townDataC6);
    //     Tools.hideMask();
    //   },
    //   error => {
    //     Tools.hideMask();
    //     console.log(<any>error);
    //     // Tools.hideMask();
    //     // self.authService.showMsg("Error en el inicio de sesión.");
    //   }
    // );
    // document.getElementById("dataTownContent").style.display = "none";
    // document.getElementById("townContent").style.display = "flex";
  }
  //////////////////////////////////Función para las gráficas comparativas//////////////
  generateChart(chartTitle: any, chartColor: any, selectorChart: any, dataChart: any, labelsChart: any) {
    // console.log("lo que me llego de data: ", dataChart);
    // this.chart[selectorChart] = new Chart(selectorChart, {
    this.chart = new Chart(selectorChart, {
      type: 'bar',
      data: {
        labels: labelsChart,
        datasets: [{
          label: chartTitle,
          data: dataChart,
          backgroundColor: chartColor,
          borderColor: chartColor,
          borderWidth: 1
        }]
      },
      options: {
        title: {
          text: chartTitle,
          // text: "Comparación Competencia "+ chartTitle,
          display: true
        },
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: true,
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 5,
              min: 0,
              fontSize: 13,
              callback: function (value, index, values) {

                var valueDesc = '';
                switch (value) {
                  case 5:
                    valueDesc = 'Superior';
                    break;
                  case 4:
                    valueDesc = 'Alto';
                    break;
                  case 3:
                    valueDesc = 'Medio';
                    break;
                  case 2:
                    valueDesc = 'Básico';
                    break;
                  case 1:
                    valueDesc = 'Bajo';
                    break;
                  default:
                    valueDesc = null;
                    break;
                }
                return valueDesc;
              }
            }
          }]
        }
      }
    });
    //selecciono el div en el cuál voy a meter las gráficas
  }


  /////////////////////////////////////////////////////////////////////////////
  //función para imprimir las listas de los estudiantes
  async downloadListStudentForTown(listTownStudent: any, playersPerInstitution: any, totalPlayer: any, playerHavedPlayed: any) {

    Tools.showMask();
    console.log("listTownStudent", listTownStudent);

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let arrDoc: any[] = [];

    let docDefinition: any = {
      content: [
        {
          layout: 'noBorders', // optional
          width: 'auto',
          table: {
            headerRows: 1,
            body: [
              [{
                columns: [
                  { width: '*', text: '' },
                  {
                    image: Tools.header,
                    width: 500,
                    alignment: 'center'
                  },
                  { width: '*', text: '' },
                ]
              }],
              [{
                text: `Informe de municipio ${this.townName}`,
                style: 'header',
                alignment: 'center',
                margin: 5
              }]
            ]
          }
        },
        {
          text: `En el municipio de ${this.townName}, el número total de jugadores fueron ${totalPlayer}.`,
          alignment: 'justify',
          margin: 5
        },
        { width: '*', text: `` },
        { width: '*', text: `` },
        { width: '*', text: `` },
        {
          text: `Listado de jugadores`,
          style: 'header',
          alignment: 'center',
          margin: 5
        },
        { width: '*', text: `` }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'justify'
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black'
        },
        tableRow: {
          fontSize: 8,
          color: 'black'
        }
      }
    };

    if (listTownStudent["advance1"].length > 0) {
      let docDefinitionAux: any = docDefinition;

      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance uno`,
          `Número de jugadores en el avance uno: ${playerHavedPlayed["advance1"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance1"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }

      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];

      for (const student in listTownStudent["advance1"]) {
        if (listTownStudent["advance1"].hasOwnProperty(student)) {
          const element = listTownStudent["advance1"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push(
            [
              { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
              { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
              { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
              { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
              { text: element["firstName"], style: 'tableRow', alignment: 'center' },
              { text: element["surname"], style: 'tableRow', alignment: 'center' },
              { text: element["lc"], style: 'tableRow', alignment: 'center' },
              { text: element["rc"], style: 'tableRow', alignment: 'center' },
              { text: element["ce"], style: 'tableRow', alignment: 'center' }
            ]);
        }
      }
      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 1.pdf` });
    }

    if (listTownStudent["advance2"].length > 0) {
      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance dos`,
          `Número de jugadores en el avance dos: ${playerHavedPlayed["advance2"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance2"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }

      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];

      for (const student in listTownStudent["advance2"]) {
        if (listTownStudent["advance2"].hasOwnProperty(student)) {
          const element = listTownStudent["advance2"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push(
            [
              { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
              { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
              { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
              { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
              { text: element["firstName"], style: 'tableRow', alignment: 'center' },
              { text: element["surname"], style: 'tableRow', alignment: 'center' },
              { text: element["lc"], style: 'tableRow', alignment: 'center' },
              { text: element["rc"], style: 'tableRow', alignment: 'center' },
              { text: element["ce"], style: 'tableRow', alignment: 'center' }
            ]);
        }
      }

      console.log("doc", docDefinitionAux);
      // arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 2.pdf` });
      arrDoc.push(docDefinitionAux);

    }

    if (listTownStudent["advance3"].length > 0) {

      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance tres`,
          `Número de jugadores en el avance tres: ${playerHavedPlayed["advance3"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance3"], style: 'tableRow', alignment: 'center' }
          ]);

        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];

      for (const student in listTownStudent["advance3"]) {
        if (listTownStudent["advance3"].hasOwnProperty(student)) {
          const element = listTownStudent["advance3"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push(
            [
              { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
              { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
              { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
              { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
              { text: element["firstName"], style: 'tableRow', alignment: 'center' },
              { text: element["surname"], style: 'tableRow', alignment: 'center' },
              { text: element["lc"], style: 'tableRow', alignment: 'center' },
              { text: element["rc"], style: 'tableRow', alignment: 'center' },
              { text: element["ce"], style: 'tableRow', alignment: 'center' }
            ]);
        }
      }

      console.log("doc", docDefinitionAux);
      // arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 3.pdf` });
      arrDoc.push(docDefinitionAux);

    }

    if (listTownStudent["advance4"].length > 0) {
      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance cuatro`,
          `Número de jugadores en el avance cuatro: ${playerHavedPlayed["advance4"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance4"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];
      for (const student in listTownStudent["advance4"]) {
        if (listTownStudent["advance4"].hasOwnProperty(student)) {
          const element = listTownStudent["advance4"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push(
            [
              { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
              { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
              { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
              { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
              { text: element["firstName"], style: 'tableRow', alignment: 'center' },
              { text: element["surname"], style: 'tableRow', alignment: 'center' },
              { text: element["lc"], style: 'tableRow', alignment: 'center' },
              { text: element["rc"], style: 'tableRow', alignment: 'center' },
              { text: element["ce"], style: 'tableRow', alignment: 'center' }
            ]);
        }
      }
      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 4.pdf` });

    }

    if (listTownStudent["advance5"].length > 0) {
      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance cinco`,
          `Número de jugadores en el avance cinco: ${playerHavedPlayed["advance5"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance5"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];
      for (const student in listTownStudent["advance5"]) {
        if (listTownStudent["advance5"].hasOwnProperty(student)) {
          const element = listTownStudent["advance5"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push(
            [
              { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
              { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
              { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
              { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
              { text: element["firstName"], style: 'tableRow', alignment: 'center' },
              { text: element["surname"], style: 'tableRow', alignment: 'center' },
              { text: element["lc"], style: 'tableRow', alignment: 'center' },
              { text: element["rc"], style: 'tableRow', alignment: 'center' },
              { text: element["ce"], style: 'tableRow', alignment: 'center' }
            ]);
        }
      }
      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 5.pdf` });

    }

    if (listTownStudent["advance6"].length > 0) {

      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Avance seis`,
          `Número de jugadores en el avance seis: ${playerHavedPlayed["advance6"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };
      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["advance6"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];
      for (const student in listTownStudent["advance6"]) {
        if (listTownStudent["advance6"].hasOwnProperty(student)) {
          const element = listTownStudent["advance6"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push([
            { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
            { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
            { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
            { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
            { text: element["firstName"], style: 'tableRow', alignment: 'center' },
            { text: element["surname"], style: 'tableRow', alignment: 'center' },
            { text: element["lc"], style: 'tableRow', alignment: 'center' },
            { text: element["rc"], style: 'tableRow', alignment: 'center' },
            { text: element["ce"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }

      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Avance 6.pdf` });

    }

    if (listTownStudent["diagnostic"].length > 0) {
      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: Diagnóstico`,
          `Número de jugadores en Diagnóstico: ${playerHavedPlayed["diagnostic"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };
      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["diagnostic"], style: 'tableRow', alignment: 'center' }
          ]);

        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.columnsAdvance
              ]
            }
          },
        ]
      }];

      for (const student in listTownStudent["diagnostic"]) {
        if (listTownStudent["diagnostic"].hasOwnProperty(student)) {
          const element = listTownStudent["diagnostic"][student];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push([
            { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
            { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
            { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
            { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
            { text: element["firstName"], style: 'tableRow', alignment: 'center' },
            { text: element["surname"], style: 'tableRow', alignment: 'center' },
            { text: element["lc"], style: 'tableRow', alignment: 'center' },
            { text: element["rc"], style: 'tableRow', alignment: 'center' },
            { text: element["ce"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }

      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-Diagnostico.pdf` });

    }
    if (listTownStudent["generalTown"].length > 0) {
      let docDefinitionAux: any = docDefinition;
      docDefinitionAux['content'][2] = {
        width: '*',
        bold: true,
        ul: [
          `Tipo de informe: General`,
          `Número de jugadores en General: ${playerHavedPlayed["generalTown"]}`
        ], margin: 5
      };

      docDefinitionAux['content'][4] = {
        layout: 'lightHorizontalLines', // optional
        table: {
          alignment: "center",
          body: [
            [
              { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
              { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
              { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
            ]
          ]
        }
      };

      for (const institution in playersPerInstitution) {
        if (playersPerInstitution.hasOwnProperty(institution)) {
          const element = playersPerInstitution[institution];
          docDefinitionAux['content'][4]['table']['body'].push([
            { text: institution, style: 'tableRow', alignment: 'center' },
            { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
            { text: element["generalInstitution"], style: 'tableRow', alignment: 'center' }
          ]);
        }
      }
      docDefinitionAux['content'][6] = [{
        columns: [
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                this.generalColumns
              ]
            }
          },
        ]
      }];


      for (const student in listTownStudent["generalTown"]) {
        if (listTownStudent["generalTown"].hasOwnProperty(student)) {
          const element = listTownStudent["generalTown"][student];
          // let username = element["username"];
          docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push([
            { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
            { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
            { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
            { text: element["gameCycle"], style: 'tableRow', alignment: 'center' },
            { text: element["firstName"], style: 'tableRow', alignment: 'center' },
            { text: element["surname"], style: 'tableRow', alignment: 'center' },
            { text: element["gameCompetence"], style: 'tableRow', alignment: 'center' },
            { text: element["gameType"], style: 'tableRow', alignment: 'center' },
          ]);
        }
      }

      console.log("doc", docDefinitionAux);
      arrDoc.push({ doc: docDefinitionAux, name: `Informe del municipio ${this.townName}-General.pdf` });

    }

    // this.location.back();

    console.log("Documentos totales", arrDoc);

    let index = 0;
    let interval = setInterval(function () {
      if (index < arrDoc.length) { // docArray here is array of docs contents
        pdfMake.createPdf(arrDoc[index]).download("asd.pdf");
        index++;
      }
      else {
        clearInterval(interval);
      }
    },
      200);


  }
  ///////////////////////////////////////////////////////////////////////////////////////////
  generatePDFForCharts() {
    // let doc = new jsPDF();
    // let specialElementHandlers={
    //   '#editor': function(element,renderer){
    //     return true;
    //   }
    // };
    // let content = this.content.nativeElement;
    // doc.fromHTML(content.innerHTML,15,15,{
    //   'width':1000,
    //   'elementHandlers':specialElementHandlers
    // });
    // doc.save()
  }

  //para las gráficas General MUNICIPIO
  public townDataG: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  public townDataC2: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  public townDataC3: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  public townDataC4: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  public townDataC5: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  ///////////////////////////////////////////////////////////////////////////////////////
  public townDataC6: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
    { label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
    { label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
  ];
  //////////////////////////////////////////////////////////////////////////////////////
  //para las gráficas General INSTITUCIÓN
  public institutionDataG: any = {};
  ///////////////////////////////////////////////////////////////////////////////////////
  public institutionDataC2: any = {};
  ///////////////////////////////////////////////////////////////////////////////////////
  public institutionDataC3: any = {};
  ///////////////////////////////////////////////////////////////////////////////////////
  public institutionDataC4: any = {};
  ///////////////////////////////////////////////////////////////////////////////////////
  public institutionDataC5: any = {};
  ///////////////////////////////////////////////////////////////////////////////////////
  public institutionDataC6: any = {};
  //////////////////////////////////////////////////////////////////////////////////////get-report.component.html
  //FOR INSTITUTIONS COMPARISONS
  public institutionCompareDataG: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [0] }
  ];
  public testCompare: any = [
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [3.4863100000000005] },
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [3.684375] },
    { label: "Lectura crítica", backgroundColor: "#E87587", data: [4.41712] }
  ];
  // Tipo de columnas a utilizar para representar la información de las gráficas
  public type: string = 'bar';
  //arreglo con los colores que se le asignarán a las columnas de las gráficas
  public chartColors: any[] = [
    {
      backgroundColor: ["#E87587", "#54BCCF", "#BFCC3D"]
    }];
  //////////////labels/////
  public labels: string[];
  public zonesLabelsG: string[];
  public zonesLabelsC2: string[];
  public zonesLabelsC3: string[];
  public zonesLabelsC4: string[];
  public zonesLabelsC5: string[];
  public zonesLabelsC6: string[];
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  public options1: any = {

    tooltips: {
      display: true
    },
    legend: {
      display: true,

    },
    responsive: true,
    responsiveAnimationDuration: 0,
    maintainAspectRatio: false,

    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 5,
          min: 0,
          fontSize: 13,


          callback: function (value, index, values) {

            var valueDesc = '';
            switch (value) {
              case 5:
                valueDesc = 'Superior';
                break;
              case 4:
                valueDesc = 'Alto';
                break;
              case 3:
                valueDesc = 'Medio';
                break;
              case 2:
                valueDesc = 'Básico';
                break;
              case 1:
                valueDesc = 'Bajo';
                break;
              default:
                valueDesc = null;
                break;
            }
            return valueDesc;
          }
        }
      }],
      xAxes: [{

        display: true,
        ticks: {
          fontSize: 8
        }
      }]
    }
  };
  public chartClicked(e: any) {

  };

}
