import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PageEvent } from '@angular/material';
import Tools from '../tools';
import { ListService } from '../_services/list.service';

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

@Component({
  selector: 'app-cycles',
  templateUrl: './cycles.component.html',
  styleUrls: ['./cycles.component.scss']
})
export class CyclesComponent implements OnInit {

  option: number;
  searchText: string = '';

  //Paginator///////////
  pageSize: number = 50;
  length: number;
  pageEvent: PageEvent;
  ////////////////////////
  cycles = [];
  cyclesAux: any = [];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private listService: ListService) {
      
    Tools.showMask();
    this.listService.getCycles(localStorage.getItem('username')).subscribe(
      result => {
        console.log("reuslt", result['cycles']);
        result['cycles'].forEach(element => {
          if (element['headquartersName'] != "CAUCA") {
            this.cycles.push(element);
            this.cyclesAux.push(element);
          }
        });

        // let papi=[];

        this.length = this.cycles.length;
        this.cycles = this.cyclesAux.slice(0, this.pageSize);

        // console.log("Que tipo es? = ", typeof (this.cycles));
        // console.log("ciclos ", this.cycles);
        // console.log("los cycles son: ", this.cycles);

        Tools.hideMask();

      },
      error => {
        console.log(<any>error);
        Tools.hideMask();
      }
    );

    this.activeRoute.queryParams.subscribe(params => {
      this.option = Tools.homeOptions[params.option];

    });
  }
  ngOnInit() { }
  onSelectCycle(selectedId: number, headquarterCode: any) {
    var self = this;
    let params: NavigationExtras = {
      queryParams: {
        "id": selectedId,
        "headquarterCode": headquarterCode,
        "from": "cycles"
      }
    };
    self.router.navigate(['/home/chart'], params);

  }

  onChangePage(event: PageEvent) {
    let self = this,
      size = self.pageSize,
      begin = size * event.pageIndex,
      end = begin + size;
    self.pageEvent = event;
    this.cycles = this.cyclesAux.slice(begin, end);
  }


  onTapSearch() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // console.log("width",w);
    // console.log("height",h);
    if (w >= 768) {
      // console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-field"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchText.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }

  editSearch: boolean = false;

  DoSearch() {

    let self = this,
      filter = [],
      searchText = self.searchText.trim();

    if (!searchText && self.editSearch) {
      self.cycles = self.cyclesAux;
    } else {
      self.editSearch = true;
    }

    for (let position in this.cyclesAux) {
      if (this.cyclesAux.hasOwnProperty(position)) {
        let element = this.cyclesAux[position];
        let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

        if (fullName.toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
          filter.push(element);
        }
        // else if (element["institutionName"].toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
        //   filter.push(element);
        // }
      }
    }
    self.cycles = filter;
    // self.length = filter.length;
    self.cycles = filter.slice(0, self.pageSize);
    if (self.pageEvent) {
      self.pageEvent.pageIndex = 0;
    }

  }


  docDefinition: any = {
    content: [
      {
        layout: 'noBorders', // optional
        width: 'auto',
        table: {
          headerRows: 1,
          body: [
            [{
              columns: [
                {
                  image: Tools.logoTalentum,
                  width: 150,
                },
                { width: '*', text: '\n\n' },
                {
                  image: Tools.logoDPI,
                  width: 120,
                }],
            }],
            [{
              text: 'Listado de ciclos',
              style: 'header',
              alignment: 'center'
            }],
            [{
              columns: [
                { width: '*', text: '' },
                {
                  layout: 'lightHorizontalLines', // optional
                  width: 'auto',
                  table: {
                    headerRows: 1,
                    body: [
                      ['Ciclo', 'Institución', 'Sede']
                    ]
                  }
                },
                { width: '*', text: '' },

              ]

            }],
          ]
        }
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify'
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
    }
  };

  async downloadPdf() {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log("doc", this.docDefinition);

    this.cyclesAux.forEach(element => {
      this.docDefinition['content'][0]['table']['body'][2][0]['columns'][1]['table']['body'].push([element.name, element.institutionName, element.headquartersName]);
    });

    pdfMake.createPdf(this.docDefinition).download("Ciclos.pdf");
    Tools.hideMask();

  }

}
