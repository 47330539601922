import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Tools from '../tools';
declare var jsPDF: any; // Important
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ListService } from '../_services/list.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-towns',
  templateUrl: './towns.component.html',
  styleUrls: ['./towns.component.scss']
})
export class TownsComponent implements OnInit {

  option: number;

  searchText: string = '';

  towns = [];
  townsAux: any = [];
  townsTest: any = [];
  length: number;

  //Towns
  institutionsTowns: any[];
  townHistorials: object;
  townsGeneralTotals: object;

  allPlayers: number;
  playersHavePlayed: number;
  id: string;
  name: string;
  cycleTotals: object;

  //Print Table
  row = [];
  columns = ["Ciclo", "Grupo", "Institución", "Sede", "Nombre", "2do Nombre", "Apellido", "2do Apellido", "Competencia", "Tipo"];
  // [gameCycle, groupIdGroup, gameCompetence, gameType, headquartersName, institutionName, firstName, secondName, surname, secondSurname]




  townsContent =[];
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private listService: ListService) {


    Tools.showMask();
    // console.log("localstorage de towns: ", localStorage.getItem('username'));
    this.listService.getTowns(localStorage.getItem('username')).subscribe(
      
      result => {
        // let papi=[];

        console.log("Result", JSON.stringify(result));
        this.towns = result["towns"];
        this.townsAux = result["towns"];

        if (this.towns[0]['name'] == "Profesores") {
          this.towns.shift();
          this.townsAux.shift();
        }

        this.length = this.townsAux.length;

        // console.log("este es el length: ",this.length);
        // console.log("Que tipo es? = ", typeof (this.towns));

        // console.log("los municipios papi: ", this.townsAux);


        // //Se le asigna al objeto zoneContent, los id ordenados de las zonas traidas en zonesTest.
        // this.townsContent = Object.keys(this.towns);

        // //Se imprime en consola el objeto con los id ordenados.
        // console.log("Object keys: ", this.townsContent);

        // //Seimprime en consola una prueba mostrando el nombre de una de las zonas.
        // console.log("prueba con el nombre: ", this.zonesTest[2]["name"]);
        // this.nombre = this.zonesTest[2]["name"];
        Tools.hideMask();

      },
      error => {
        console.log(<any>error);
        Tools.hideMask();
      }
    );
    this.activeRoute.queryParams.subscribe(params => {
      this.option = Tools.homeOptions[params.option];

    });
  }

  ngOnInit() { }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    // console.log("que me llego de row: ", buffer);
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '_';
    // var time = today.getHours() + "/" + today.getMinutes() + "/" + today.getSeconds();
    var name = fileName + "_" + date;
    FileSaver.saveAs(data, "MUNICIPIO_" + name + EXCEL_EXTENSION);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  //////---------------------------------
  downloadAllTowns(towns: Array<any>) {

    // console.log("municipios en la función de descargar: ", towns);
    Tools.showMask();
    // console.log("antes del for");
    // console.log("tamaño: ", towns.length);
    let contadorcito = 0;
    for (let posicion in towns) {

      if (towns.hasOwnProperty(posicion)) {
        let element = towns[posicion];
        let townsId = element["id"];
        let townName = element["name"];

        this.listService.townHistorial(townsId).subscribe(
          result => {
            contadorcito += 1;
            let townsPlayer: any = [];
            townsPlayer = result["players"];
            // console.log("esto es el result de Municipios: ", result);

            // this.id = result["id"];
            let allPlayers = result["allPlayers"];
            let playersHavePlayed = result["playersHavePlayed"];

            let rowsito = [];

            for (const key in townsPlayer) {
              if (townsPlayer.hasOwnProperty(key)) {
                const element = townsPlayer[key];
                let firstName = element["firstName"];
                let secondName = element["secondName"];
                let surname = element["surname"];
                let secondSurname = element["secondSurname"];
                let gameCompetence = element["gameCompetence"];
                let gameCycle = element["groupCycleId"];
                let gameType = element["gameType"];
                let headquartersName = element["headquartersName"];
                let institutionName = element["institutionName"];
                let groupIdGroup = element["groupIdGroup"];
                // console.log("prueba primer nombre: ", firstName);
                rowsito.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, secondName, surname, secondSurname, gameCompetence, gameType]);

              }
            }


            let doc = new jsPDF('p', 'pt');
            let titleFinal = 'Listado de estudiantes de ' + townName;
            let allPlayerTextFinal = 'Total de jugadores: ' + allPlayers;
            let playersHavePlayedFinal = 'Total de jugadores que han jugado: ' + playersHavePlayed;

            let splitTitle = doc.splitTextToSize(titleFinal, 380);
            doc.text(40, 30, splitTitle);

            let splitTitle2 = doc.splitTextToSize(allPlayerTextFinal, 380);
            doc.text(splitTitle2, 40, 50);


            let splitTitle3 = doc.splitTextToSize(playersHavePlayedFinal, 380);
            doc.text(splitTitle3, 40, 70);
            // console.log("nombre del municipio:", townName);
            let dataExcel = [];
            console.log("rowsito: ", rowsito);
            for (let key in rowsito) {
              if (rowsito.hasOwnProperty(key)) {
                let element = rowsito[key];
                dataExcel.push(
                  {
                    ciclo: element[0],
                    grupo: element[1],
                    institución: element[2],
                    sede: element[3],
                    nombre1: element[4],
                    nombre2: element[5],
                    apellido1: element[6],
                    apellido2: element[7],
                    competencia: element[8],
                    tipo: element[9]
                  }
                );
              }
            }
            console.log("así quedo el dataExcel: ", dataExcel);
            // this.exportAsExcelFile(dataExcel, townName);



            doc.autoTable(this.columns, rowsito, {
              // theme: 'striped', // 'striped', 'grid' or 'plain'
              styles: {
                cellPadding: 5, // a number, array or object (see margin below)
                fontSize: 5,
                font: "helvetica", // helvetica, times, courier
                lineColor: 200,
                lineWidth: 0,
                fontStyle: 'normal', // normal, bold, italic, bolditalic
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                             halign: 'center', // left, center, right
                valign: 'middle', // top, middle, bottom
                columnWidth: 'auto' // 'auto', 'wrap' or a number
              },
              margin: { top: 80 }
            });

            if (contadorcito == towns.length) {
              console.log("FINISH");
              Tools.hideMask();
            }

          },
          error => {
            console.log(<any>error);
            this.listService.showMsg("Error en el inicio de sesión.");
            Tools.hideMask();
          }
        );



      }
    }

  }

  ////------------------------------------
  onSelectTown(selectedId: number) {
    var self = this;
    let params: NavigationExtras = {
      queryParams: {
        "id": selectedId,
        "from": "towns"
      }
    };
    self.router.navigate(['/home/chart'], params);
  }

  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }

  onTapSearch() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // console.log("width",w);
    // console.log("height",h);
    if (w >= 768) {
      // console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-field"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchText.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }


  editSearch: boolean = false;

  DoSearch() {

    let self = this,
      filter = [],
      searchText = self.searchText.trim();

    if (!searchText && self.editSearch) {
      self.towns = self.townsAux;
    } else {
      self.editSearch = true;
    }

    for (let position in this.townsAux) {
      if (this.townsAux.hasOwnProperty(position)) {
        let element = this.townsAux[position];

        if (element["name"].toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
          filter.push(element);
        }
      }
    }
    self.towns = filter;
    // self.length = filter.length;

  }

}
