import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Tools from '../tools';
import { Location } from '@angular/common';


@Component({
  selector: 'app-report-calitativo',
  templateUrl: './report-calitativo.component.html',
  styleUrls: ['./report-calitativo.component.scss']
})
export class ReportCalitativoComponent implements OnInit {
  user: string = null;
	from: string;
  chart: string;

  subjects: object = null;
	rawData: object = null;
  
  studentUser: string = null;
  ejes: any[] = [];
  areas: any[] = [];
  estudiante: any;
  sesiones: any[] = [];
  competencias: any[] = [];
  pruebaAreas: any[] = [];

  ejesBool: boolean = false;
  areasBool: boolean = false;
  estudianteBool: boolean = false;
  dataBool: boolean = false;
  rawDataBool: boolean = false;
  sesionesBool: boolean = false;
  competenciasBool: boolean = false;
	pruebaAreasBool: boolean = false;
	
	transicionATercero: boolean = false;
	cuartoAQuinto: boolean = false;
	sextoANoveno: boolean = false;

  dataDB :any =null;

  recomendaciones:any = [];

  constructor( private route: ActivatedRoute, 
              private location: Location, private router: Router) { 
    
    let uid = localStorage.getItem("user");

    this.estudiante = {
      PRI_NOM:""
    }
                
    this.route.queryParams.subscribe(params => {
			this.user = params["user"];
			this.from = params["from"];
			this.chart = params["chart"];
			// console.log("Params:  ", params );
    });
    
  }

  ngOnInit() {
    let self = this;

    Tools.showMask();
    
    // this.DBServ.getNode('data', data => {
    //   self.dataDB = data;
    //   self.dataBool = true;
    //   if (self.estudianteBool && self.dataBool && self.rawDataBool) {
    //     self.processData();
    //   }
    //   // console.log("data DB:", data);
    // });
    
    // this.DBServ.getNode('alias/'+this.user,
    // data => {
    //   self.estudiante = data;
		// 	self.estudianteBool = true;
		// 	if (self.estudiante.COD_GRADO <4) {
		// 		self.transicionATercero = true;
		// 		self.cuartoAQuinto = false;
		// 		self.sextoANoveno = false;
		// 	} else if (self.estudiante.COD_GRADO <6){
		// 		self.transicionATercero = false;
		// 		self.cuartoAQuinto = true;
		// 		self.sextoANoveno = false;
				
		// 	}else{
		// 		self.transicionATercero = false;
		// 		self.cuartoAQuinto = false;
		// 		self.sextoANoveno = true;
		// 	}

    //   if (self.estudianteBool && self.dataBool && self.rawDataBool) {
    //     self.processData();
    //   }
    //   // console.log("estudiante data:", data);
    // });

    // this.DBServ.getNode('sesiones/' + this.user, data => {
    //   self.rawData = data;
    //   self.rawDataBool = true;
    //   if (self.estudianteBool && self.dataBool && self.rawDataBool) {
    //     self.processData();
    //   }
    //   // console.log("sesion data:", data);
    // });
		
  }
  printReport(){
    if (this.estudianteBool && this.dataBool && this.rawDataBool) {
			document.getElementById("button-print").classList.add("hide"); 
			window.print();
			this.location.back();
    }
  }


  processData() {
		var self = this,
			rawData = this.rawData,
			scores = null,
			dataAreas = [],
			labelsAreas = [],
			dataCompetencias = [],
			labelsCompetencias = [],
			scoresData =[];

		// console.log("Raw data:",this.rawData);
		// console.log("Data DB:",this.dataDB);

		// Se calcula el resultado del minijuego jugado
		// guarda los datos (con areas y competencias, etc) del minijuego jugado
		// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
		// juego es el minijuego
		// score es el resultado de ese minijuego
		// juegoData son los datos del minijuego
		// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
		for (var i in this.rawData) {
			scores = this.rawData[i].puntajes;
			
			for (var j in scores) {
				let juego;
				if (scores[j].juego != "Game_11") {
					for (const key in this.dataDB.juegos) {
						if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
							juego = this.dataDB.juegos[0];
						}else if (this.dataDB.juegos[key].juego == scores[j].juego &&
								this.dataDB.juegos[key].grado == scores[j].grado &&
								this.dataDB.juegos[key].nivel == scores[j].nivel) {
							juego = this.dataDB.juegos[key];
						}
					}
					
					scoresData.push({
						"juego":scores[j],
						"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
						"juegoData":juego
					});
				}

			}
		}

		// console.log("Data scores: ", scoresData);
    
		//Para grafica de areas:
		let areaChart: Array<Array<any>> = []; 
		//en el arreglo area chart se guardan los resultados 
		//segun el scoresdata en la posicion con el id del area
		scoresData.forEach((element,index) => {
				for (const k in element.juegoData.areas) {
					let position = element.juegoData.areas[k];
					(areaChart[position])?areaChart[position].push(element.score):areaChart[position]=[element.score];
					//para agregar las competencias por area a recomendaciones
					if(!self.recomendaciones[position]){
						self.recomendaciones[position] = {
							idArea:position,
							idsCompetencias:element.juegoData.competencias
						};
					}
				}
		});
		
		//por cada area calcula el promedio y lo agrega al array "data" para mostrarlo en el chart 
		//y saca la descripcion del area segun la data que viene de la base de datos
		for (let i in areaChart) {
			let sum = 0;
			for (const j in areaChart[i]) {
				sum += areaChart[i][j];
			}
			var avg = sum / areaChart[i].length;
			labelsAreas.push(this.dataDB.areas[i].desc);
			dataAreas.push(avg);

		}


		//Para grafica de competencias:
		let recomendaciones = [];
		let competenciaChart: Array<Array<any>> = []; 
		scoresData.forEach((element,index) => {
				for (let k in element.juegoData.areas) {
					let position = element.juegoData.areas[k];
					//para agregar las competencias por area a recomendaciones
					if(!recomendaciones[position]){
						recomendaciones[position] = {
							idArea:position,
							idsCompetencias:element.juegoData.competencias
						};
					}
				}
				for (let m in element.juegoData.competencias) {
					let position = element.juegoData.competencias[m];
					(competenciaChart[position])?competenciaChart[position].push(element.score):competenciaChart[position]=[element.score];
				}
		});
		let competenciaScore = [];
		for (let i in competenciaChart) {
			let sum = 0;
			for (const j in competenciaChart[i]) {
				sum += competenciaChart[i][j];
			}
			var avg = sum / competenciaChart[i].length;
			let idComp = this.dataDB.competencias[i].id;
			competenciaScore[idComp]={
				id: idComp,
				desc: this.dataDB.competencias[i].desc,
				promedio:avg
			};
			labelsCompetencias.push(this.dataDB.competencias[i].desc);
			dataCompetencias.push(avg);

		}


		self.labelsAreas = labelsAreas;
    self.dataAreas[0].data = dataAreas;
		self.labelsCompetencias = labelsCompetencias;
    self.dataCompetencias[0].data = dataCompetencias;

		//RECOMENDACIONES:
		
		let colors = ["text-yellow","text-green","text-pink","text-red","text-blue"];
		let colorIndex = 0;
		this.recomendaciones = [];
    for (let pos in recomendaciones) {
      let idArea = recomendaciones[pos].idArea;
			recomendaciones[pos].nombreArea = this.dataDB.areas[idArea].desc;
			recomendaciones[pos].color = colors[colorIndex];//para el color del texto
			recomendaciones[pos].nombresCompetencias = [];
			recomendaciones[pos].promedio = [];
			recomendaciones[pos].recomendaciones = [];
			for (const i in recomendaciones[pos].idsCompetencias) {
				let idCompetencia = recomendaciones[pos].idsCompetencias[i];
				recomendaciones[pos].nombresCompetencias.push(this.dataDB.competencias[idCompetencia].desc);
				recomendaciones[pos].recomendaciones= this.dataDB.competencias[idCompetencia].recomendaciones;
				recomendaciones[pos].promedio = competenciaScore[idCompetencia].promedio;
			}
			if(recomendaciones[pos].promedio <= 3){
				this.recomendaciones.push(recomendaciones[pos]);
			}
			//Para los colores
			(colorIndex>4)?colorIndex=0:colorIndex++;
    }
    // console.log("........................");
    // console.log("Recomendaciones",this.recomendaciones);
    // console.log("........................");
    
		Tools.hideMask();

  }
  
  public legend: boolean = false;

	public labelsAreas: string[];

	public dataAreas: any = [{
		data: [0], 
		label: null
	}];
	public labelsCompetencias: string[];

	public dataCompetencias: any = [{
		data: [0], 
		label: null
	}];

	public chartColors: any[] = [
		{ 
		  backgroundColor:["#F1B849", "#9EA451", "#E59CAA", "#B94E61", "#63ADBA"] 
		}];

	public type: string = 'bar';

	public options: any = {

		tooltips: {
			enabled: false
		},
		responsive: true,
		responsiveAnimationDuration:0,
		maintainAspectRatio:false,


		scales: {
			yAxes: [{
				stacked: true,
				ticks: {
					beginAtZero: true,
          max:5,
					min: 0,
					callback: function(value, index, values) {

						var valueDesc = '';
						switch (value) {
							case 5:
								valueDesc = 'Superior';
								break;
							case 4:
								valueDesc = 'Alto';
								break;
							case 3:
								valueDesc = 'Medio';
								break;
							case 2:
								valueDesc = 'Básico';
								break;
							case 1:
								valueDesc = 'Bajo';
								break;
							default:
								valueDesc = null;
								break;
						}
						return valueDesc;
					}
				}
			}],
			xAxes:[{
				display: true,
				ticks: {
					fontSize: 8
				}
			}]
		}
	};

}
