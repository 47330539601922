import { Component, OnInit } from '@angular/core';
import Tools from '../tools';
import * as _ from 'lodash';

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"
//Capture
import * as html2canvas from 'html2canvas';
import { PrintService } from '../_services/print.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {

  from: string = "";
  title: string = "";

  //** From towns */
  name: string = "";
  institutionTowns: any[] = [];
  general: any[] = [];
  diagnostico: any[] = [];
  avance1: any[] = [];
  avance2: any[] = [];
  avance3: any[] = [];
  avance4: any[] = [];
  avance5: any[] = [];
  avance6: any[] = [];
  cycleTotals: any;
  institutionTownCycles: any;
  evaluacion: any[] = [];
  evaluacionGeneral: any[] = [];
  townsGeneralTotals: any;
  townHistorials: any;
  id: string = "";
  //******************** */

  //** Groups */
  groupsPlayer: any = [];
  groupCycleId: any;
  headquartersCode: any;
  groupsGeneralTotals: object;
  institutionName: string = "";
  headquartersName: string = "";
  //******************** */

  //** From Institutions */
  institutionsHeadquarters: any[] = [];
  institutionHistorials: any[] = [];
  institutionGeneralTotals: object;
  //******************** */

  //** From Zones && Project */
  zonesTowns: any = [];
  zonesGeneralTotals: object;
  //******************** */
  checkedInstitution: Object = {};

  //for competences
  checkedCompetences: Object = {
    lc: true,
    rc: true,
    ce: true
  }

  //for data town cycles
  checkedCyclesTown: Object = {
    cycleTotal: false,
    cycle2: false,
    cycle3: false,
    cycle4: false,
    cycle5: false,
    cycle6: false
  }

  //for town students
  checkedListStudentTown: Object = {
    generalTown: false,
    diagnostic: false,
    advance1: false,
    advance2: false,
    advance3: false,
    advance4: false,
    advance5: false,
    advance6: false,
    test: false,
    generalTest: false
  }

  //for other options
  checkedOtherOptions: object = {
    separateCompetences: true,
    comparedCompetences: false
  }
  //for other options
  checkedGroups: object = {
    listPlayer: false
  }

  //for button state
  stateQuantitative: boolean = true;
  stateQualitative: boolean = true;

  generalColumns = [
    { text: 'Institución', style: 'tableHeader', alignment: 'center' },
    { text: 'Sede', style: 'tableHeader', alignment: 'center' },
    { text: 'Grupo', style: 'tableHeader', alignment: 'center' },
    { text: 'Juego', style: 'tableHeader', alignment: 'center' },
    { text: 'Nombre', style: 'tableHeader', alignment: 'center' },
    { text: 'Apellido', style: 'tableHeader', alignment: 'center' },
    { text: 'Competencia', style: 'tableHeader', alignment: 'center' },
    { text: 'Tipo', style: 'tableHeader', alignment: 'center' }
  ];

  columnsAdvance = [
    { text: 'Institución', style: 'tableHeader', alignment: 'center' },
    { text: 'Sede', style: 'tableHeader', alignment: 'center' },
    { text: 'Grupo', style: 'tableHeader', alignment: 'center' },
    { text: 'Ciclo', style: 'tableHeader', alignment: 'center' },
    { text: 'Nombre', style: 'tableHeader', alignment: 'center' },
    { text: 'Apellido', style: 'tableHeader', alignment: 'center' },
    { text: 'Lectura Crítica', style: 'tableHeader', alignment: 'center' },
    { text: 'Razonamiento Cuantitativo', style: 'tableHeader', alignment: 'center' },
    { text: 'Comunicación Escrita', style: 'tableHeader', alignment: 'center' }
  ];

  townCycle2: any[];
  townCycle3: any[];
  townCycle4: any[];
  townCycle5: any[];
  townCycle6: any[];

  public zonesDataG: any = [];
  public zonesDataC2: any = [];
  public zonesDataC3: any = [];
  public zonesDataC4: any = [];
  public zonesDataC5: any = [];
  public zonesDataC6: any = [];

  //** Array for Quantitative*/
  arrDoc1: any[] = [];
  arrDoc2: any[] = [];
  arrDoc3: any[] = [];
  arrDoc4: any[] = [];
  arrDoc5: any[] = [];
  arrDoc6: any[] = [];
  arrDocDiagnostic: any[] = [];
  arrDocGeneralTown: any[] = [];

  //** Array for Qualitative*/
  arrDocC2: any[] = [];
  arrDocC3: any[] = [];
  arrDocC4: any[] = [];
  arrDocC5: any[] = [];
  arrDocC6: any[] = [];
  arrDocGeneral: any[] = [];


  //** Grafíca */
  //arreglo con los colores que se le asignarán a las columnas de las gráficas
  public chartColors: any[] = [{
    backgroundColor: ["#E87587", "#54BCCF", "#BFCC3D"]
  }];

  public options1: any = {

    tooltips: {
      display: true
    },
    legend: {
      display: true,

    },
    responsive: true,
    responsiveAnimationDuration: 0,
    maintainAspectRatio: false,

    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          max: 5,
          min: 0,
          fontSize: 13,


          callback: function (value, index, values) {

            var valueDesc = '';
            switch (value) {
              case 5:
                valueDesc = 'Superior';
                break;
              case 4:
                valueDesc = 'Alto';
                break;
              case 3:
                valueDesc = 'Medio';
                break;
              case 2:
                valueDesc = 'Básico';
                break;
              case 1:
                valueDesc = 'Bajo';
                break;
              default:
                valueDesc = null;
                break;
            }
            return valueDesc;
          }
        }
      }],
      xAxes: [{

        display: true,
        ticks: {
          fontSize: 8
        }
      }]
    }
  };

  // Tipo de columnas a utilizar para representar la información de las gráficas
  public type: string = 'bar';

  constructor(private printService: PrintService) { }

  ngOnInit() {
    let params: any = JSON.parse(localStorage.getItem("params"));
    //CONSOLE.LOG("Parametros de llegada", params);

    this.from = params['from'];
    switch (this.from) {

      case 'projects': {
        this.title = `Informe del departamento ${params[('name')]}`;
        this.name = params[('name')];
        this.id = params['id'];
        this.zonesTowns = params['zonesTowns'];
        this.zonesGeneralTotals = params['zonesGeneralTotals'];
        this.cycleTotals = params['cycleTotals'];
        this.zonesDataG = _.compact(params["zonesDataG"]);
        this.zonesDataC2 = _.compact(params["zonesDataC2"]);
        this.zonesDataC3 = _.compact(params["zonesDataC3"]);
        this.zonesDataC4 = _.compact(params["zonesDataC4"]);
        this.zonesDataC5 = _.compact(params["zonesDataC5"]);
        this.zonesDataC6 = _.compact(params["zonesDataC6"]);

        //CONSOLE.LOG("name", this.name);
        //CONSOLE.LOG("id", this.id);
        //CONSOLE.LOG("zonesTowns", this.zonesTowns);
        //CONSOLE.LOG("zonesGeneralTotals", this.zonesGeneralTotals);
        //CONSOLE.LOG("cycleTotals", this.cycleTotals);
        //CONSOLE.LOG("zonesDataG", this.zonesDataG);
        //CONSOLE.LOG("zonesDataC2", this.zonesDataC2);
        //CONSOLE.LOG("zonesDataC3", this.zonesDataC3);
        //CONSOLE.LOG("zonesDataC4", this.zonesDataC4);
        //CONSOLE.LOG("zonesDataC5", this.zonesDataC5);
        //CONSOLE.LOG("zonesDataC6", this.zonesDataC6);
        break;
      }

      case 'institutions': {
        this.title = `Informe de la institución ${params[('name')]}`;
        this.name = params[('name')];
        this.id = params['id'];
        this.institutionsHeadquarters = params['institutionsHeadquarters'];
        this.institutionHistorials = params['institutionHistorials'];
        this.institutionGeneralTotals = params['institutionGeneralTotals'];
        this.cycleTotals = params['cycleTotals'];
        this.zonesDataG = _.compact(params["zonesDataG"]);
        this.zonesDataC2 = _.compact(params["zonesDataC2"]);
        this.zonesDataC3 = _.compact(params["zonesDataC3"]);
        this.zonesDataC4 = _.compact(params["zonesDataC4"]);
        this.zonesDataC5 = _.compact(params["zonesDataC5"]);
        this.zonesDataC6 = _.compact(params["zonesDataC6"]);


        //CONSOLE.LOG("name", this.name);
        //CONSOLE.LOG("id", this.id);
        //CONSOLE.LOG("institutionsHeadquarters", this.institutionsHeadquarters);
        //CONSOLE.LOG("institutionHistorials", this.institutionHistorials);
        //CONSOLE.LOG("institutionGeneralTotals", this.institutionGeneralTotals);
        //CONSOLE.LOG("cycleTotals", this.cycleTotals);
        //CONSOLE.LOG("zonesDataG", this.zonesDataG);
        //CONSOLE.LOG("zonesDataC2", this.zonesDataC2);
        //CONSOLE.LOG("zonesDataC3", this.zonesDataC3);
        //CONSOLE.LOG("zonesDataC4", this.zonesDataC4);
        //CONSOLE.LOG("zonesDataC5", this.zonesDataC5);
        //CONSOLE.LOG("zonesDataC6", this.zonesDataC6);
        break;
      }

      case 'groups': {
        this.title = `Informe del grupo ${params[('name')]}`;
        this.name = params[('name')];
        this.id = params['id'];
        this.groupsPlayer = params['groupsPlayer']
        this.groupCycleId = params['groupCycleId']
        this.groupsGeneralTotals = params['groupsGeneralTotals']
        this.headquartersCode = params["headquartersCode"]
        this.cycleTotals = params['cycleTotals'];
        this.zonesDataG = _.compact(params["zonesDataG"]);
        this.zonesDataC2 = _.compact(params["zonesDataC2"]);
        this.zonesDataC3 = _.compact(params["zonesDataC3"]);
        this.zonesDataC4 = _.compact(params["zonesDataC4"]);
        this.zonesDataC5 = _.compact(params["zonesDataC5"]);
        this.zonesDataC6 = _.compact(params["zonesDataC6"]);
        this.institutionName = params['institutionName'];
        this.headquartersName = params['headquartersName'];

        //CONSOLE.LOG("name", this.name);
        //CONSOLE.LOG("id", this.id);
        //CONSOLE.LOG("groupsPlayer", this.groupsPlayer);
        //CONSOLE.LOG("groupCycleId", this.groupCycleId);
        //CONSOLE.LOG("groupsGeneralTotals", this.groupsGeneralTotals);

        //CONSOLE.LOG("institutionName", this.institutionName);
        //CONSOLE.LOG("headquartersName", this.headquartersName);

        //CONSOLE.LOG("cycleTotals", this.cycleTotals);
        //CONSOLE.LOG("zonesDataG", this.zonesDataG);
        //CONSOLE.LOG("zonesDataC2", this.zonesDataC2);
        //CONSOLE.LOG("zonesDataC3", this.zonesDataC3);
        //CONSOLE.LOG("zonesDataC4", this.zonesDataC4);
        //CONSOLE.LOG("zonesDataC5", this.zonesDataC5);
        //CONSOLE.LOG("zonesDataC6", this.zonesDataC6);

        break;
      }

      case 'towns': {
        this.title = `Informe del municipio de ${params[('name')]}`;
        this.name = params[('name')];
        this.id = params['id'];
        this.institutionTowns = _.compact(params['institutionTowns']);
        this.general = _.compact(params['general']);
        this.diagnostico = _.compact(params['diagnostico']);
        this.avance1 = _.compact(params['avance1']);
        this.avance2 = _.compact(params['avance2']);
        this.avance3 = _.compact(params['avance3']);
        this.avance4 = _.compact(params['avance4']);
        this.avance5 = _.compact(params['avance5']);
        this.avance6 = _.compact(params['avance6']);
        this.cycleTotals = params['cycleTotals'];
        this.institutionTownCycles = params['institutionTownCycles'];
        this.evaluacion = _.compact(params['evaluacion']);
        this.evaluacionGeneral = _.compact(params['evaluacionGeneral']);
        this.townsGeneralTotals = params['townsGeneralTotals'];
        this.townHistorials = params['townHistorials'];
        this.zonesDataG = _.compact(params["zonesDataG"]);
        this.zonesDataC2 = _.compact(params["zonesDataC2"]);
        this.zonesDataC3 = _.compact(params["zonesDataC3"]);
        this.zonesDataC4 = _.compact(params["zonesDataC4"]);
        this.zonesDataC5 = _.compact(params["zonesDataC5"]);
        this.zonesDataC6 = _.compact(params["zonesDataC6"]);

        //CONSOLE.LOG("institutionTowns", this.institutionTowns);
        //CONSOLE.LOG("general", this.general);
        //CONSOLE.LOG("diagnostico", this.diagnostico);
        //CONSOLE.LOG("avance1", this.avance1);
        //CONSOLE.LOG("avance2", this.avance2);
        //CONSOLE.LOG("avance3", this.avance3);
        //CONSOLE.LOG("avance4", this.avance4);
        //CONSOLE.LOG("avance5", this.avance5);
        //CONSOLE.LOG("avance6", this.avance6);
        //CONSOLE.LOG("cycleTotals", this.cycleTotals);
        //CONSOLE.LOG("institutionTownCycles", this.institutionTownCycles);
        //CONSOLE.LOG("evaluacion", this.evaluacion);
        //CONSOLE.LOG("evaluacionGeneral", this.evaluacionGeneral);
        //CONSOLE.LOG("from", this.from);
        //CONSOLE.LOG("townsGeneralTotals", this.townsGeneralTotals);
        //CONSOLE.LOG("townHistorials", this.townHistorials);
        //CONSOLE.LOG("name", this.name);
        //CONSOLE.LOG("id", this.id);
        //CONSOLE.LOG("zonesDataG", this.zonesDataG);
        //CONSOLE.LOG("zonesDataC2", this.zonesDataC2);
        //CONSOLE.LOG("zonesDataC3", this.zonesDataC3);
        //CONSOLE.LOG("zonesDataC4", this.zonesDataC4);
        //CONSOLE.LOG("zonesDataC5", this.zonesDataC5);
        //CONSOLE.LOG("zonesDataC6", this.zonesDataC6);

        let institutionKey = Object.keys(this.institutionTownCycles);
        for (let p in this.institutionTownCycles) {
          if (this.institutionTownCycles.hasOwnProperty(p)) {
            let element = this.institutionTownCycles[p];
            let cycles = {};
            element.forEach(cycle => {
              cycles["c" + cycle.cycleId] = false;
            });
            let code = null;
            this.institutionTowns.forEach(element => {
              if (p == element["institutionName"]) {
                code = element["institutionCode"];
              }
            });
            this.checkedInstitution[p] = {
              "institutionCode": code,
              "check": false,
              "indeterminate": false,
              "cycles": cycles
            };
          }
        }

        if (parseInt(this.cycleTotals["Cycle2"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle2"]["Competence2"]) > 0
          || parseInt(this.cycleTotals["Cycle2"]["Competence3"]) > 0) {
          this.townCycle2 = this.cycleTotals["Cycle2"];
        } else {
          this.townCycle2 = null;
        }
        if (parseInt(this.cycleTotals["Cycle3"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle3"]["Competence2"]) > 0
          || parseInt(this.cycleTotals["Cycle3"]["Competence3"]) > 0) {
          this.townCycle3 = this.cycleTotals["Cycle3"];
        } else {
          this.townCycle3 = null;
        }
        if (parseInt(this.cycleTotals["Cycle4"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle4"]["Competence2"]) > 0
          || parseInt(this.cycleTotals["Cycle4"]["Competence3"]) > 0) {
          this.townCycle4 = this.cycleTotals["Cycle4"];
        } else {
          this.townCycle4 = null;
        }
        if (parseInt(this.cycleTotals["Cycle5"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle5"]["Competence2"]) > 0
          || parseInt(this.cycleTotals["Cycle5"]["Competence3"]) > 0) {
          this.townCycle5 = this.cycleTotals["Cycle5"];
        } else {
          this.townCycle5 = null;
        }
        if (parseInt(this.cycleTotals["Cycle6"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle6"]["Competence2"]) > 0
          || parseInt(this.cycleTotals["Cycle6"]["Competence3"]) > 0) {
          this.townCycle6 = this.cycleTotals["Cycle6"];
        } else {
          this.townCycle6 = null;
        }

        break;
      }
    }

  }

  onSelectInstitutionName(evt: any, institutionName: any) {
    for (let p in this.checkedInstitution[institutionName].cycles) {
      if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
        this.checkedInstitution[institutionName].cycles[p] = (evt == true) ? true : false;
      }
    }
    this.allState();
  }

  onSelectInstitutionCycle(institutionName: any) {
    let countTrue = 0;
    for (const p in this.checkedInstitution[institutionName].cycles) {
      if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
        countTrue = (this.checkedInstitution[institutionName].cycles[p] == true) ? countTrue + 1 : countTrue;
      }
    }
    if (countTrue == 0) {
      this.checkedInstitution[institutionName].check = false;
      this.checkedInstitution[institutionName].indeterminate = false;
    } else if (countTrue == Object.keys(this.checkedInstitution[institutionName].cycles).length) {
      this.checkedInstitution[institutionName].check = true;
      this.checkedInstitution[institutionName].indeterminate = false;
    } else if (countTrue < Object.keys(this.checkedInstitution[institutionName].cycles).length) {
      this.checkedInstitution[institutionName].check = true;
      this.checkedInstitution[institutionName].indeterminate = true;
    }
    this.allState();
  }

  allState() {
    //ENuso
    if (this.checkedListStudentTown['generalTown'] == true || this.checkedListStudentTown['diagnostic'] == true ||
      this.checkedListStudentTown['advance1'] == true || this.checkedListStudentTown['advance2'] == true ||
      this.checkedListStudentTown['advance3'] == true || this.checkedListStudentTown['advance4'] == true ||
      this.checkedListStudentTown['advance5'] == true || this.checkedListStudentTown['advance6'] == true ||
      this.checkedListStudentTown['test'] == true || this.checkedListStudentTown['generalTest'] == true ||
      this.checkedGroups['listPlayer'] == true) {
      this.stateQuantitative = false;
    } else {
      this.stateQuantitative = true;
    }
    if (this.checkedCyclesTown['cycleTotal'] || this.checkedCyclesTown['cycle2'] ||
      this.checkedCyclesTown['cycle3'] || this.checkedCyclesTown['cycle4'] ||
      this.checkedCyclesTown['cycle5'] || this.checkedCyclesTown['cycle6']) {
      this.stateQualitative = false;
    } else {
      this.stateQualitative = true;
    }
    //CONSOLE.LOG("stateQualitative", this.stateQualitative);
    //CONSOLE.LOG("stateQuantitative", this.stateQuantitative);
    //CONSOLE.LOG("checkedCyclesTown", this.checkedCyclesTown);
    //CONSOLE.LOG("checkedListStudentTown", this.checkedListStudentTown);


  }

  async CreateReport() {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    switch (this.from) {

      case 'towns': {

        let data: Object = {
          "name": this.name,
          "chartTown": this.checkedCyclesTown,
          "listTownStudent": this.checkedListStudentTown,
          "chartInstitution": this.checkedInstitution,
          "competences": this.checkedCompetences,
          "otherOptions": this.checkedOtherOptions
        }

        //CONSOLE.LOG("JSON", data);
        try {
          let result = await this.printService.getReportTown(data);
          //CONSOLE.LOG("datos de llegada towns", result);

          let totalPlayer = result["allPlayers"];
          let playerHavedPlayed = result["playersHavePlayed"];
          let listTownStudent = result["listTownStudent"];
          let playersPerInstitution = result["playersPerInstitution"];

          if (listTownStudent["advance1"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 1-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE UNO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance uno: ${playerHavedPlayed["advance1"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance1"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance1"]) {
              if (listTownStudent["advance1"].hasOwnProperty(student)) {
                const element = listTownStudent["advance1"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A1", docDefinitionAux);
            this.arrDoc1.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 1.pdf` });
          }

          if (listTownStudent["advance2"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 2-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance dos: ${playerHavedPlayed["advance2"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance2"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance2"]) {
              if (listTownStudent["advance2"].hasOwnProperty(student)) {
                const element = listTownStudent["advance2"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A2", docDefinitionAux);
            this.arrDoc2.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 2.pdf` });

          }

          if (listTownStudent["advance3"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 3-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance tres: ${playerHavedPlayed["advance3"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance3"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }


            for (const student in listTownStudent["advance3"]) {
              if (listTownStudent["advance3"].hasOwnProperty(student)) {
                const element = listTownStudent["advance3"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A3", docDefinitionAux);
            this.arrDoc3.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 3.pdf` });

          }

          if (listTownStudent["advance4"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 4-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cuatro: ${playerHavedPlayed["advance4"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance4"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance4"]) {
              if (listTownStudent["advance4"].hasOwnProperty(student)) {
                const element = listTownStudent["advance4"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A4", docDefinitionAux);
            this.arrDoc4.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 4.pdf` });

          }

          if (listTownStudent["advance5"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 5-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cinco: ${playerHavedPlayed["advance5"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance5"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance5"]) {
              if (listTownStudent["advance5"].hasOwnProperty(student)) {
                const element = listTownStudent["advance5"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A5", docDefinitionAux);
            this.arrDoc5.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 5.pdf` });

          }

          if (listTownStudent["advance6"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Avance 6-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance seis: ${playerHavedPlayed["advance6"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance6"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance6"]) {
              if (listTownStudent["advance6"].hasOwnProperty(student)) {
                const element = listTownStudent["advance6"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc A6", docDefinitionAux);
            this.arrDoc6.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Avance 6.pdf` });

          }

          if (listTownStudent["diagnostic"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-diagnóstico-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DIAGNÓSTICO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance diagnóstico: ${playerHavedPlayed["diagnostic"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["diagnostic"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }


            for (const student in listTownStudent["diagnostic"]) {
              if (listTownStudent["diagnostic"].hasOwnProperty(student)) {
                const element = listTownStudent["diagnostic"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc DIA", docDefinitionAux);
            this.arrDocDiagnostic.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Diagnostico.pdf` });

          }

          if (listTownStudent["generalTown"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-GENERAL-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}. \n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance general: ${playerHavedPlayed["generalTown"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.generalColumns
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };



            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["generalInstitution"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }



            for (const student in listTownStudent["generalTown"]) {
              if (listTownStudent["generalTown"].hasOwnProperty(student)) {
                const element = listTownStudent["generalTown"][student];
                // let username = element["username"];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCycle"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCompetence"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameType"], style: 'tableRow', alignment: 'center' },
                ]);
              }
            }
            //CONSOLE.LOG("doc Gene", docDefinitionAux);
            this.arrDocGeneralTown.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-General.pdf` });
          }


          Tools.hideMask();

        } catch (error) {
          //CONSOLE.LOG("Error@CreateReport", error);
          Tools.hideMask();

        }
        break;
      }

      case 'groups': {
        let data: Object = {
          "groupId": this.id,
          "headquartersCode": this.headquartersCode,
          "listTownStudent": this.checkedListStudentTown
        }

        //CONSOLE.LOG("JSON", data);
        try {
          let result = await this.printService.getReportGroup(data);
          //CONSOLE.LOG("datos de llegada groups", result);

          let totalPlayer = result["allPlayers"];
          let playerHavedPlayed = result["playersHavePlayed"];
          let listTownStudent = result["listTownStudent"];
          let playersPerInstitution = result["playersPerInstitution"];

          if (listTownStudent["advance1"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 1-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE UNO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance uno: ${playerHavedPlayed["advance1"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance1"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance1"]) {
              if (listTownStudent["advance1"].hasOwnProperty(student)) {
                const element = listTownStudent["advance1"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A1", docDefinitionAux);
            this.arrDoc1.push({ doc: docDefinitionAux, name: `Informe del grupo  ${this.name}-Avance 1.pdf` });
          }

          if (listTownStudent["advance2"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 2-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance dos: ${playerHavedPlayed["advance2"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance2"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }


            for (const student in listTownStudent["advance2"]) {
              if (listTownStudent["advance2"].hasOwnProperty(student)) {
                const element = listTownStudent["advance2"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A2", docDefinitionAux);
            this.arrDoc2.push({ doc: docDefinitionAux, name: `Informe del grupo  ${this.name}-Avance 2.pdf` });

          }

          if (listTownStudent["advance3"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 3-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance tres: ${playerHavedPlayed["advance3"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance3"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }

            for (const student in listTownStudent["advance3"]) {
              if (listTownStudent["advance3"].hasOwnProperty(student)) {
                const element = listTownStudent["advance3"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A3", docDefinitionAux);
            this.arrDoc3.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Avance 3.pdf` });

          }

          if (listTownStudent["advance4"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 4-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cuatro: ${playerHavedPlayed["advance4"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance4"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance4"]) {
              if (listTownStudent["advance4"].hasOwnProperty(student)) {
                const element = listTownStudent["advance4"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A4", docDefinitionAux);
            this.arrDoc4.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Avance 4.pdf` });

          }

          if (listTownStudent["advance5"].length > 0) {
            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 5-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cinco: ${playerHavedPlayed["advance5"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance5"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance5"]) {
              if (listTownStudent["advance5"].hasOwnProperty(student)) {
                const element = listTownStudent["advance5"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A5", docDefinitionAux);
            this.arrDoc5.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Avance 5.pdf` });

          }

          if (listTownStudent["advance6"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Avance 6-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance seis: ${playerHavedPlayed["advance6"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance6"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }
            docDefinitionAux['content'][5] = [{
              columns: [
                {
                  layout: 'lightHorizontalLines', // optional
                  table: {
                    headerRows: 1,
                    widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                    body: [
                      this.columnsAdvance
                    ]
                  }
                },
              ]
            }];
            for (const student in listTownStudent["advance6"]) {
              if (listTownStudent["advance6"].hasOwnProperty(student)) {
                const element = listTownStudent["advance6"][student];
                docDefinitionAux['content'][6][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc A6", docDefinitionAux);
            this.arrDoc6.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Avance 6.pdf` });

          }

          if (listTownStudent["diagnostic"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-Diagnóstico-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DIAGNÓSTICO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance diagnóstico: ${playerHavedPlayed["diagnostic"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["diagnostic"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }

            for (const student in listTownStudent["diagnostic"]) {
              if (listTownStudent["diagnostic"].hasOwnProperty(student)) {
                const element = listTownStudent["diagnostic"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc DIA", docDefinitionAux);
            this.arrDocDiagnostic.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Diagnostico.pdf` });

          }

          if (listTownStudent["generalTown"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-General-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En el grupo ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance general: ${playerHavedPlayed["generalTown"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.generalColumns
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["generalInstitution"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["generalTown"]) {
              if (listTownStudent["generalTown"].hasOwnProperty(student)) {
                const element = listTownStudent["generalTown"][student];
                // let username = element["username"];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCycle"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCompetence"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameType"], style: 'tableRow', alignment: 'center' },
                ]);
              }
            }
            //CONSOLE.LOG("doc Gene", docDefinitionAux);
            this.arrDocGeneralTown.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-General.pdf` });
          }

          Tools.hideMask();

        } catch (error) {
          //CONSOLE.LOG("Error@CreateReport", error);
          Tools.hideMask();

        }
        break;
      }

      case 'institutions': {
        let data: Object = {
          "institutionId": this.id,
          "listTownStudent": this.checkedListStudentTown
        }

        //CONSOLE.LOG("JSON", data);
        try {

          let result = await this.printService.getReportInstitution(data);
          //CONSOLE.LOG("datos de llegada groups", result);

          let totalPlayer = result["allPlayers"];
          let playerHavedPlayed = result["playersHavePlayed"];
          let listTownStudent = result["listTownStudent"];
          let playersPerInstitution = result["playersPerInstitution"];

          if (listTownStudent["advance1"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance 1-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE UNO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance uno: ${playerHavedPlayed["advance1"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance1"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }



            for (const student in listTownStudent["advance1"]) {
              if (listTownStudent["advance1"].hasOwnProperty(student)) {
                const element = listTownStudent["advance1"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A1", docDefinitionAux);
            this.arrDoc1.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance1.pdf` });
          }

          if (listTownStudent["advance2"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance2-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance dos: ${playerHavedPlayed["advance2"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance2"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance2"]) {
              if (listTownStudent["advance2"].hasOwnProperty(student)) {
                const element = listTownStudent["advance2"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A2", docDefinitionAux);
            this.arrDoc2.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance2.pdf` });

          }

          if (listTownStudent["advance3"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance3-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance tres: ${playerHavedPlayed["advance3"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };



            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance3"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }


            for (const student in listTownStudent["advance3"]) {
              if (listTownStudent["advance3"].hasOwnProperty(student)) {
                const element = listTownStudent["advance3"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }

            //CONSOLE.LOG("doc A3", docDefinitionAux);
            this.arrDoc3.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance3.pdf` });

          }

          if (listTownStudent["advance4"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance4-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cuatro: ${playerHavedPlayed["advance4"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };



            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance4"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance4"]) {
              if (listTownStudent["advance4"].hasOwnProperty(student)) {
                const element = listTownStudent["advance4"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A4", docDefinitionAux);
            this.arrDoc4.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance4.pdf` });

          }

          if (listTownStudent["advance5"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance5-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance cinco: ${playerHavedPlayed["advance5"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance5"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance5"]) {
              if (listTownStudent["advance5"].hasOwnProperty(student)) {
                const element = listTownStudent["advance5"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push(
                  [
                    { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                    { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                    { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                    { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                    { text: element["surname"], style: 'tableRow', alignment: 'center' },
                    { text: element["lc"], style: 'tableRow', alignment: 'center' },
                    { text: element["rc"], style: 'tableRow', alignment: 'center' },
                    { text: element["ce"], style: 'tableRow', alignment: 'center' }
                  ]);
              }
            }
            //CONSOLE.LOG("doc A5", docDefinitionAux);
            this.arrDoc5.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance5.pdf` });

          }

          if (listTownStudent["advance6"].length > 0) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Avance6-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance seis: ${playerHavedPlayed["advance6"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["advance6"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["advance6"]) {
              if (listTownStudent["advance6"].hasOwnProperty(student)) {
                const element = listTownStudent["advance6"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc A6", docDefinitionAux);
            this.arrDoc6.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Avance6.pdf` });

          }

          if (listTownStudent["diagnostic"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-Diagnóstico-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE DIAGNÓSTICO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance diagnóstico: ${playerHavedPlayed["diagnostic"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.columnsAdvance
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };


            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["diagnostic"], style: 'tableRow', alignment: 'center' }
                ]);

              }
            }

            for (const student in listTownStudent["diagnostic"]) {
              if (listTownStudent["diagnostic"].hasOwnProperty(student)) {
                const element = listTownStudent["diagnostic"][student];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupCycleId"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["lc"], style: 'tableRow', alignment: 'center' },
                  { text: element["rc"], style: 'tableRow', alignment: 'center' },
                  { text: element["ce"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            //CONSOLE.LOG("doc DIA", docDefinitionAux);
            this.arrDocDiagnostic.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Diagnóstico.pdf` });

          }

          if (listTownStudent["generalTown"].length > 0) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-General-Cuantitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                {
                  text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${totalPlayer}.\n\n`,
                  alignment: 'justify',
                  margin: 5
                },//1
                {
                  text: [
                    {
                      text: `INFORME CUANTITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `AVANCE GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `Número de jugadores que han jugado en el avance general: ${playerHavedPlayed["generalTown"]}`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                {
                  layout: 'lightHorizontalLines', // optional
                  margin: 10,
                  table: {
                    alignment: "center",
                    body: [
                      [
                        { text: 'Instituciones', style: 'tableHeader', alignment: 'center' },
                        { text: 'Total jugadores', style: 'tableHeader', alignment: 'center' },
                        { text: 'Han jugado', style: 'tableHeader', alignment: 'center' }
                      ]
                    ]
                  }
                },//3
                {
                  text: `Listado de jugadores \n\n`,
                  style: 'header',
                  alignment: 'center',
                  margin: 5
                },//4
                [{
                  columns: [
                    {
                      layout: 'lightHorizontalLines', // optional
                      table: {
                        headerRows: 1,
                        widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                        body: [
                          this.generalColumns
                        ]
                      }
                    },
                  ]
                }]//5
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            for (const institution in playersPerInstitution) {
              if (playersPerInstitution.hasOwnProperty(institution)) {
                const element = playersPerInstitution[institution];
                docDefinitionAux['content'][3]['table']['body'].push([
                  { text: institution, style: 'tableRow', alignment: 'center' },
                  { text: element["allPlayers"], style: 'tableRow', alignment: 'center' },
                  { text: element["generalInstitution"], style: 'tableRow', alignment: 'center' }
                ]);
              }
            }

            for (const student in listTownStudent["generalTown"]) {
              if (listTownStudent["generalTown"].hasOwnProperty(student)) {
                const element = listTownStudent["generalTown"][student];
                // let username = element["username"];
                docDefinitionAux['content'][5][0]['columns'][0]['table']['body'].push([
                  { text: element["institutionName"], style: 'tableRow', alignment: 'center' },
                  { text: element["headquartersName"], style: 'tableRow', alignment: 'center' },
                  { text: element["groupIdGroup"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCycle"], style: 'tableRow', alignment: 'center' },
                  { text: element["firstName"], style: 'tableRow', alignment: 'center' },
                  { text: element["surname"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameCompetence"], style: 'tableRow', alignment: 'center' },
                  { text: element["gameType"], style: 'tableRow', alignment: 'center' },
                ]);
              }
            }

            //CONSOLE.LOG("doc Gene", docDefinitionAux);
            this.arrDocGeneralTown.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-General.pdf` });
          }

          Tools.hideMask();

        } catch (error) {
          //CONSOLE.LOG("Error@CreateReport", error);
          Tools.hideMask();

        }
        break;
      }

      case 'projects': {

      }
    }

  }

  generatePDF(arrDoc: any[]) {
    // //CONSOLE.LOG("Qué doc llego?", arrDoc);
    // //CONSOLE.LOG("Documento", arrDoc[0]['doc']);
    // //CONSOLE.LOG("Título", arrDoc[0]['name']);
    Tools.showMask();
    try {
      document.getElementById('pdf').setAttribute('src', null);
      pdfMake.createPdf(arrDoc[0]['doc']).getDataUrl((dataUrl) => {
        document.getElementById('pdf').setAttribute('src', dataUrl)
        Tools.hideMask();
      });
    } catch (error) {
      console.log("Error@generatePDF", error);

    }
    Tools.hideMask();
  }

  async screenShot(element: string) {
    // //CONSOLE.LOG("element", element);
    Tools.showMask();
    let result = await html2canvas(document.getElementById(element), {
      onclone: (clonedDoc) => {
        clonedDoc.getElementById(element).style.visibility = 'visible';
      }
    });
    //your onrendered function code here
    let image = result.toDataURL("image/png");
    // //CONSOLE.LOG(`Imagen`, image);
    Tools.hideMask();

    return image;
  }

  async CreateReportQualitative() {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    try {

      switch (this.from) {

        case 'towns': {

          if (this.checkedCyclesTown['cycleTotal']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-General-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}. \n\n`,
              alignment: 'justify',
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capGeneral"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataG);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'


                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            this.arrDocGeneral.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-General-Cualitativo.pdf` });
          }
          if (this.checkedCyclesTown['cycle2']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-CICLO2-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}. \n\n`,
              alignment: 'justify',
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo2"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC2);
            //CONSOLE.LOG("infoPerformance", infoPerformance);


            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'


                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo2", docDefinitionAux);
            this.arrDocC2.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Ciclo2-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle3']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-CICLOTRES-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}. \n\n`,
              alignment: 'justify',
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo3"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC3);
            //CONSOLE.LOG("infoPerformance", infoPerformance);


            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'


                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo3", docDefinitionAux);
            this.arrDocC3.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Ciclo3-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle4']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-CICLO4-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo4"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC4);
            //CONSOLE.LOG("infoPerformance", infoPerformance);


            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'


                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo4", docDefinitionAux);
            this.arrDocC4.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Ciclo4-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle5']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-CICLO5-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}. \n\n`,
              alignment: 'justify',
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo5"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC5);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'


                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }


            //CONSOLE.LOG("doc culitativo capCiclo5", docDefinitionAux);
            this.arrDocC5.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Ciclo5-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle6']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del municipio de ${this.name}-Ciclo6-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del municipio de ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el municipio de ${this.name}, el número de jugadores inscritos fueron ${this.general.length}. \n\n`,
              alignment: 'justify',
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo6"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC6);
            //CONSOLE.LOG("infoPerformance", infoPerformance);


            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del municipio de ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo6", docDefinitionAux);
            this.arrDocC6.push({ doc: docDefinitionAux, name: `Informe del municipio de ${this.name}-Ciclo6-Cualitativo.pdf` });

          }
          break;
        }
        case 'groups': {

          if (this.checkedCyclesTown['cycleTotal']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-General-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capGeneral"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataG);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo general", docDefinitionAux);
            this.arrDocGeneral.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-General-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle2']) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-CICLO2-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo2"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC2);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo2", docDefinitionAux);
            this.arrDocC2.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Ciclo2-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle3']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-CICLO3-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo3"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC3);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo3", docDefinitionAux);
            this.arrDocC3.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Ciclo3-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle4']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-CICLO4-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo4"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC4);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo4", docDefinitionAux);
            this.arrDocC4.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Ciclo4-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle5']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-CICLO5-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };


            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo5"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };
            let infoPerformance: any = this.getDataPerformance(this.zonesDataC5);
            //CONSOLE.LOG("infoPerformance", infoPerformance);
            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo5", docDefinitionAux);
            this.arrDocC5.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Ciclo5-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle6']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe del grupo ${this.name}-CICLO6-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe del grupo ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En el grupo  ${this.name}, el número de jugadores inscritos fueron ${this.groupsPlayer.length}.\n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo6"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataC6);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',

                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'

                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,

                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes del Grupo ${this.name} de la Institución Educativa ${this.institutionName} de la sede ${this.headquartersName} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',


                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',


                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo6", docDefinitionAux);
            this.arrDocC6.push({ doc: docDefinitionAux, name: `Informe del grupo ${this.name}-Ciclo6-Cualitativo.pdf` });

          }
          break;
        }
        case 'institutions': {
          // let sizePlayer: any = _.groupBy(this.institutionHistorials, 'cycleId');
          // console.log("<jugadores que han jugado>", sizePlayer);

          if (this.checkedCyclesTown['cycleTotal']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-General-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: GENERAL`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capGeneral"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };

            let infoPerformance: any = this.getDataPerformance(this.zonesDataG);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo general", docDefinitionAux);
            this.arrDocGeneral.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-General-Cualitativo.pdf` });

          }

          if (this.checkedCyclesTown['cycle2']) {


            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-CICLO2-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: DOS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo2"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };



            let infoPerformance: any = this.getDataPerformance(this.zonesDataC2);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo2", docDefinitionAux);
            this.arrDocC2.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Ciclo2-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle3']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-CICLO3-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: TRES`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo3"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };


            let infoPerformance: any = this.getDataPerformance(this.zonesDataC3);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo3", docDefinitionAux);
            this.arrDocC3.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Ciclo3-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle4']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-CICLO4-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CUATRO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo4"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };


            let infoPerformance: any = this.getDataPerformance(this.zonesDataC4);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo4", docDefinitionAux);
            this.arrDocC4.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Ciclo4-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle5']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-CICLO5-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: CINCO`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo5"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };


            let infoPerformance: any = this.getDataPerformance(this.zonesDataC5);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo5", docDefinitionAux);
            this.arrDocC5.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Ciclo5-Cualitativo.pdf` });

          }
          if (this.checkedCyclesTown['cycle6']) {

            let docDefinitionAux: any = {
              info: {
                title: `Informe de la institución ${this.name}-CICLO6-Cualitativo.pdf`,
                author: 'DPI', compress: false,
              },
              pageMargins: [40, 180, 40, 60],
              footer: function (currentPage, pageCount) {
                return {
                  columns: [
                    { width: '*', text: '' },
                    {
                      image: Tools.footer,
                      width: 200,
                      alignment: 'right',
                      margin: [0, 10, 15, 0],
                    }
                  ]
                };
              },
              header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return {
                  layout: 'noBorders', // optional
                  width: '100%',
                  alignment: 'center',
                  columns: [
                    {
                      image: Tools.logoTalentum,
                      width: 150,
                      alignment: 'center',
                      margin: [0, 30, -35, 0]
                    },
                    {
                      width: 'auto',
                      text: '',
                      alignment: 'center',
                    },
                    {
                      image: Tools.logoDPI,
                      width: 120,
                      alignment: 'center',
                      margin: [0, 31, -290, 0]

                    }
                  ]
                };
              },
              content: [
                {
                  stack: [
                    {
                      text: `Informe de la institución ${this.name} \n\n`,
                      style: 'header',
                      alignment: 'center'
                    }
                  ]
                },//0
                { width: '*', text: `` },//1
                {
                  text: [
                    {
                      text: `INFORME CUALITATIVO`,
                      style: 'subheader',
                      alignment: 'center',
                      margin: 10
                    }, '\n\n',
                    {
                      text: `CICLO: SEIS`,
                      bold: true,
                      alignment: 'justify',
                      margin: 10
                    }, '\n\n'
                  ]
                },//2,//2
                { width: '*', text: `` },//3
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'LECTURA CRÍTICA',
                        style: 'subheader'
                      }],
                    ]
                  }
                },//[4]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'RAZONAMIENTO CUANTITATIVO',
                        style: 'subheader'
                      }]
                    ]
                  }
                },//[5]['table']['body']
                {
                  layout: 'noBorders', // optional
                  width: 'auto',
                  margin: 5,
                  table: {
                    body: [
                      [{
                        text: 'COMUNICACIÓN ESCRITA',
                        style: 'subheader'
                      }]
                    ]
                  }
                }//[6]['table']['body']
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 10,
                  color: 'black'
                },
                tableRow: {
                  fontSize: 8,
                  color: 'black'
                },
                subheader: {
                  fontSize: 15,
                  bold: true,
                  margin: 5,
                },
              }
            };

            docDefinitionAux['content'][1] = {
              text: `En la institución ${this.name}, el número de jugadores inscritos fueron ${this.institutionHistorials.length}. \n\n`,
              alignment: 'justify',
              margin: 5
            };

            docDefinitionAux['content'][3] = {
              table: {
                widths: ['*'],
                body: [
                  [{
                    image: await this.screenShot("capCiclo6"),
                    width: 500,
                    alignment: 'center',
                  }]
                ]
              }
            };


            let infoPerformance: any = this.getDataPerformance(this.zonesDataC6);
            //CONSOLE.LOG("infoPerformance", infoPerformance);

            //** Lectura crítica */

            switch (infoPerformance['L']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Lectura Crítica, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprenden la forma en la que se deben articular las partes de un texto, para darle un sentido global.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionan y valoran sobre aspectos como la forma y el contenido del mismo, obteniendo la información que este pueda suministrar.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identifican el sentido literal que puede llegar a tener un texto y reflexiona adecuadamente sobre aspectos como la forma y el contenido del mismo.\n\n'
                        }
                      ]
                    }
                  ]
                }])
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][4]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Lectura Crítica, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la forma en la que se deben articular las partes de un texto, para darle un sentido global y el sentido literal que este puede llegar a tener.\n\n'
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reflexionar sobre aspectos como la forma y el contenido de un texto, para obtener la información que este le puede suministrar.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Alcanzar el nivel de comprensión inferencial de un texto.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Razonamiento Cuantitativo */
            switch (infoPerformance['R']['performance']) {

              case "Superior": {

                break;
              }
              case "Alto": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [

                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran relacionar datos, comprender el contexto de las situaciones matemáticas que se le presentan y adicionalmente, establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos, construyendo y presentando explicaciones y argumentos que dan respuesta a los mismos.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretan, relacionan y utilizan distintas representaciones cuando deben interactuar con un problema matemático, que los lleva a elaborar y presentar explicaciones y argumentos referentes a los resultados matemáticos en el marco de situaciones reales del contexto.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan conceptos formales que están basados en las definiciones, reglas y sistemas formales.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizan herramientas matemáticas, la comprensión del contexto y el relacionamiento de datos para guiar su proceso de reflexión que lleva a la solución de problemas, construyendo generalizaciones y argumentos que apoyan o refuten dichas soluciones.\n\n'
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][5]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Razonamiento Cuantitativo, lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender la relación que existe entre los tipos de lenguaje utilizados en un problema en el momento de representarlo.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar conceptos formales que están basados en las definiciones, reglas y sistemas formales para dicha representación.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Interpretar los resultados matemáticos según el contexto y el uso que se les puede dar a estos en las situaciones de la vida cotidiana.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Comprender el contexto de las situaciones matemáticas que se le presentan.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Establecer relaciones entre los tipos de lenguaje que manejan los problemas matemáticos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Construir y presentar explicaciones y argumentos para dar respuesta a problemas matemáticos de orden cada vez más complejos.\n\n',


                        }, {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Utilizar herramientas matemáticas, comprender el contexto y el relacionamiento de datos que lo lleve a realizar el proceso reflexivo propio de la solución de problemas, de la construcción de generalizaciones y argumentos que apoyen o refuten dichas soluciones.\n\n'
                        },
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //** Comunicación Escrita */
            switch (infoPerformance['C']['performance']) {

              case "Superior": {
                break;
              }
              case "Alto": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Alto de desempeño en Comunicación Escrita, lo que implica que los estudiantes:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Logran identificar la intención de un escrito y el tipo de receptor al que este se dirige, para posteriormente, darle un uso adecuado.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Organizan y estructuran las ideas de manera coherente,  al momento de comunicarlas.\n\n'
                        }
                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Medio": {
                docDefinitionAux['content'][6]['table']['body'].push([{
                  alignment: 'justify',
                  margin: 5,
                  lineHeight: 1.2,
                  stack: [
                    `Los estudiantes de la Institución Educativa ${this.name} han alcanzado un Nivel Medio de desempeño en Comunicación Escrita lo que implica que los estudiantes requieren de preguntas orientadoras para:\n\n`,
                    {
                      ul: [
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Identificar la intención de un escrito, el tipo de receptor al que se dirige y el uso adecuado que debe darle a este.\n\n',
                        },
                        {
                          width: '*',
                          lineHeight: 1.2,
                          text: 'Reconocer y estructurar adecuadamente las ideas al momento de comunicarlas.\n\n',
                        },

                      ]
                    }
                  ]
                }]);
                break;
              }
              case "Básico": {
                break;
              }
              case "Bajo": {
                break;
              }
            }

            //CONSOLE.LOG("doc culitativo capCiclo6", docDefinitionAux);
            this.arrDocC6.push({ doc: docDefinitionAux, name: `Informe de la institución ${this.name}-Ciclo6-Cualitativo.pdf` });

          }
          break;
        }
      }

      Tools.hideMask();
    } catch (err) {
      //CONSOLE.LOG("Error@CreateReportQualitative", err);
      Tools.hideMask();
    }
  }


  getDataPerformance(arGrap: any[]) {
    return {
      L: { performance: this.Performance(arGrap[0]['data'][0]), score: arGrap[0]['data'][0] },
      R: { performance: this.Performance(arGrap[1]['data'][0]), P: arGrap[1]['data'][0] },
      C: { performance: this.Performance(arGrap[2]['data'][0]), P: arGrap[2]['data'][0] }
    };
  }

  Performance(value: any) {
    let valueDesc = "";

    //CONSOLE.LOG("que llega", value);
    //CONSOLE.LOG("parse", parseInt(value, 10));

    switch (parseInt(value, 10)) {
      case 5:
        valueDesc = 'Superior';
        break;
      case 4:
        valueDesc = 'Alto';
        break;
      case 3:
        valueDesc = 'Medio';
        break;
      case 2:
        valueDesc = 'Básico';
        break;
      case 1:
        valueDesc = 'Bajo';
        break;
      default:
        valueDesc = null;
        break;
    }

    return valueDesc;

  }

  async downloadPdf(array: any[]) {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(array[0]['doc']).download(array[0]['name']);
    Tools.hideMask();
  }

}