import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Tools from '../tools';
import { Location } from '@angular/common';


@Component({
	selector: 'report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
	institucion: any;
	aulaCode: string;
	sedeCode: string;
	good: any = ["Realiza operaziones básicas (Sumas y restas).", "Agrupa objetos similares.", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas).", "Realiza operaziones básicas (Sumas y restas)."];
	regular: any = ["Realiza operaciones complejas (Multiplicación y división).", "Reconoce patrones."];
	basic: any = ["Resuelve ecuaciones lineales."];

	params: any;

	options = [];

	user: string = null;
	from: string;
	chart: string;

	subjects: object = null;
	rawData: object = null;

	studentUser: string = null;
	ejes: any[] = [];
	areas: any[] = [];
	estudiante: any;
	sesiones: any[] = [];
	competencias: any[] = [];
	pruebaAreas: any[] = [];

	ejesBool: boolean = false;
	areasBool: boolean = false;
	estudianteBool: boolean = false;
	dataBool: boolean = false;
	rawDataBool: boolean = false;
	sesionesBool: boolean = false;
	competenciasBool: boolean = false;
	pruebaAreasBool: boolean = false;

	transicionATercero: boolean = false;
	cuartoAQuinto: boolean = false;
	sextoANoveno: boolean = false;

	dataDB: any = null;

	alias: any;

	recomendaciones: any = [];

	constructor(private route: ActivatedRoute,
		private location: Location,
		private router: Router) {

		this.estudiante = {
			PRI_NOM: ""
		}

		this.route.queryParams.subscribe(params => {
			this.user = params["user"];
			this.from = params["from"];
			this.chart = params["chart"];
			if (this.from == "aula") {
				this.sedeCode = this.user.split("-aulas-")[0];
				this.aulaCode = this.user.split("-aulas-")[1];

			}
			this.params = params;
			// console.log("Params:  ", params );
		});
	}

	goBack() {
		this.location.back();
	}
	ngOnInit() {

		let self = this;

		Tools.showMask();

		// this.DBServ.getNode('data', data => {
		// 	self.dataDB = data;
		// 	self.dataBool = true;
		// 	if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 		self.processData();
		// 	}
		// 	// console.log("data DB:", data);
		// });
		// if (this.from == "students") {
		// 	this.DBServ.getNode('alias/'+this.user,
		// 	data => {
		// 		self.estudiante = data;
		// 		self.estudianteBool = true;
		// 		if (self.estudiante.COD_GRADO <4) {
		// 			self.transicionATercero = true;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = false;
		// 		} else if (self.estudiante.COD_GRADO <6){
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = true;
		// 			self.sextoANoveno = false;

		// 		}else{
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = true;
		// 		}

		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 			// self.printReport();
		// 		}
		// 		console.log("estudiante data:", data);
		// 	});

		// 	this.DBServ.getNode('sesiones/' + this.user, data => {
		// 		self.rawData = data;
		// 		self.rawDataBool = true;
		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 			// self.printReport();
		// 		}
		// 		console.log("sesion data:", data);
		// 	});

		// }else if(this.from == "aula"){
		// 	this.DBServ.getNode('alias/',
		// 	data => {
		// 		self.alias = data;
		// 		let grado = 0;
		// 		for (let i in self.alias) {
		// 			let element = self.alias[i];
		// 			if (element.COD_GRUPO == this.aulaCode) {
		// 				grado = element.COD_GRADO;
		// 			}
		// 		}
		// 		self.estudianteBool = true;
		// 		if (grado <4) {
		// 			self.transicionATercero = true;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = false;
		// 		} else if (grado <6){
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = true;
		// 			self.sextoANoveno = false;

		// 		}else{
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = true;
		// 		}

		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("estudiante data:", data);
		// 	});

		// 	this.DBServ.getNode('sesiones/', data => {
		// 		self.rawData = data;
		// 		self.rawDataBool = true;
		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("sesion data:", data);
		// 	});

		// }else if(this.from == "grados"){
		// 	this.DBServ.getNode('alias/',
		// 	data => {
		// 		self.alias = data;
		// 		let grado = this.user;
		// 		self.estudianteBool = true;
		// 		if (parseInt(grado) <4) {
		// 			self.transicionATercero = true;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = false;
		// 		} else if (parseInt(grado) <6){
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = true;
		// 			self.sextoANoveno = false;

		// 		}else{
		// 			self.transicionATercero = false;
		// 			self.cuartoAQuinto = false;
		// 			self.sextoANoveno = true;
		// 		}

		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("estudiante data:", data);
		// 	});

		// 	this.DBServ.getNode('sesiones/', data => {
		// 		self.rawData = data;
		// 		self.rawDataBool = true;
		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("sesion data:", data);
		// 	});

		// }else if(this.from == "instituciones"){
		// 	this.DBServ.getNode('alias/',
		// 	data => {
		// 		self.alias = data;
		// 		self.estudianteBool = true;

		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("estudiante data:", data);
		// 	});

		// 	this.DBServ.getNode('sesiones/', data => {
		// 		self.rawData = data;
		// 		self.rawDataBool = true;
		// 		if (self.estudianteBool && self.dataBool && self.rawDataBool) {
		// 			self.processData();
		// 		}
		// 		// console.log("sesion data:", data);
		// 	});

		// }
	}

	processData() {
		var self = this,
			rawData = this.rawData,
			scores = null,
			dataAreas = [],
			labelsAreas = [],
			dataCompetencias = [],
			labelsCompetencias = [],
			scoresData = [];
		let recomendaciones = [];
		let competenciaScore = [];
		this.recomendaciones = [];

		// console.log("Raw data:",this.rawData);
		// console.log("Data DB:",this.dataDB);

		if (this.from == "students") {
			// Se calcula el resultado del minijuego jugado
			// guarda los datos (con areas y competencias, etc) del minijuego jugado
			// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
			// juego es el minijuego
			// score es el resultado de ese minijuego
			// juegoData son los datos del minijuego
			// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
			for (var i in this.rawData) {
				scores = this.rawData[i].puntajes;

				for (var j in scores) {
					let juego;
					if (scores[j].juego != "Game_11") {
						for (const key in this.dataDB.juegos) {
							if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
								juego = this.dataDB.juegos[0];
							} else if (this.dataDB.juegos[key].juego == scores[j].juego &&
								this.dataDB.juegos[key].grado == scores[j].grado &&
								this.dataDB.juegos[key].nivel == scores[j].nivel) {
								juego = this.dataDB.juegos[key];
							}
						}

						scoresData.push({
							"juego": scores[j],
							"score": Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
							"juegoData": juego
						});
					}

				}
			}

		} else {
			// Se calcula el resultado del minijuego jugado
			// guarda los datos (con areas y competencias, etc) del minijuego jugado
			// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
			// juego es el minijuego
			// score es el resultado de ese minijuego
			// juegoData son los datos del minijuego
			// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
			for (let p in this.rawData) {
				let raw = this.rawData[p];
				for (var i in raw) {
					scores = raw[i].puntajes;

					for (var j in scores) {
						let juego;
						if (scores[j].juego != "Game_11") {
							for (const key in this.dataDB.juegos) {
								if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
									juego = this.dataDB.juegos[0];
								} else if (this.dataDB.juegos[key].juego == scores[j].juego &&
									this.dataDB.juegos[key].grado == scores[j].grado &&
									this.dataDB.juegos[key].nivel == scores[j].nivel) {
									juego = this.dataDB.juegos[key];
								}
							}
							// por cada estudiante (alias) lo relaciona con las sesion que se esta promediando (rawdata)
							// donde "p" es el alias pero en la sesion
							// ya cuando valida que ese es, se valida que el codigo del aula que necesito esté en ese estudiante
							for (let aliasPosition in this.alias) {
								let element = this.alias[aliasPosition];
								if (this.from == "aula") {
									let sedeCode = this.user.split("-aulas-")[0];
									let aulaCode = this.user.split("-aulas-")[1];
									if (element.alias == p && aulaCode == element.COD_GRUPO && sedeCode == element.CONS_SEDE) {
										scoresData.push({
											"alias": p,
											"aula": aulaCode,
											"juego": scores[j],
											"score": Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
											"juegoData": juego
										});
									}
								}
								if (this.from == "grados") {
									if (element.alias == p && this.user == element["COD_GRADO"]) {
										scoresData.push({
											"alias": p,
											"grado": element.COD_GRADO,
											"juego": scores[j],
											"score": Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
											"juegoData": juego
										});
									}
								}
								if (this.from == "instituciones") {
									if (element.alias == p && this.user == element.COD_DANE) {
										this.institucion = element.ESTABLECIMIENTO;
										scoresData.push({
											"alias": p,
											"institucion": element.COD_DANE,
											"juego": scores[j],
											"score": Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
											"juegoData": juego
										});
									}
								}
								// if(this.from == "municipios"){
								// 	if(element.alias == p && this.user == element.COD_MPIO_NAC){
								// 		scoresData.push({
								// 			"alias":p,
								// 			"municipio":element.COD_MPIO_NAC,
								// 			"juego":scores[j],
								// 			"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
								// 			"juegoData":juego
								// 		});
								// 	}
								// }
								// if(this.from == "departamento"){
								// 	if(element.alias == p /*&& this.user == element.COD_DPTO_NAC*/){
								// 		scoresData.push({
								// 			"alias":p,
								// 			/*"departamento":element.COD_DPTO_NAC,*/
								// 			"juego":scores[j],
								// 			"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
								// 			"juegoData":juego
								// 		});
								// 	}
								// }
							}

						}

					}
				}
			}
		}


		/*
		if(this.from == "aula"){
			// Se calcula el resultado del minijuego jugado
			// guarda los datos (con areas y competencias, etc) del minijuego jugado
			// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
			// juego es el minijuego
			// score es el resultado de ese minijuego
			// juegoData son los datos del minijuego
			// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
			for (let p in this.rawData) {
				let raw = this.rawData[p];
				for (var i in raw) {
					scores = raw[i].puntajes;
					
					for (var j in scores) {
						let juego;
						if (scores[j].juego != "Game_11") {
							for (const key in this.dataDB.juegos) {
								if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
									juego = this.dataDB.juegos[0];
								}else if (this.dataDB.juegos[key].juego == scores[j].juego &&
										this.dataDB.juegos[key].grado == scores[j].grado &&
										this.dataDB.juegos[key].nivel == scores[j].nivel) {
									juego = this.dataDB.juegos[key];
								}
							}

							for (let aliasPosition in this.alias) {
								let element = this.alias[aliasPosition];
								if(element.alias == p && this.aulaCode == element.COD_GRUPO){
									scoresData.push({
										"alias":p,
										"aula":this.aulaCode,
										"juego":scores[j],
										"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
										"juegoData":juego
									});
								}
							}
							
						}
		
					}
				}
				
			}

		} else if(this.from == "grados"){
			// Se calcula el resultado del minijuego jugado
			// guarda los datos (con areas y competencias, etc) del minijuego jugado
			// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
			// juego es el minijuego
			// score es el resultado de ese minijuego
			// juegoData son los datos del minijuego
			// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
			for (let p in this.rawData) {
				let raw = this.rawData[p];
				for (var i in raw) {
					scores = raw[i].puntajes;
					
					for (var j in scores) {
						let juego;
						if (scores[j].juego != "Game_11") {
							for (const key in this.dataDB.juegos) {
								if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
									juego = this.dataDB.juegos[0];
								}else if (this.dataDB.juegos[key].juego == scores[j].juego &&
										this.dataDB.juegos[key].grado == scores[j].grado &&
										this.dataDB.juegos[key].nivel == scores[j].nivel) {
									juego = this.dataDB.juegos[key];
								}
							}

							for (let aliasPosition in this.alias) {
								let element = this.alias[aliasPosition];
								if(element.alias == p && this.user == element.COD_GRADO){
									scoresData.push({
										"alias":p,
										"grupo":this.user,
										"juego":scores[j],
										"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
										"juegoData":juego
									});
								}
							}
							
						}
		
					}
				}
				
			}

		}else if(this.from == "instituciones"){
			// Se calcula el resultado del minijuego jugado
			// guarda los datos (con areas y competencias, etc) del minijuego jugado
			// y la informacion que viene de unity al jugarlo en un solo arreglo scoresData
			// juego es el minijuego
			// score es el resultado de ese minijuego
			// juegoData son los datos del minijuego
			// NOTA: el Game_11(Biblioteca) se omite  el Game_10(Parque) se toma como un event
			for (let p in this.rawData) {
				let raw = this.rawData[p];
				for (var i in raw) {
					scores = raw[i].puntajes;
					
					for (var j in scores) {
						let juego;
						if (scores[j].juego != "Game_11") {
							for (const key in this.dataDB.juegos) {
								if (scores[j].juego == "Event" || scores[j].juego == "Game_10") {
									juego = this.dataDB.juegos[0];
								}else if (this.dataDB.juegos[key].juego == scores[j].juego &&
										this.dataDB.juegos[key].grado == scores[j].grado &&
										this.dataDB.juegos[key].nivel == scores[j].nivel) {
									juego = this.dataDB.juegos[key];
								}
							}

							for (let aliasPosition in this.alias) {
								let element = this.alias[aliasPosition];
								if(element.alias == p && this.user == element.COD_DANE){
									this.institucion = element.ESTABLECIMIENTO;
									scoresData.push({
										"alias":p,
										"institucion":this.user,
										"juego":scores[j],
										"score":Tools.CalcScore(scores[j].intentos, scores[j].ayudas, scores[j].superado),
										"juegoData":juego
									});
								}
							}
							
						}
		
					}
				}
				
			}

		}
		*/
		console.log("IMPORTANT");
		console.log("Data scores: ", scoresData);

		//Para grafica de areas:
		//en el arreglo area chart se guardan los resultados 
		//segun el scoresdata en la posicion con el id del area

		let competenciaChart: Array<Array<any>> = [];
		scoresData.forEach((element, index) => {
			for (let k in element.juegoData.areas) {
				let position = element.juegoData.areas[k];
				//para agregar las competencias por area a recomendaciones
				if (!recomendaciones[position]) {
					recomendaciones[position] = {
						idArea: position,
						idsCompetencias: element.juegoData.competencias/*,
							puntaje:element.score*/
					};
				}
			}
			for (let m in element.juegoData.competencias) {
				let position = element.juegoData.competencias[m];
				(competenciaChart[position]) ? competenciaChart[position].push(element.score) : competenciaChart[position] = [element.score];
			}
		});


		for (let i in competenciaChart) {
			let sum = 0;
			for (const j in competenciaChart[i]) {
				sum += competenciaChart[i][j];
			}
			var avg = sum / competenciaChart[i].length;
			let idComp = this.dataDB.competencias[i].id;
			competenciaScore[idComp] = {
				id: idComp,
				desc: this.dataDB.competencias[i].desc,
				promedio: avg
			};

		}


		//RECOMENDACIONES:

		let colors = ["text-yellow", "text-green", "text-pink", "text-red", "text-blue"];
		let colorIndex = 0;
		for (let pos in recomendaciones) {
			let idArea = recomendaciones[pos].idArea;
			recomendaciones[pos].nombreArea = this.dataDB.areas[idArea].desc;
			recomendaciones[pos].color = colors[colorIndex];//para el color del texto
			recomendaciones[pos].nombresCompetencias = [];
			recomendaciones[pos].promedio = [];
			recomendaciones[pos].recomendaciones = [];
			for (const i in recomendaciones[pos].idsCompetencias) {
				let idCompetencia = recomendaciones[pos].idsCompetencias[i];
				recomendaciones[pos].nombresCompetencias.push(this.dataDB.competencias[idCompetencia].desc);
				if (this.from == "instituciones") {
					recomendaciones[pos].recomendaciones = this.dataDB.competencias[idCompetencia].recomendacionesinstitucion;
				}
				if (this.from == "aula") {
					recomendaciones[pos].recomendaciones = this.dataDB.competencias[idCompetencia].recomendacionesaula;
				}
				if (this.from == "grados") {
					recomendaciones[pos].recomendaciones = this.dataDB.competencias[idCompetencia].recomendacionesgrado;
				}
				if (this.from == "students") {
					recomendaciones[pos].recomendaciones = this.dataDB.competencias[idCompetencia].recomendaciones;
				}
				recomendaciones[pos].promedio = competenciaScore[idCompetencia].promedio;
				// recomendaciones[pos].nombresCompetencias.push(idCompetencia);
			}
			if (recomendaciones[pos].promedio <= 3) {
				this.recomendaciones.push(recomendaciones[pos]);
			}
			//Para los colores
			(colorIndex > 4) ? colorIndex = 0 : colorIndex++;
		}
		// console.log("........................");
		// console.log("Recomendaciones",recomendaciones);
		// console.log("this.Recomendaciones",this.recomendaciones);
		// console.log("........................");

		Tools.hideMask();

		// setTimeout(() => {
		// 	self.printReport();

		// }, 6000);

	}
	onMenu_toggle() {
		document.getElementById("menu").classList.toggle("ocultar");
		document.getElementById("menu-black-background").classList.toggle("hide");
		// document.getElementById("btn-menu").classList.toggle("btn-izq");
		// document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
	}

}
