import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Tools from '../tools';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {
  rolname = localStorage.getItem('typeUserName');
  homeOptions = Tools.homeOptions;
  options = [];

  constructor(private location: Location, private activeRoute: ActivatedRoute, private authServ: AuthService, private router: Router) {
    // console.log(" localsttorage ", localStorage.getItem('user'));
    let uid = localStorage.getItem("username");
    console.log("el localstorgae es: ", localStorage.getItem("username"));
    console.log("esto es el localstorage type: ", localStorage.getItem('typeUserName'));


  }

  ngOnInit() {

  }


  // setOptions(tipoUsuario) {
  //   this.homeOptions.forEach(element => {
  //     element.rol.forEach(rol => {
  //       (rol == tipoUsuario) ? this.options.push(element) : null;
  //     });
  //   });
  // }
  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }

}
