import { Component, OnInit } from '@angular/core';
import Tools from '../tools';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ListService } from '../_services/list.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  //** Inputs */
  parameter: string = "";
  title: string = "Departamentos";
  queryParams: any = "";
  data: any[] = [];

  constructor(private listService: ListService) { }

  ngOnInit() {
    console.log("name", localStorage.getItem('name'));
    Tools.showMask();
    try {

      this.listService.getProjects(localStorage.getItem('name')).subscribe(result => {
        console.log("result", result);
        if (result['project'].length != 1) {
          result['project'].pop()
        }
        this.data = result['project'];
        this.parameter = "name";
        this.queryParams = {
          "parameter": ['id', 'name'],
          "from": "projects"
        }
      });
    } catch (error) {
      console.log("Error@getProjects", error);
      Tools.hideMask();
    }
    Tools.hideMask();
  }
}


