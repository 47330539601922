import { PrintReport } from '../msg/printReport';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MsgComponent } from '../msg/msg.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class PrintService {

	msg: MatDialogRef<MsgComponent>;
	printDialog: MatDialogRef<PrintReport>;
	// url: string = "http://127.0.0.1:8000";
	url: string = "https://bordadosycaladosvalle.com/laravel";
	// url: string = "http://84918e73.ngrok.io";

	constructor(
		private dialog: MatDialog,
		public http: HttpClient) {
	}

	getReportTown(data: any) {
		//CONSOLE.LOG("URL", `${this.url}/services/printData`);
		return this.http.post(`${this.url}/services/printData`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		}).toPromise();
	}

	getReportGroup(data: any) {
		//CONSOLE.LOG("URL", `${this.url}/services/printDataGroup`);
		return this.http.post(`${this.url}/services/printDataGroup`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		}).toPromise();
	}

	getReportInstitution(data: any) {
		//CONSOLE.LOG("URL", `${this.url}/services/printDataInstitution`);
		return this.http.post(`${this.url}/services/printDataInstitution`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		}).toPromise();
	}

	getReportProject(data: any) {
		//CONSOLE.LOG("URL", `${this.url}/services/printDataProject`);
		return this.http.post(`${this.url}/services/printDataProject`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		});
	}




}
