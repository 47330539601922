import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { PageEvent } from '@angular/material';
import Tools from '../tools';
import { ListService } from '../_services/list.service';

import * as _ from 'lodash'

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"

@Component({
  selector: 'app-headquarters',
  templateUrl: './headquarters.component.html',
  styleUrls: ['./headquarters.component.scss']
})
export class HeadquartersComponent implements OnInit {
  option: number;
  searchText: string = '';

  headquarters = [];
  headquartersAux: any = [];

  //Paginator
  pageSize: number = 50;
  length: number;
  pageEvent: PageEvent;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private listService: ListService) {

    Tools.showMask();
    this.listService.getHeadquarters(localStorage.getItem('username')).subscribe(

      result => {

        console.log("result", result["headquarters"]);
        console.log("acumular", _.groupBy(result["headquarters"], "institution"));


        this.headquarters = result["headquarters"];
        this.headquartersAux = result["headquarters"];

        if (this.headquarters[0]['name'] == "CAUCA") {
          this.headquarters.shift();
          this.headquartersAux.shift();
        }

        this.length = this.headquartersAux.length;
        this.headquarters = this.headquartersAux.slice(0, this.pageSize);

        console.log("Que tipo es? = ", typeof (this.headquarters));

        console.log("las sedes papi: ", this.headquarters);

        Tools.hideMask();

      },
      error => {
        console.log(<any>error);
        Tools.hideMask();
      }
    );

    this.activeRoute.queryParams.subscribe(params => {
      this.option = Tools.homeOptions[params.option];

    });
  }

  ngOnInit() { }

  onSelectHeadquarter(selectedId: number) {
    console.log("este es el id de la sede", selectedId);
    var self = this;
    let params: NavigationExtras = {
      queryParams: {
        "id": selectedId,
        "from": "headquarters"
      }
    };
    self.router.navigate(['/home/chart'], params);
  }

  onChangePage(event: PageEvent) {
    let self = this,
      size = self.pageSize,
      begin = size * event.pageIndex,
      end = begin + size;
    self.pageEvent = event;
    this.headquarters = this.headquartersAux.slice(begin, end);
  }
  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }
  onTapSearch() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // console.log("width",w);
    // console.log("height",h);
    if (w >= 768) {
      // console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-field"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchText.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }

  editSearch: boolean = false;

  DoSearch() {

    let self = this,
      filter = [],
      searchText = self.searchText.trim();

    if (!searchText && self.editSearch) {
      self.headquarters = self.headquartersAux;
    } else {
      self.editSearch = true;
    }

    for (let position in this.headquartersAux) {
      if (this.headquartersAux.hasOwnProperty(position)) {
        let element = this.headquartersAux[position];
        let fullName = element["name"] + " " + element["institution"];

        if (fullName.toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
          filter.push(element);
        }
      }
    }
    self.headquarters = filter;
    // self.length = filter.length;
    self.headquarters = filter.slice(0, self.pageSize);
    if (self.pageEvent) {
      self.pageEvent.pageIndex = 0;
    }
  }

  docDefinition: any = {
    content: [
      {
        layout: 'noBorders', // optional
        width: 'auto',
        table: {
          headerRows: 1,
          body: [
            [{
              columns: [
                {
                  image: Tools.logoTalentum,
                  width: 150,
                },
                { width: '*', text: '\n\n' },
                {
                  image: Tools.logoDPI,
                  width: 120,
                }]
            }],
            [{
              text: 'Listado de Sedes',
              style: 'header',
              alignment: 'center'
            }],
            [{
              columns: [
                { width: '*', text: '' },
                {
                  layout: 'lightHorizontalLines', // optional
                  width: 'auto',
                  table: {
                    headerRows: 1,
                    body: [
                      ['Institución', 'Sede']
                    ]
                  }
                },
                { width: '*', text: '' },

              ]

            }],
          ]
        }
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify'
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
    }
  };
  async downloadPdf() {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log("doc", this.docDefinition);

    this.headquartersAux.forEach(element => {
      this.docDefinition['content'][0]['table']['body'][2][0]['columns'][1]['table']['body'].push([element.institution, element.name]);
    });




    pdfMake.createPdf(this.docDefinition).download("Sedes.pdf");
    Tools.hideMask();

  }

}
