import { Popupcarga } from './msg/popupcarga';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//** Modules */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatCardModule, MatInputModule, MatCheckboxModule, MatButtonModule, MatListModule, MatProgressSpinnerModule, MatDialogModule, MatPaginatorModule, MatSelect, MatSelectModule, MatOptionModule, MatExpansionModule, MatTabsModule, MatTableModule, MatSortModule, MatTooltipModule, MatButtonToggleModule, MatGridListModule } from '@angular/material';
import { ChartsModule } from 'ng2-charts';
import { DataTablesModule } from 'angular-datatables';
import { HttpModule } from '@angular/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//*** Services */
import { AuthService } from './_services/auth.service';
import { AuthGuard } from './_guards/auth.guard';
import { ListService } from './_services/list.service';

//** Components */
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ChartComponent } from './chart/chart.component';
import { MaskComponent } from './mask/mask.component';
import { MsgComponent } from './msg/msg.component';
import { StudentsComponent } from './students/students.component';
import { ReportComponent } from './report/report.component';
import { FaqComponent } from './faq/faq.component';
import { InitComponent } from './init/init.component';
import { ReportCalitativoComponent } from './report-calitativo/report-calitativo.component';
import { ListEstudentsComponent } from './list-estudents/list-estudents.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserStartComponent } from './user-start/user-start.component';
import { CyclesComponent } from './cycles/cycles.component';
import { GroupComponent } from './group/group.component';
import { HeadquartersComponent } from './headquarters/headquarters.component';
import { ZonesComponent } from './zones/zones.component';
import { TownsComponent } from './towns/towns.component';
import { InstitutionsComponent } from './institutions/institutions.component';
import { DownloadComponent } from './download/download.component';
import { HttpClientModule } from '@angular/common/http';
import { PrintReport } from './msg/printReport';
import { GetReportComponent } from './get-report/get-report.component';
import { ChartContentComponent } from './chart-content/chart-content.component';
import { ProjectsComponent } from './projects/projects.component';

//Componentes
import { ListComponent } from './components/list/list.component';
import { TeachersComponent } from './teachers/teachers.component';
import { QueryPageComponent } from './components/query-page/query-page.component';
import { ReportsComponent } from './reports/reports.component';
import { PrintService } from './_services/print.service';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ListComponent,
    ChartComponent,
    MaskComponent,
    MsgComponent,
    StudentsComponent,
    ReportComponent,
    Popupcarga,
    PrintReport,
    FaqComponent,
    InitComponent,
    ReportCalitativoComponent,
    ListEstudentsComponent,
    UserProfileComponent,
    UserStartComponent,
    CyclesComponent,
    GroupComponent,
    HeadquartersComponent,
    ZonesComponent,
    TownsComponent,
    InstitutionsComponent,
    DownloadComponent,
    GetReportComponent,
    ChartContentComponent,
    ProjectsComponent,
    TeachersComponent,
    QueryPageComponent,
    ReportsComponent

  ],

  imports: [
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    MatTabsModule,
    MatOptionModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatPaginatorModule,
    AppRoutingModule,
    MatButtonToggleModule,
    DataTablesModule,
    HttpClientModule,
    MatGridListModule
  ],
  entryComponents: [MsgComponent, Popupcarga, PrintReport, GetReportComponent, ReportsComponent],
  providers: [AuthService, AuthGuard, PrintService, ListService],
  bootstrap: [AppComponent],

})
export class AppModule { }
