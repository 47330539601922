import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  title: string;
  description:string;
  listItems = [
    {
      desc: "Desafíos para la inclusión - Manual Director General",
      file: '3. Manual_Portal secretaria.pdf'
    },
    {
      desc: "Desafíos para la inclusión - Manual Subdirector Zona",
      file: '1. Manual_Portal Docente.pdf'
    },
    {
      desc: "Desafíos para la inclusión - Manual Coordinador",
      file: '2. Manual_Portal coordinador.pdf'
    }
  ];
  constructor() { 

    this.title = "Descargas";
    this.description = "En esta sección encontrará los enlaces de descarga de cada uno de los videojuegos además de los manuales de usuario que le ayudaran a resolver cualquier inquietud sobre el proceso de descarga, instalación y ejecución de Desafíos para la inclusión.";
    let uid = localStorage.getItem("user");    
  }

  ngOnInit() {
  }

  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }
  

}
