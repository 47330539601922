import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material';
import Tools from '../../tools';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-query-page',
  templateUrl: './query-page.component.html',
  styleUrls: ['./query-page.component.scss']
})
export class QueryPageComponent implements OnInit {

  @Input('title') title = "Title Page";
  @Input('data') data: any[] = [
    { data: "Element0" },
    { data: "Element1" },
    { data: "Element2" },
    { data: "Element3" },
    { data: "Element4" },
    { data: "Element5" }];
  @Input('parameter') parameter: string = "data";
  @Input('queryParams') queryParams: any = ""

  dataAux: any[] = [];

  //  Search item
  searchText: string = '';

  //*** Paginator */
  pageSize: number = 25;
  length: number;
  pageEvent: PageEvent;

  constructor(private router: Router) {

    Tools.showMask();

    this.dataAux = this.data
    this.length = this.data.length;
    this.data = this.dataAux.slice(0, this.pageSize);

    Tools.hideMask();

  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    Tools.showMask();

    this.dataAux = changes.data.currentValue;
    this.length = changes.data.currentValue.length;
    this.queryParams = changes.queryParams.currentValue;
    this.data = this.dataAux.slice(0, this.pageSize);

    Tools.hideMask();
  }


  ngOnInit() { }

  onSelectItem(item: any) {
    console.log("el ID seleccionado: ", item);
    console.log("queryParams", this.queryParams);
    let query: any = { from: this.queryParams['from'] };
    this.queryParams['parameter'].forEach(element => {
      query[element] = item[element];
    });

    console.log("Query", query);
    let params: NavigationExtras = {
      queryParams: query
    };
    this.router.navigate(['/home/chart'], params);
  }


  //** Download Query */
  downloadAll(query: any) {
    console.log("Soy la query", query);
  }

  // Paginator
  onChangePage(event: PageEvent) {
    let self = this,
      size = this.pageSize,
      begin = size * event.pageIndex,
      end = begin + size;
    this.pageEvent = event;
    this.data = this.dataAux.slice(begin, end);
  }


  //** Search */


  onTapSearch() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // console.log("width",w);
    // console.log("height",h);
    if (w >= 768) {
      // console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-field"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = () => {
        if (!this.searchText.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }

  editSearch: boolean = false;

  DoSearch() {

    let filter = []
    let searchText = this.searchText.trim();

    if (!searchText && this.editSearch) {
      this.data = this.dataAux;
    } else {
      this.editSearch = true;
    }

    for (let position in this.dataAux) {
      if (this.dataAux.hasOwnProperty(position)) {
        let element = this.dataAux[position];

        console.log("Element", element);

        if (element[this.parameter].toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
          filter.push(element);
        }
        // else if (element["institutionName"].toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
        //   filter.push(element);
        // }
      }
    }
    this.data = filter;
    // this.length = filter.length;
    this.data = filter.slice(0, this.pageSize);
    if (this.pageEvent) {
      this.pageEvent.pageIndex = 0;
    }

  }



  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }

  //** Mobile */
  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }

}
