import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'msg',
  templateUrl: './msg.component.html',
  styleUrls: ['./msg.component.scss']
})
export class MsgComponent implements OnInit {
 
 text: string;

  constructor(  private dialogRef: MatDialogRef<MsgComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {

  	     }

  ngOnInit() {
  	this.text=this.data.text;
  }

}
