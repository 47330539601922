import { Component, Input } from '@angular/core';



@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent {

	@Input('data') data: any;
	@Input('parameter') parameter: string;
	// @Input('title') title: string;

	constructor() { }


	fullName(student: any) {
		return (
			(student.PRI_NOM || '') +
			' ' +
			(student.SEG_NOM || '') +
			' ' +
			(student.PRI_APE || '') +
			' ' +
			(student.SEG_APE || '') +
			'- Grado: ' +
			(student.GRADO || '')
		)
			.trim()
			.replace(/\s\s/g, ' ');
	}

}
