import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-content',
  templateUrl: './chart-content.component.html',
  styleUrls: ['./chart-content.component.scss']
})
export class ChartContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
