import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';

import { FormControl, Validators, NgForm } from '@angular/forms';
import Tools from '../tools';
import { Location } from '@angular/common';

@Component({
	selector: 'view-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

	//Attr hiden password
	hide = true;

	username: string;
	password: string;
	loading = false;
	res: object;


	userNameFormControl = new FormControl('', [
		Validators.required]);

	passFormControl = new FormControl('', [
		Validators.required]);


	constructor(
		private authServ: AuthService,
		private router: Router
	) { }

	/**
	 * Method that do login
	 * @return 
	 */

	onSubmit() {

		if (this.userNameFormControl.valid && this.passFormControl.valid) {
			this.loading = true;
			Tools.showMask();
			this.authServ.login(this.username, this.password, this)
				.subscribe(result => {
					if (result["validated"]) {
						this.router.navigate(['/home/init']);
						this.loading = false;
						localStorage.setItem("username", result["username"]);
						localStorage.setItem("identityCard", result["identityCard"]);
						localStorage.setItem("typeUserId", result["typeUserId"]);
						localStorage.setItem("typeUserName", result["typeUserName"]);
						localStorage.setItem("name", result["name"]);
						localStorage.setItem("email", result["email"]);

					} else {
						this.authServ.showMsg(result["msg"]);
						this.loading = false;
					}
					Tools.hideMask();

				},
					error => {
						console.log(<any>error);
						this.authServ.showMsg("Error en el inicio de sesión.");
						this.loading = false;
						Tools.hideMask();
					}
				);
		}
	}

	resetPassword() {
		if (this.userNameFormControl.valid) {
			Tools.showMask();
			this.authServ.resetPassword(this.username).subscribe(
				result => {
					Tools.hideMask();
					this.authServ.showMsg("Se envió un correo a " + result + " con las instrucciones.");
				},
				error => {
					Tools.hideMask();
					this.authServ.showMsg("Error al enviar el correo.");
				}
			);
		}
	}

	ngOnInit() {

	}

}
