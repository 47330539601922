import { PrintReport } from './../msg/printReport';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MsgComponent } from '../msg/msg.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
@Injectable()
export class AuthService {

	msg: MatDialogRef<MsgComponent>;
	printDialog: MatDialogRef<PrintReport>;
	// url: string = "http://127.0.0.1:8000";
	url: string = "https://bordadosycaladosvalle.com/laravel";

	constructor(
		private dialog: MatDialog,
		public http: HttpClient) {
	}


	/*
	Servicio encargado de traer el historial del estudiante seleccionado
	La función recibe por parametro el username(string) del estudiante.
	Devuelve un observable con la consulta.
	**/

	// getHistorials(username:string): Observable<any> {
	// 	return this.http.get(this.url + '/api/historial/'+username);
	// }


	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getZones(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listZones/' + user);
	}

	/*
	Servicio encargado de traer todos los proyectos
	Devuelve un observable con la consulta.
	**/
	getProjects(user: string): Observable<any> {
		return this.http.get(`${this.url}/services/listProjects/${user}`);
	}

	/*
Servicio encargado de traer todas los TownProjects
Devuelve un observable con la consulta.
**/
	getTownProjects(idTown: any): Observable<any> {
		return this.http.get(`${this.url}/services/listTownProjects/${idTown}`);
	}

	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getTowns(user: any): Observable<any> {
		// console.log("el localstorgae service es: ", this.usernameLocalstorage);
		// console.log("esto es el localstorage: ", localStorage.getItem('username'));
		// console.log("esto es el localstorage type: ", localStorage.getItem('typeUserName'));
		return this.http.get(this.url + '/services/listTowns/' + user);
		// return this.http.get(this.url + '/services/listTowns/' + localStorage.getItem('username'));
	}
	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getInstitutions(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listInstitutions/' + user);
	}
	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getHeadquarters(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listHeadquarters/' + user);
	}
	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getGroups(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listGroups/' + user);
	}
	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getStudents(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listPlayers/' + user);
	}

	/*
	Servicio encargado de traer todas las zonas
	Devuelve un observable con la consulta.
	**/
	getCycles(user: any): Observable<any> {
		return this.http.get(this.url + '/services/listCycles/' + user);
	}

	//-------------------------------------------------------------------//--------------------------------------
	playerHistorial(username: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressPlayer/' + username);
	}

	zoneHistorial(username: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressZones/' + username);
	}

	projectsHistorial(project: string): Observable<any> {
		return this.http.get(`${this.url}/services/progressProject/${project}`);
	}

	townHistorial(username: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressTowns/' + username);
	}
	institutionHistorial(id: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressInstitutions/' + id);
	}
	headquartersHistorial(id: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressHeadquarters/' + id);
	}
	groupHistorial(id: string, cycle: number, headquartersCode: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		console.log("Asi se fue: ", this.url + '/services/progressGroups/' + id + '/' + cycle + '/' + headquartersCode);
		return this.http.get(this.url + '/services/progressGroups/' + id + '/' + cycle + '/' + headquartersCode);
	}
	cycleHistorial(id: string, headquarterCode: any): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(this.url + '/services/progressCycles/' + id + '/' + headquarterCode);
	}



	///////////////////////////////////////////////////////////////////////////////////

	saveSimat(simat: any): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.url + '/services/saveSimatPlayers', simat, { headers: headers });
	}
	saveTeachers(simat: any): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.url + '/services/saveSimatTeachers', simat, { headers: headers });
	}

	//-----------------------------------------------------------------------------------------------------------

	isLogged(): boolean {
		return (localStorage.getItem("username") == null) ? false : true;
	}

	login(email: string, password: string, formData: any): Observable<any> {

		let params = {
			username: email,
			password: password
		};
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.url + '/services/userLogin', params, { headers: headers });
	}



	updatePassword(username: string, oldPass: string, newPass: string): Observable<any> {

		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		let params = {
			username: username,
			oldPassword: oldPass,
			newPassword: newPass
		};
		return this.http.post(this.url + '/services/resetPassword', params, { headers: headers });
	}

	showMsg(msgText: string) {
		this.msg = this.dialog.open(MsgComponent, {
			data: {
				text: msgText
			}
		});
	}

	printMsg(name: any, institutionsTowns: any, townsPlayer: any, rowDIAGNOSTICO: any, rowAVANCE1: any, rowAVANCE2: any, rowAVANCE3: any, rowAVANCE4: any, rowAVANCE5: any, rowAVANCE6: any, cycleTotals: any, institutionTownCycles: any, rowEVALUACION: any) {
		this.printDialog = this.dialog.open(PrintReport, {
			width: '60vw',
			height: '80vh',
			data: {
				name: name,
				institutionsTowns: institutionsTowns,
				institutionTownCycles: institutionTownCycles,
				general: townsPlayer,
				cycleTotals: cycleTotals,
				diagnostico: rowDIAGNOSTICO,
				avance1: rowAVANCE1,
				avance2: rowAVANCE2,
				avance3: rowAVANCE3,
				avance4: rowAVANCE4,
				avance5: rowAVANCE5,
				avance6: rowAVANCE6,
				evaluacion: rowEVALUACION,
			}
		});

	}

	getReportData(data: any) {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		let params = {
			bigJson: data
		};
		return this.http.post(this.url + '/services/printTownData', params, { headers: headers });

	}

	getReport(data: any) {
		console.log("URL", `${this.url}/services/printData`);
		return this.http.post(`${this.url}/services/printData`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		}).toPromise();
	}

	getReportGroup(data: any) {
		console.log("URL", `${this.url}/services/printDataGroup`);
		return this.http.post(`${this.url}/services/printDataGroup`, data, {
			headers:
				new HttpHeaders(
					{
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'MyClientCert': '',        // This is empty
						'MyToken': ''              // This is empty
					}
				)
		}).toPromise();
	}

	resetPassword(email: string) {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		let params = {
			email: email
		};
		return this.http.post(this.url + '/services/forgotPassword', params, { headers: headers });
	}

	// logout() {
	// 	return this.afAuth.auth.signOut();
	// }

	private extractData(res: Response) {
		let body = res.json();
		return body || {};
	}
	private handleErrorObservable(error: Response | any) {
		console.error(error.message || error);
		return Observable.throw(error.message || error);
	}
	private handleErrorPromise(error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
	}




}
