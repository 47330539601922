import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Tools from '../tools';
import { ListService } from '../_services/list.service';

import * as _ from 'lodash';

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  option: number;
  searchTextC2: string = '';
  searchTextC3: string = '';
  searchTextC4: string = '';
  searchTextC5: string = '';
  searchTextC6: string = '';

  groupsC2: any[] = [];
  groupsC2Aux: any[] = [];

  groupsC3: any[] = [];
  groupsC3Aux: any[] = [];


  groupsC4: any[] = [];
  groupsC4Aux: any[] = [];


  groupsC5: any[] = [];
  groupsC5Aux: any[] = [];


  groupsC6: any[] = [];
  groupsC6Aux: any[] = [];


  groupContent = [];
  groupCycleContent = [];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private listService: ListService) {
    Tools.showMask();
    this.listService.getGroups(localStorage.getItem('username')).subscribe(
      result => {

        console.log('result', result);

        if (result["groups"]["2"]) {
          let gp2 = _.orderBy(result['groups']['2']["groups"], ['institutionName'], ['asc']);
          let arCycle = Object.values(gp2);
          //console.log("arCycle", arCycle[0]['headquartersName']);
          //console.log("tamañoi", arCycle.length);
          for (let index = 0; index < arCycle.length; index++) {

            if (arCycle[index]['headquartersName'] != "CAUCA") {

              this.groupsC2.push(arCycle[index]);
              this.groupsC2Aux.push(arCycle[index]);
            } else {
              //console.log("Si es un profesor");
            }
          }
        } else {
          this.groupsC2 = [];
          this.groupsC2Aux = [];
        }


        if (result["groups"]["3"]) {

          let gp3 = _.orderBy(result['groups']['3']["groups"], ['institutionName'], ['asc']);
          let arCycle = Object.values(gp3);

          for (let index = 0; index < arCycle.length; index++) {

            if (arCycle[index]['headquartersName'] != "CAUCA") {

              this.groupsC3.push(arCycle[index]);
              this.groupsC3Aux.push(arCycle[index]);
            } else {
              //console.log("Si es un profesor");
            }
          }
        } else {
          this.groupsC3 = [];
          this.groupsC3Aux = [];
        }

        if (result["groups"]["4"]) {
          let gp4 = _.orderBy(result['groups']['4']["groups"], ['institutionName'], ['asc']);
          let arCycle = Object.values(gp4);

          for (let index = 0; index < arCycle.length; index++) {

            if (arCycle[index]['headquartersName'] != "CAUCA") {

              this.groupsC4.push(arCycle[index]);
              this.groupsC4Aux.push(arCycle[index]);
            } else {
              //console.log("Si es un profesor");

            }
          }
        } else {
          this.groupsC4 = [];
          this.groupsC4Aux = [];
        }

        if (result["groups"]["5"]) {
          let gp5 = _.orderBy(result['groups']['5']["groups"], ['institutionName'], ['asc']);
          let arCycle = Object.values(gp5);

          for (let index = 0; index < arCycle.length; index++) {

            if (arCycle[index]['headquartersName'] != "CAUCA") {

              this.groupsC5.push(arCycle[index]);
              this.groupsC5Aux.push(arCycle[index]);
            } else {
              //console.log("Si es un profesor");
            }
          }
        } else {
          this.groupsC5 = [];
          this.groupsC5Aux = [];
        }

        if (result["groups"]["6"]) {
          let gp6 = _.orderBy(result['groups']['6']["groups"], ['institutionName'], ['asc']);
          let arCycle = Object.values(gp6);

          for (let index = 0; index < arCycle.length; index++) {
            if (arCycle[index]['headquartersName'] != "CAUCA") {

              this.groupsC6.push(arCycle[index]);
              this.groupsC6Aux.push(arCycle[index]);
            } else {
              //console.log("Si es un profesor");

            }
          }
        } else {
          this.groupsC6 = [];
          this.groupsC6Aux = [];
        }

        Tools.hideMask();

      },
      error => {
        //console.log(<any>error);
        Tools.hideMask();
      }
    );
    this.activeRoute.queryParams.subscribe(params => {
      this.option = Tools.homeOptions[params.option];

    });
  }


  ngOnInit() { }


  onSelectGroup(selected: any) {
    console.log("este es el id del grupo", selected);

    var self = this;
    let params: NavigationExtras = {
      queryParams: {
        "id": selected["id"],
        "cycle": selected["cycle"],
        "headquartersCode": selected["headquartersCode"],
        "institutionName": selected["institutionName"],
        "headquartersName": selected["headquartersName"],
        "from": "groups"
      }
    };
    self.router.navigate(['/home/chart'], params);
  }

  hideSearch() {
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
  }


  onTapSearchC2() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (w >= 768) {
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-fieldC2"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchTextC2.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }
  onTapSearchC3() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // //console.log("width",w);
    // //console.log("height",h);
    if (w >= 768) {
      // //console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-fieldC3"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btnC3");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchTextC3.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }
  onTapSearchC4() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // //console.log("width",w);
    // //console.log("height",h);
    if (w >= 768) {
      // //console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-fieldC4"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn4");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchTextC4.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }
  onTapSearchC5() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // //console.log("width",w);
    // //console.log("height",h);
    if (w >= 768) {
      // //console.log("Desktop");
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-fieldC5"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn5");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchTextC5.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }
  onTapSearchC6() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (w >= 768) {
      var self = this,
        hideCls = "hide-field",
        field = document.getElementById("search-fieldC6"),
        input = field.getElementsByTagName('input')[0],
        btn = document.getElementById("search-btn6");
      btn.className = hideCls;
      field.className = "";
      input.focus();
      input.onblur = function () {
        if (!self.searchTextC5.trim()) {
          btn.className = "";
          field.className = hideCls;
        }

      }
    }

  }

  editSearchC2: boolean = false;

  // DoSearchC2() {

  //   let self = this,
  //     filter = [],
  //     searchTextC2 = self.searchTextC2.trim();

  //   if (!searchTextC2 && self.editSearchC2) {
  //     self.groupsC2Keys = Object.keys(self.groupsC2Aux);
  //   } else {
  //     self.editSearchC2 = true;
  //   }

  //   for (let position in this.groupsC2Aux) {
  //     if (this.groupsC2Aux.hasOwnProperty(position)) {
  //       let element = this.groupsC2Aux[position];
  //       let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

  //       if (fullName.toUpperCase().indexOf(searchTextC2.toUpperCase()) != -1) {
  //         filter.push(position);
  //       }
  //       // else if (element["name"].toUpperCase().indexOf(searchTextC2.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // } else if (element["institutionName"].toUpperCase().indexOf(searchTextC2.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // }

  //     }
  //   }
  //   self.groupsC2Keys = filter;
  //   // self.length = filter.length;
  // }
  // editSearchC3: boolean = false;

  // DoSearchC3() {

  //   let self = this,
  //     filter = [],
  //     searchTextC3 = self.searchTextC3.trim();

  //   if (!searchTextC3 && self.editSearchC3) {
  //     self.groupsC3Keys = Object.keys(self.groupsC3Aux);
  //   } else {
  //     self.editSearchC3 = true;
  //   }

  //   for (let position in this.groupsC3Aux) {
  //     if (this.groupsC3Aux.hasOwnProperty(position)) {
  //       let element = this.groupsC3Aux[position];
  //       let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

  //       if (fullName.toUpperCase().indexOf(searchTextC3.toUpperCase()) != -1) {
  //         filter.push(position);
  //       }
  //       //  else if (element["name"].toUpperCase().indexOf(searchTextC3.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // } else if (element["institutionName"].toUpperCase().indexOf(searchTextC3.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // }

  //     }
  //   }
  //   self.groupsC3Keys = filter;
  //   // self.length = filter.length;

  // }
  // editSearchC4: boolean = false;
  // DoSearchC4() {

  //   let self = this,
  //     filter = [],
  //     searchTextC4 = self.searchTextC4.trim();

  //   if (!searchTextC4 && self.editSearchC4) {
  //     self.groupsC4Keys = Object.keys(self.groupsC4Aux);
  //   } else {
  //     self.editSearchC4 = true;
  //   }

  //   for (let position in this.groupsC4Aux) {
  //     if (this.groupsC4Aux.hasOwnProperty(position)) {
  //       let element = this.groupsC4Aux[position];
  //       let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

  //       if (fullName.toUpperCase().indexOf(searchTextC4.toUpperCase()) != -1) {
  //         filter.push(position);
  //       }
  //       // else if (element["name"].toUpperCase().indexOf(searchTextC4.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // } else if (element["institutionName"].toUpperCase().indexOf(searchTextC4.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // }

  //     }
  //   }
  //   self.groupsC4Keys = filter;
  //   // self.length = filter.length;

  // }
  // editSearchC5: boolean = false;
  // DoSearchC5() {

  //   let self = this,
  //     filter = [],
  //     searchTextC5 = self.searchTextC5.trim();

  //   if (!searchTextC5 && self.editSearchC5) {
  //     self.groupsC5Keys = Object.keys(self.groupsC5Aux);
  //   } else {
  //     self.editSearchC5 = true;
  //   }

  //   for (let position in this.groupsC5Aux) {
  //     if (this.groupsC5Aux.hasOwnProperty(position)) {
  //       let element = this.groupsC5Aux[position];
  //       let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

  //       if (fullName.toUpperCase().indexOf(searchTextC5.toUpperCase()) != -1) {
  //         filter.push(position);
  //       }
  //       // else if (element["name"].toUpperCase().indexOf(searchTextC5.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // } else if (element["institutionName"].toUpperCase().indexOf(searchTextC5.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // }

  //     }
  //   }
  //   self.groupsC5Keys = filter;
  //   // self.length = filter.length;

  // }
  // editSearchC6: boolean = false;
  // DoSearchC6() {

  //   let self = this,
  //     filter = [],
  //     searchTextC6 = self.searchTextC6.trim();

  //   if (!searchTextC6 && self.editSearchC6) {
  //     self.groupsC6Keys = Object.keys(self.groupsC6Aux);
  //   } else {
  //     self.editSearchC6 = true;
  //   }

  //   for (let position in this.groupsC6Aux) {
  //     if (this.groupsC6Aux.hasOwnProperty(position)) {
  //       let element = this.groupsC6Aux[position];
  //       let fullName = element["name"] + " " + element["headquartersName"] + " " + element["institutionName"];

  //       if (fullName.toUpperCase().indexOf(searchTextC6.toUpperCase()) != -1) {
  //         filter.push(position);
  //       }
  //       //  else if (element["name"].toUpperCase().indexOf(searchTextC6.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // } else if (element["institutionName"].toUpperCase().indexOf(searchTextC6.toUpperCase()) != -1) {
  //       //   filter.push(position);
  //       // }

  //     }
  //   }
  //   self.groupsC6Keys = filter;
  //   // self.length = filter.length;

  // }

  docDefinition: any = {
    compress: false,
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: '*', text: '' },
          {
            image: Tools.footer,
            width: 200,
            alignment: 'right',
            margin: 3,
          }
        ]
      };
    },
    content: [
      {
        layout: 'noBorders', // optional
        width: 'auto',
        table: {
          headerRows: 1,
          margin:10,
          body: [
            [{
              alignment: 'center',
              columns: [
                {
                  image: Tools.logoTalentum,
                  width: 150,
                },
                { width: '*', text: '' },
                {
                  image: Tools.logoDPI,
                  width: 120,  
                }
              ],
            }],
            [{
              text: 'Listado de grupos \n\n',
              style: 'header',
              alignment: 'center'
            }],
            [{
              columns: [
                { width: '*', text: '' },
                {
                  layout: 'lightHorizontalLines', // optional
                  width: 'auto',
                  table: {
                    headerRows: 1,
                    body: [
                      ['Institución', 'Sede', 'Ciclo']
                    ]
                  }
                },
                { width: '*', text: '' },

              ]

            }],
          ]
        }
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify'
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
    }
  };

  async downloadPdf(array: any[]) {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    array.forEach(element => {
      this.docDefinition['content'][0]['table']['body'][2][0]['columns'][1]['table']['body'].push([element.institutionName, element.headquartersName, element.cycle]);
    });

    pdfMake.createPdf(this.docDefinition).download("Grupos.pdf");
    Tools.hideMask();

  }

}
