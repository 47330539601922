import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import Tools from '../tools';
import { PageEvent } from '@angular/material';
import { ListService } from '../_services/list.service';

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss']
})
export class InstitutionsComponent implements OnInit {




  //** Inputs */
  parameter: string = "";
  title: string = "Instituciones";
  queryParams: any = "";
  data: any[] = [];

  option: number;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private listService: ListService) {

    Tools.showMask();
    this.listService.getInstitutions(localStorage.getItem('username')).subscribe(
      result => {

        console.log("result", result['institutions']);
        this.data = result['institutions'];

        if (this.data[0]['name'] == "PROFESORES") {
          this.data.shift();
        }

        this.parameter = "name";
        this.queryParams = {
          "parameter": ['code'],
          "from": "institutions"
        }

        Tools.hideMask();

      },
      error => {
        console.log(<any>error);
        Tools.hideMask();
      }
    );

    this.activeRoute.queryParams.subscribe(params => {
      this.option = Tools.homeOptions[params.option];
    });
  }

  ngOnInit() { }


  docDefinition: any = {
    content: [
      {
        layout: 'noBorders', // optional
        width: 'auto',
        table: {
          headerRows: 1,
          body: [
            [{
              columns: [
                { width: '*', text: '' },
                {
                  image: Tools.header,
                  width: 500,
                  alignment: 'center'
                },
                { width: '*', text: '' },
              ]
            }],
            [{
              text: 'Listado de Instituciones',
              style: 'header',
              alignment: 'center'
            }],
            [{
              columns: [
                { width: '*', text: '' },
                {
                  layout: 'lightHorizontalLines', // optional
                  width: 'auto',
                  table: {
                    headerRows: 1,
                    body: [
                      ['Instituciónes']
                    ]
                  }
                },
                { width: '*', text: '' },

              ]

            }],
          ]
        }
      }
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify'
      },
      tableExample: {
        margin: [0, 5, 0, 15]
      },
    }
  };
  
  async downloadPdf() {
    Tools.showMask();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log("doc", this.docDefinition);

    this.data.forEach(element => {
      this.docDefinition['content'][0]['table']['body'][2][0]['columns'][1]['table']['body'].push([element.name]);
    });

    pdfMake.createPdf(this.docDefinition).download("Instituciones.pdf");
    Tools.hideMask();

  }

}
