import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Tools from '../tools';

@Component({
  selector: 'app-user-start',
  templateUrl: './user-start.component.html',
  styleUrls: ['./user-start.component.scss']
})
export class UserStartComponent implements OnInit {
  url = "http://talentumehs.com/Game/";
  menuOpen:boolean = false;
  // imageTest="../../assets/DPA/dpaNewLogo.png";

  constructor(private router: Router) {

  }

  ngOnInit() {
  }

  goLogin() {
    Tools.showMask();
    this.router.navigate(['/login']);
    Tools.hideMask();
  }
  goToGame() {
    window.open(this.url, "_blank");
  }

  openMenu(){
    // console.log("open");
    // this.menuOpen = true;
    document.getElementById("adminSection").style.display = "flex";
    document.getElementById("menuRow").style.display = "none";
  }
  
  closeMenu(){
    // console.log("close");
    // this.menuOpen = false;
    document.getElementById("adminSection").style.display = "none";
    document.getElementById("menuRow").style.display = "flex";
  }

}
