import { Component, OnInit } from '@angular/core';
import Tools from '../tools';
import { ListService } from '../_services/list.service';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {



  //** Inputs */
  parameter: string = "";
  title: string = "Zonas";
  queryParams: any = "";
  data: any[] = [];

  // option: number;

  constructor(
    // private activeRoute: ActivatedRoute,
    private listService: ListService) {

    /*
    Llegan las zonas mediante el servicio getZones
    **/
    Tools.showMask();
    this.listService.getZones(localStorage.getItem('username')).subscribe(
      result => {
        console.log("result", result['zones']);
        this.data = result['zones'];
        this.parameter = "name";
        this.queryParams = {
          "parameter": ['id'],
          "from": "projects"
        }
        Tools.hideMask();
      },
      error => {
        console.log(<any>error);
        Tools.hideMask();
      }
    );

    // this.activeRoute.queryParams.subscribe(params => {
    //   this.option = Tools.homeOptions[params.option];
    // });
  }

  ngOnInit() { }

}
