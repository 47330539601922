import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckbox, MatDialog} from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
// import { GetReportComponent } from './../get-report/get-report.component';



@Component({
    selector: 'app-printReport',
    templateUrl: './printReport.html',
    styleUrls: ['./printReport.scss']
})
export class PrintReport implements OnInit {

    // @ViewChild(GetReportComponent) hijo: GetReportComponent;

    //output
    // @Output() valueChange = new EventEmitter();

    // For checks
    checked1: boolean = true;

    bigJson:Object;


    // checked: Object = {
    //     "elnombre":false,
    //     "elnombre2":true
    // };
    checkedInstitution: Object={};
    
    //getReportComponent
    // getReport: GetReportComponent;
    // printDialog: MatDialogRef<GetReportComponent>;
    
    indeterminate:boolean = false;

    //for competences
    checkedCompetences:Object={
        lc:false,
        rc: false,
        ce: false
    }

    //for data town cycles
    checkedCyclesTown:Object={
        cycleTotal:false,
        cycle2:false,
        cycle3:false,
        cycle4:false,
        cycle5:false,
        cycle6:false
    }

    //for town students
    checkedListStudentTown:Object={
        generalTown:false,
        diagnostic:false,
        advance1:false,
        advance2:false,
        advance3:false,
        advance4:false,
        advance5:false,
        advance6:false
    }

    //for other options
    checkedOtherOptions:object={
        separateCompetences:true,
        comparedCompetences:false
    }

    //for button state
    state:boolean =true;




    townName: string;
    institutionsTowns:any[];
    institutionTownCycles:any[];
    institutionKey:any[];
    generalTown:any[];
    diagnostic:any[];
    advance1:any[];
    advance2:any[];
    advance3:any[];
    advance4:any[];
    advance5:any[];
    advance6:any[];
    test:any[];
    generalTest:any[];
    cycleTotals:any[];
    townCycle2:any[];
    townCycle3:any[];
    townCycle4:any[];
    townCycle5:any[];
    townCycle6:any[];

    constructor(@Inject(MAT_DIALOG_DATA) private data, private dialog: MatDialog, private router: Router,) {
            
        }
        
    ngOnInit() {
        var self = this;
        this.townName = this.data["name"];
        this.institutionsTowns = this.data["institutionsTowns"];
        this.institutionTownCycles = this.data["institutionTownCycles"];

        this.institutionKey = Object.keys(this.institutionTownCycles);
        //for inst checks
            // checkedInstitution: Object = {
            //     "elnombre":{
            //         "check":false,
            //         "cycles":{
            //             "c2":false,
            //             "c3":false
            //         }
            //     },
            //     "elnombre2": {
            //         "check": false,
            //         "cycles": {
            //             "c2": false,
            //             "c5": false,
            //             "c6": false
            //         }
            //     }
            // };
            // // --------------------
            // institutionTownCycles= {
            //     "elnombre":[
            //         {cycleId:2},
            //         {cycleId:3},
            //         {cycleId:4}
            //     ],
            //     "elnombre":[
            //         {cycleId:2},
            //         {cycleId:3}
            //     ]
            // }
        for (let p in this.institutionTownCycles) {
            if (this.institutionTownCycles.hasOwnProperty(p)) {
                let element = this.institutionTownCycles[p];
                let cycles = {};
                element.forEach(cycle => {
                    cycles["c"+cycle.cycleId] = false;
                });
                self.checkedInstitution[p] = {
                    "check": false,
                    "indeterminate": false,
                    "cycles": cycles
                };
            }
        }
        console.log("Sera que funciono?", this.checkedInstitution);

        // this.generalTown= this.data["general"];
        this.data["general"].length > 0 ? this.generalTown = this.data["general"]: this.generalTown=null;
        this.data["diagnostico"].length > 0 ? this.diagnostic = this.data["diagnostico"]:this.diagnostic=null;
        this.data["avance1"].length > 0 ? this.advance1 = this.data["avance1"]:this.advance1=null;
        this.data["avance2"].length > 0 ? this.advance2 = this.data["avance2"]:this.advance2=null;
        this.data["avance3"].length > 0 ? this.advance3 = this.data["avance3"]:this.advance3=null;
        this.data["avance4"].length > 0 ? this.advance4 = this.data["avance4"]:this.advance4=null;
        this.data["avance5"].length > 0 ? this.advance5 = this.data["avance5"]:this.advance5=null;
        this.data["avance6"].length > 0 ? this.advance6 = this.data["avance6"]:this.advance6=null;
        this.data["evaluacion"].length > 0 ? this.test = this.data["evaluacion"]:this.test=null;
        this.cycleTotals=this.data["cycleTotals"];
     
        // console.log("llegó total ciclos: ",this.cycleTotals);
        if (parseInt(this.cycleTotals["Cycle2"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle2"]["Competence2"]) > 0
            || parseInt(this.cycleTotals["Cycle2"]["Competence3"]) > 0 ) {
            this.townCycle2 = this.cycleTotals["Cycle2"];
        }else{
            this.townCycle2 = null;
        }
        if (parseInt(this.cycleTotals["Cycle3"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle3"]["Competence2"]) > 0
            || parseInt(this.cycleTotals["Cycle3"]["Competence3"]) > 0) {
            this.townCycle3 = this.cycleTotals["Cycle3"];
        } else {
            this.townCycle3 = null;
        }
        if (parseInt(this.cycleTotals["Cycle4"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle4"]["Competence2"]) > 0
            || parseInt(this.cycleTotals["Cycle4"]["Competence3"]) > 0) {
            this.townCycle4 = this.cycleTotals["Cycle4"];
        } else {
            this.townCycle4 = null;
        }
        if (parseInt(this.cycleTotals["Cycle5"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle5"]["Competence2"]) > 0
            || parseInt(this.cycleTotals["Cycle5"]["Competence3"]) > 0) {
            this.townCycle5 = this.cycleTotals["Cycle5"];
        } else {
            this.townCycle5 = null;
        }
        if (parseInt(this.cycleTotals["Cycle6"]["Competence1"]) > 0 || parseInt(this.cycleTotals["Cycle6"]["Competence2"]) > 0
            || parseInt(this.cycleTotals["Cycle6"]["Competence3"]) > 0) {
            this.townCycle6 = this.cycleTotals["Cycle6"];
        } else {
            this.townCycle6 = null;
        }
        console.log("los keys de las instituciones: ", this.institutionKey);     
    }
    
    onSelectInstitutionName(evt:any,institutionName:any){
        for (let p in this.checkedInstitution[institutionName].cycles) {
            if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
                this.checkedInstitution[institutionName].cycles[p] = (evt==true)?true:false;
            }
        }
        this.allState();
    }
    onSelectInstitutionCycle(institutionName: any){
        let countTrue = 0;
        for (const p in this.checkedInstitution[institutionName].cycles) {
            if (this.checkedInstitution[institutionName].cycles.hasOwnProperty(p)) {
                countTrue = (this.checkedInstitution[institutionName].cycles[p] == true) ? countTrue + 1 : countTrue;
            }
        }
        if (countTrue == 0) {
            this.checkedInstitution[institutionName].check = false;
            this.checkedInstitution[institutionName].indeterminate = false;
        } else if (countTrue == Object.keys(this.checkedInstitution[institutionName].cycles).length) {
            this.checkedInstitution[institutionName].check = true;
            this.checkedInstitution[institutionName].indeterminate = false;
        } else if (countTrue < Object.keys(this.checkedInstitution[institutionName].cycles).length){
            this.checkedInstitution[institutionName].check = true;
            this.checkedInstitution[institutionName].indeterminate = true;
        }
        this.allState();
    }
    allState(){
        if (this.checkedListStudentTown['generalTown'] == true || this.checkedListStudentTown['diagnostic'] == true ||
            this.checkedListStudentTown['advance1'] == true || this.checkedListStudentTown['advance2'] == true ||
            this.checkedListStudentTown['advance3'] == true || this.checkedListStudentTown['advance4'] == true || 
            this.checkedListStudentTown['advance5'] == true || this.checkedListStudentTown['advance6'] == true) {
            this.state = false;
        } else if (this.checkedCyclesTown['cycleTotal'] || this.checkedCyclesTown['cycle2']||
            this.checkedCyclesTown['cycle3'] || this.checkedCyclesTown['cycle4'] ||
            this.checkedCyclesTown['cycle5'] || this.checkedCyclesTown['cycle6'] ||
            this.checkedCompetences['lc'] || this.checkedCompetences['rc'] ||
            this.checkedCompetences['ce']){
            this.state=false;
        }else{
            this.state=true;
        }
        
        console.log("el state: ", this.state);
    }

    print(){
        var self = this;
        this.bigJson={
            "name": this.townName,
            "chartTown": this.checkedCyclesTown,
            "listTownStudent": this.checkedListStudentTown,
            "chartInstitution": this.checkedInstitution,
            "competences": this.checkedCompetences,
            "otherOptions": this.checkedListStudentTown
        }
        // this.hijo.recibir(this.bigJson);
        // // this.valueChange.emit("algo");
        // let params: NavigationExtras = {
        //     queryParams: {
        //         "json": this.checkedCyclesTown,
        //         "json1": this.checkedInstitution,
        //         "json2": this.checkedCompetences,
        //         "name": this.townName,
                
        //     }
        // };
        // self.router.navigate(['/getReport'],params); 
        // self.router.navigate(['/getReport']); 

        

    }
    

}