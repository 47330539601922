import { Popupcarga } from './../msg/popupcarga';
import { AuthService } from './../_services/auth.service';
import { element } from 'protractor';
import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import Tools from '../tools';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
declare var jsPDF: any; // Important
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { PrintReport } from '../msg/printReport';
import * as FileSaver from 'file-saver';

import { PageEvent } from '@angular/material';
import { GetReportComponent } from '../get-report/get-report.component';
import { ListService } from '../_services/list.service';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']

})
export class ChartComponent implements OnInit {

	@Output() sendJson = new EventEmitter<any>();

	from: string;
	changePrint: string = "";

	testData: number[] = [];
	testDataCycle2: number[] = [];
	testDataCycle3: number[] = [];
	testDataCycle4: number[] = [];
	testDataCycle5: number[] = [];
	testDataCycle6: number[] = [];
	bigJson: any[];

	testLabel: string[] = ["C1", "C2", "C3"];

	//toolTip
	positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
	position = new FormControl(this.positionOptions[2]);
	position1 = new FormControl(this.positionOptions[2]);
	position2 = new FormControl(this.positionOptions[2]);
	position3 = new FormControl(this.positionOptions[2]);
	position4 = new FormControl(this.positionOptions[2]);
	position5 = new FormControl(this.positionOptions[2]);

	// name and secondname
	firstName: string = "";
	secondName: string = "";
	firstLastName: string = "";
	secondLastName: string = "";

	//names
	groupName: string = "";
	zoneName: string = "";

	//id o code
	id: string;

	//objects for querys
	students: object;

	//Zone
	zonesTowns: any = [];
	zonesGeneralTotals: object;

	//Towns
	institutionsTowns: any[];
	institutionTownCycles: any[];
	townHistorials: object;
	townsGeneralTotals: object;
	townsPlayer: any = [];
	townsPlayerAux: any = [];
	allPlayers: number;
	playersHavePlayed: number;

	//BolivarInstitutions
	bolivar: any = [];
	bolivarInstitutionStudents: any = [];

	//Institutions
	institutionsHeadquarters: any[];
	institutionHistorials: object;
	institutionGeneralTotals: object;
	institutionName: string = "";

	//headquarters
	headquartersName: string = "";
	groups: any[];
	headquartersHistorials: object;
	headquartersGeneralTotals: object;
	arrayCycle2: any = [];
	arrayCycle3: any = [];
	arrayCycle4: any = [];
	arrayCycle5: any = [];
	arrayCycle6: any = [];

	//groups
	groupsPlayer: any = [];
	groupCycleId: any;
	headquartersCode: any;
	groupsGeneralTotals: object;


	//Cycles
	cycleTotals: object;
	cycleId: string = "";
	cycleHeadquartersName: string;
	cycleHeadquartersCode: string;
	cyclesPlayer: any = [];
	cyclesGeneralTotals: object;
	cyclesHistorials: object;

	//historial
	historials: object;

	//name for object query
	name: string;

	//
	entryCount: number;
	entryLast: string;

	//title
	title: string;

	//Popup Modal
	dialogRef: MatDialogRef<Popupcarga, any>;

	//Students
	// actualCompetence:string;
	// actualCycle:number;
	// actualId:number;
	// actualType:number;
	actualGames: any = [];
	username: string;


	//TABLE
	row = [];
	rowDIAGNOSTICO = [];
	rowAVANCE1 = [];
	rowAVANCE2 = [];
	rowAVANCE3 = [];
	rowAVANCE4 = [];
	rowAVANCE5 = [];
	rowAVANCE6 = [];
	rowEVALUACION = [];
	rowEVALUACIONGENERAL = [];
	//PRINT TABLE
	printAll = [];
	printDIAGNOSTICO = [];
	printAVANCE1 = [];
	printAVANCE2 = [];
	printAVANCE3 = [];
	printAVANCE4 = [];
	printAVANCE5 = [];
	printAVANCE6 = [];
	printEVALUACION = [];
	printEVALUACIONGENERAL = [];

	columns = ["Ciclo", "Grupo", "Institución", "Sede", "Nombre", "2do Nombre", "Apellido", "2do Apellido", "Competencia", "Tipo"];
	columns2 = ["Ciclo", "Grupo", "Institución", "Sede", "Nombre", "Apellido", "Lectura crítica", "Razonamiento Cuantitativo", "Comunicación Escrita"];
	// [gameCycle, groupIdGroup, gameCompetence, gameType, headquartersName, institutionName, firstName, secondName, surname, secondSurname]

	//TEXT TABLE
	titleList: string = 'Listado de estudiantes ';
	allPlayerText: string = 'Total de jugadores: ';
	playerHavePlayedText: string = 'Total de jugadores que han jugado: ';

	/////////////////////////////////////////
	// rows table


	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private router: Router,
		private http: HttpClient,
		private listService: ListService,
		public dialog: MatDialog
	) {
		//VACIANDO ARRELOS
		this.testData = [];
		this.testDataCycle2 = [];
		this.testDataCycle3 = [];
		this.testDataCycle4 = [];
		this.testDataCycle5 = [];
		this.testDataCycle6 = [];
		//objects for querys
		this.students = [];
		//Zone
		this.zonesTowns = [];
		this.zonesGeneralTotals = [];

		//Towns
		this.institutionsTowns = [];
		this.townHistorials = [];
		this.townsGeneralTotals = [];
		this.townsPlayer = [];
		this.townsPlayerAux = [];
		this.institutionTownCycles = [];

		//BolivarInstitutions
		this.bolivar = [];
		this.bolivarInstitutionStudents = [];

		//Institutions
		this.institutionsHeadquarters = [];
		this.institutionHistorials = [];
		this.institutionGeneralTotals = [];

		//headquarters
		this.groups = [];
		this.headquartersHistorials = [];
		this.headquartersGeneralTotals = [];
		this.arrayCycle2 = [];
		this.arrayCycle3 = [];
		this.arrayCycle4 = [];
		this.arrayCycle5 = [];
		this.arrayCycle6 = [];

		//groups
		this.groupsPlayer = [];
		this.groupsGeneralTotals = [];


		//Cycles
		this.cycleTotals = [];
		this.cyclesPlayer = [];
		this.cyclesGeneralTotals = [];
		this.cyclesHistorials = [];

		//historial
		this.historials = [];
		//Students
		this.actualGames = [];


		//TABLE
		this.row = [];
		this.rowDIAGNOSTICO = [];
		this.rowAVANCE1 = [];
		this.rowAVANCE2 = [];
		this.rowAVANCE3 = [];
		this.rowAVANCE4 = [];
		this.rowAVANCE5 = [];
		this.rowAVANCE6 = [];
		this.rowEVALUACION = [];
		this.rowEVALUACIONGENERAL = [];
		//PRINT TABLE
		this.printAll = [];
		this.printDIAGNOSTICO = [];
		this.printAVANCE1 = [];
		this.printAVANCE2 = [];
		this.printAVANCE3 = [];
		this.printAVANCE4 = [];
		this.printAVANCE5 = [];
		this.printAVANCE6 = [];
		this.printEVALUACION = [];
		this.printEVALUACIONGENERAL = [];

		////////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////////
		this.title = "Resultados generales";
		////////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////////
		// Trae los parametros mandados desde los diferentes componentes como lo son :
		//student, institution, cycles, headquarters, towns.
		this.route.queryParams.subscribe(params => {
			//CONSOLE.LOG('params charts',params);

			////////////////////////////////////////////////////////////////////////
			////////////////////////////////////////////////////////////////////////
			//Cuando el from que llega como parámetro es igual a "zones"
			//se le asigna a nuestro objeto, para así mismo mostrar esa información
			//en el interfaz.
			this.from = params["from"];
			localStorage.removeItem("params");
			switch (params["from"]) {

				case "zones": {
					Tools.showMask();
					this.listService.zoneHistorial(params[("id")]).subscribe(
						result => {
							this.id = result["id"];
							this.name = result["name"];
							this.zonesTowns = result["towns"];
							this.zonesGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];

							let c1T = this.zonesGeneralTotals["Competence1"] * 0.05;
							let c2T = this.zonesGeneralTotals["Competence2"] * 0.05;
							let c3T = this.zonesGeneralTotals["Competence3"] * 0.05;

							////////////////////////////////////////////////////////////////////////
							//PARA TOTAL ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];

							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 2 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 3 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 4 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 5 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 6 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05] }
							];

							//Se le asigna al objeto zoneContent, los id ordenados de las zonas traidas en zones.
							//Se imprime en consola el objeto con los id ordenados.

							Tools.hideMask();


						},
						error => {
							console.log("Error@Zones", error);
							Tools.hideMask();
						}
					);
					break;
				}
				// Cuando el from que llega como parámetro es igual a "towns"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información en el interfaz.
				case "towns": {
					Tools.showMask();
					this.listService.townHistorial(params[("id")]).subscribe(
						result => {
							this.id = result["id"];
							this.townsPlayer = result["players"];
							this.townsPlayerAux = result["players"];
							this.allPlayers = result["allPlayers"];
							this.playersHavePlayed = result["playersHavePlayed"];
							this.name = result["name"];
							this.institutionsTowns = result["institutions"];
							this.institutionTownCycles = result["institutionCycles"];
							this.townHistorials = result["townHistorials"];
							this.townsGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];

							if (this.id == "BOLÍVAR") {
								this.bolivar = result;
							}

							for (const key in this.townsPlayer) {
								if (this.townsPlayer.hasOwnProperty(key)) {
									const element = this.townsPlayer[key];
									let firstName = element["firstName"];
									let secondName = element["secondName"];
									let surname = element["surname"];
									let secondSurname = element["secondSurname"];
									let gameId = element["gameId"];
									let gameCompetence = element["gameCompetence"];
									let gameCycle = element["groupCycleId"];
									let gameType = element["gameType"];
									let headquartersName = element["headquartersName"];
									let institutionName = element["institutionName"];
									let groupIdGroup = element["groupIdGroup"];
									let lc = "";
									let rc = "";
									let ce = "";

									switch (gameId) {
										case 1:
											lc = "X"; rc = ""; ce = ""
											this.rowDIAGNOSTICO.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printDIAGNOSTICO.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);

											break;
										case 2:
											lc = "X"; rc = "X"; ce = ""
											this.rowDIAGNOSTICO.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printDIAGNOSTICO.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 3:
											lc = "X"; rc = "X"; ce = "X"
											this.rowDIAGNOSTICO.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printDIAGNOSTICO.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 4:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE1.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE1.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 5:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE1.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE1.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 6:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE1.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE1.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 7:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE2.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE2.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 8:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE2.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE2.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 9:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE2.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE2.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 10:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE3.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE3.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 11:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE3.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE3.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 12:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE3.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE3.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 13:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE4.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE4.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 14:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE4.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE4.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 15:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE4.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE4.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 16:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE5.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE5.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 17:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE5.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printDIAGNOSTICO.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 18:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE5.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE5.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 19:
											lc = "X"; rc = ""; ce = ""
											this.rowAVANCE6.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE6.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 20:
											lc = "X"; rc = "X"; ce = ""
											this.rowAVANCE6.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE6.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 21:
											lc = "X"; rc = "X"; ce = "X"
											this.rowAVANCE6.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printAVANCE6.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 22:
											lc = "X"; rc = ""; ce = ""
											this.rowEVALUACION.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printEVALUACION.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 23:
											lc = "X"; rc = "X"; ce = ""
											this.rowEVALUACION.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printEVALUACION.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										case 24:
											lc = "X"; rc = "X"; ce = "X"
											this.rowEVALUACION.push({ "gameCycle": gameCycle, "groupIdGroup": groupIdGroup, "institutionName": institutionName, "headquartersName": headquartersName, "firstName": firstName, "surname": surname, "lc": lc, "rc": rc, "ce": ce });
											this.printEVALUACION.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
											break;
										// 	// case 25:
										// 	// 	lc = "X";rc = "X";ce = "X"
										// 	// 	this.rowEVALUACIONGENERAL.push({"gameCycle": gameCycle,"groupIdGroup": groupIdGroup,"institutionName": institutionName,"headquartersName": headquartersName,"firstName": firstName,"surname": surname,"lc": lc,"rc": rc, "ce": ce});
										// 	// 	this.printEVALUACIONGENERAL.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname, lc, rc, ce]);
										// 	// 	break;

										default:
											// aqui van los que no han jugado ningun juego, los "N/A"
											break;
									}
									this.printAll.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, secondName, surname, secondSurname, gameCompetence, gameType]);

								}
							}

							let c1T = this.townsGeneralTotals["Competence1"] * 0.05;
							let c2T = this.townsGeneralTotals["Competence2"] * 0.05;
							let c3T = this.townsGeneralTotals["Competence3"] * 0.05;


							// ////////////////////////////////////////////////////////////////////////
							// //PARA TOTAL TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// //PARA CICLO 2 TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// //PARA CICLO 3 TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// //PARA CICLO 4 TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							// //PARA CICLO 5 TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// //PARA CICLO 6 TOWNS
							// ////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// ////////////////////////////////////////////////////////////////////////
							Tools.hideMask();

							this.params = {
								"name": this.name,
								"institutionTowns": (this.institutionsTowns),
								"general": (this.townsPlayer),
								"diagnostico": (this.rowDIAGNOSTICO),
								"avance1": (this.rowAVANCE1),
								"avance2": (this.rowAVANCE2),
								"avance3": (this.rowAVANCE3),
								"avance4": (this.rowAVANCE4),
								"avance5": (this.rowAVANCE5),
								"avance6": (this.rowAVANCE6),
								"cycleTotals": (this.cycleTotals),
								"institutionTownCycles": (this.institutionTownCycles),
								"evaluacion": (this.rowEVALUACION),
								"evaluacionGeneral": (this.rowEVALUACIONGENERAL),
								"from": "towns",
								"townsGeneralTotals": this.townsGeneralTotals,
								"townHistorials": this.townHistorials,
								"id": this.id,
								"zonesDataG": this.zonesDataG,
								"zonesDataC2": this.zonesDataC2,
								"zonesDataC3": this.zonesDataC3,
								"zonesDataC4": this.zonesDataC4,
								"zonesDataC5": this.zonesDataC5,
								"zonesDataC6": this.zonesDataC6
							}
							localStorage.setItem("params", JSON.stringify(this.params));
						},
						error => {
							console.log("Error@Towns", error);
							Tools.hideMask();
						}

					);
					break;
				}
				////////////////////////////////////////////////////////////////////////
				// Cuando el from que llega como parámetro es igual a "institutions"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información
				//en el interfaz.
				case "institutions": {
					Tools.showMask();

					this.listService.institutionHistorial(params[("code")]).subscribe(
						result => {

							this.id = result["code"];
							this.name = result["name"];

							this.institutionsHeadquarters = result["headquarters"];
							this.institutionHistorials = result["institutionHistorials"];
							this.institutionGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];

							let c1T = this.institutionGeneralTotals["Competence1"] * 0.05;
							let c2T = this.institutionGeneralTotals["Competence2"] * 0.05;
							let c3T = this.institutionGeneralTotals["Competence3"] * 0.05;

							// // ////////////////////////////////////////////////////////////////////////
							// // //PARA TOTAL INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];
							// // ////////////////////////////////////////////////////////////////////////
							// // //PARA CICLO 2 INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05,] }
							];
							// // ////////////////////////////////////////////////////////////////////////
							// // //PARA CICLO 3 INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05,] }
							];
							// // ////////////////////////////////////////////////////////////////////////
							// // //PARA CICLO 4 INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////
							// // //PARA CICLO 5 INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05] }
							];
							// // ////////////////////////////////////////////////////////////////////////
							// // //PARA CICLO 6 INSTITUTION
							// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05] }
							];
							// ////////////////////////////////////////////////////////////////////////


							this.params = {
								"id": this.id,
								"name": this.name,
								"institutionsHeadquarters": (this.institutionsHeadquarters),
								"institutionHistorials": (this.institutionHistorials),
								"institutionGeneralTotals": (this.institutionGeneralTotals),
								"cycleTotals": (this.cycleTotals),
								"zonesDataG": this.zonesDataG,
								"zonesDataC2": this.zonesDataC2,
								"zonesDataC3": this.zonesDataC3,
								"zonesDataC4": this.zonesDataC4,
								"zonesDataC5": this.zonesDataC5,
								"zonesDataC6": this.zonesDataC6,
								"from": "institutions"
							}

							localStorage.setItem("params", JSON.stringify(this.params));
							Tools.hideMask();
						},
						error => {
							console.log("Error@Institutions", error);
							Tools.hideMask();
						}
					);
					break;
				}
				////////////////////////////////////////////////////////////////////////
				// Cuando el from que llega como parámetro es igual a "headquarters"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información
				//en el interfaz.
				case "headquarters": {
					Tools.showMask();
					this.listService.headquartersHistorial(params[("id")]).subscribe(
						result => {

							this.id = result["code"];
							this.name = result["name"];
							this.groups = result["groups"];
							this.headquartersHistorials = result["headquartersHistorials"];
							this.headquartersGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];


							let groupContentKey: any = [];


							groupContentKey = Object.keys(this.groups);

							//for cycles
							for (const key in this.groups) {
								const element = this.groups[key];
								if (element["cycleId"] == 2) {
									this.arrayCycle2.push(element);
								} else if (element["cycleId"] == 3) {
									this.arrayCycle3.push(element);
								} else if (element["cycleId"] == 4) {
									this.arrayCycle4.push(element);

								} else if (element["cycleId"] == 5) {
									this.arrayCycle5.push(element);

								} else if (element["cycleId"] == 6) {
									this.arrayCycle6.push(element);

								}

							}

							let c1T = this.headquartersGeneralTotals["Competence1"] * 0.05;
							let c2T = this.headquartersGeneralTotals["Competence2"] * 0.05;
							let c3T = this.headquartersGeneralTotals["Competence3"] * 0.05;

							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA TOTAL HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];
							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA CICLO 2 HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05,] }
							];
							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA CICLO 3 HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05,] }
							];
							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA CICLO 4 HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05,] }
							];
							// 		// ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA CICLO 5 HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05,] }
							];
							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA CICLO 6 HEADQUARTERS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05,] }
							];
							// 		// ////////////////////////////////////////////////////////////////////////
							// 		// ////////////////////////////////////////////////////////////////////////
							Tools.hideMask();
						},
						error => {
							console.log("Error@Headquarters", error);
							Tools.hideMask();
						}
					);
					break;
				}
				////////////////////////////////////////////////////////////////////////
				// Cuando el from que llega como parámetro es igual a "groups"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información
				//en el interfaz.
				case "groups": {
					Tools.showMask();
					this.listService.groupHistorial(params[("id")], params[("cycle")], params[("headquartersCode")]).subscribe(
						result => {

							this.id = result["idGroup"];
							this.name = result["name"];
							this.groupsPlayer = result["players"];
							this.groupCycleId = result["cycleId"];
							this.cycleTotals = result["cycleTotals"];
							this.groupsGeneralTotals = result["generalTotals"];
							this.headquartersCode = result["headquartersCode"];

							let c1T = this.groupsGeneralTotals["Competence1"] * 0.05;
							let c2T = this.groupsGeneralTotals["Competence2"] * 0.05;
							let c3T = this.groupsGeneralTotals["Competence3"] * 0.05;

							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA TOTAL GROUPS
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];
							// // 		// // ////////////////////////////////////////////////////////////////////////
							// // 		// // //PARA CICLO 2 GROUPS
							// // 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05,] }
							];
							// // 		// // ////////////////////////////////////////////////////////////////////////
							// // 		// // //PARA CICLO 3 GROUPS
							// // 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05,] }
							];
							// // 		// // ////////////////////////////////////////////////////////////////////////
							// // 		// // //PARA CICLO 4 GROUPS
							// // 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05,] }
							];
							// // 		// ////////////////////////////////////////////////////////////////////////
							// // 		// // //PARA CICLO 5 GROUPS
							// // 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05,] }
							];
							// // 		// // ////////////////////////////////////////////////////////////////////////
							// // 		// // //PARA CICLO 6 GROUPS
							// // 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05,] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05,] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05,] }
							];


							this.params = {
								"id": this.id,
								"name": this.name,
								"groupsPlayer": (this.groupsPlayer),
								"groupCycleId": (this.groupCycleId),
								"groupsGeneralTotals": (this.groupsGeneralTotals),
								"headquartersCode": (this.headquartersCode),
								"institutionName": params["institutionName"],
								"headquartersName": params["headquartersName"],
								"cycleTotals": (this.cycleTotals),
								"zonesDataG": this.zonesDataG,
								"zonesDataC2": this.zonesDataC2,
								"zonesDataC3": this.zonesDataC3,
								"zonesDataC4": this.zonesDataC4,
								"zonesDataC5": this.zonesDataC5,
								"zonesDataC6": this.zonesDataC6,
								"from": "groups"
							}

							localStorage.setItem("params", JSON.stringify(this.params));
							Tools.hideMask();
						},
						error => {
							console.log("Error@Groups", error);
							Tools.hideMask();
						}
					);
					break;
				}
				////////////////////////////////////////////////////////////////////////
				// Cuando el from que llega como parámetro es igual a "cycles"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información
				//en el interfaz.
				case "cycles": {
					Tools.showMask();
					this.listService.cycleHistorial(params[("id")], params[("headquarterCode")]).subscribe(
						result => {
							this.id = result["id"];
							this.name = result["name"];
							this.cycleHeadquartersName = result["headquartersName"];
							this.cycleHeadquartersCode = result["headquartersCode"];
							this.cyclesPlayer = result["players"];
							this.cyclesGeneralTotals = result["generalTotals"];
							this.cyclesHistorials = result["cycleHistorials"];

							let c1T = this.cyclesGeneralTotals["Competence1"] * 0.05;
							let c2T = this.cyclesGeneralTotals["Competence2"] * 0.05;
							let c3T = this.cyclesGeneralTotals["Competence3"] * 0.05;


							// 		// // ////////////////////////////////////////////////////////////////////////
							// 		// // //PARA TOTAL CYCLES
							// 		// // ////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [c1T] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [c2T] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [c3T] }
							];
							Tools.hideMask();

						}, error => {
							console.log("Error@Cycles", error);
							Tools.hideMask();
						}
					);
					break;
				}

				// Cuando el from que llega como parámetro es igual a "students"
				//se le asigna a nuestro objeto, para así mismo mostrar esa información
				//en el interfaz.
				case "students": {
					Tools.showMask();

					this.listService.playerHistorial(params["username"]).subscribe(

						result => {
							this.students = result["player"];
							this.actualGames = result["actualGame"];
							this.username = this.students["username"];
							this.historials = result["historials"];
							this.entryCount = result["entryCount"];
							this.entryLast = result["entryLast"];
							this.firstName = this.students["firstName"];
							this.secondName = this.students["secondName"];
							this.firstLastName = this.students["surname"];
							this.secondLastName = this.students["secondSurname"];
							this.cycleId = String(this.students["cycleId"]);
							this.groupName = String(this.students["groupName"]);
							this.headquartersName = String(this.students["headquartersName"]);
							this.institutionName = String(this.students["institutionName"]);
							this.zoneName = String(this.students["zoneName"]);

							////////////////////////////////////////////////////////////////////////
							////////////////////////////////////////////////////////////////////////
							let c1 = [];
							let c2 = [];
							let c3 = [];
							////////////////////////////////////////////////////////////////////////
							////////////////////////////////////////////////////////////////////////
							for (const id in this.historials) {
								switch (this.historials[id]["competenceId"]) {
									case "1":
										c1.push(this.historials[id]["total"]);
										break;
									case "2":
										c2.push(this.historials[id]["total"]);
										break;
									case "3":
										c3.push(this.historials[id]["total"]);
										break;
									case 1:
										c1.push(this.historials[id]["total"]);
										break;
									case 2:
										c2.push(this.historials[id]["total"]);
										break;
									case 3:
										c3.push(this.historials[id]["total"]);
										break;

									default:
										break;
								}
							}


							////////////////////////////////////////////////////////////////////////
							////////////////////////////////////////////////////////////////////////
							let promC1 = ((c1.length > 0) ? c1.reduce(function (acc, val) { return acc + val; }) / c1.length : 0) * 0.05;
							let promC2 = ((c2.length > 0) ? c2.reduce(function (acc, val) { return acc + val; }) / c2.length : 0) * 0.05;
							let promC3 = ((c3.length > 0) ? c3.reduce(function (acc, val) { return acc + val; }) / c3.length : 0) * 0.05;
							////////////////////////////////////////////////////////////////////////
							////////////////////////////////////////////////////////////////////////
							this.data1 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [promC1] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [promC2] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [promC3] }
							];


							Tools.hideMask();


						},
						error => {
							console.log("Error@Students", error);
							Tools.hideMask();
						}
					);
					break;
				}


				case "projects": {
					Tools.showMask();
					this.name = params["name"];
					this.id = params["id"];

					this.listService.projectsHistorial(params["id"]).subscribe(
						result => {
							this.zonesTowns = result["towns"];
							this.zonesGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];

							////////////////////////////////////////////////////////////////////////
							//PARA TOTAL ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [(this.zonesGeneralTotals["Competence1"] * 0.05)] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [(this.zonesGeneralTotals["Competence2"] * 0.05)] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [(this.zonesGeneralTotals["Competence3"] * 0.05)] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 2 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 3 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								// parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05,
								// parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05,
								// parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 4 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 5 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 6 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////



							this.params = {
								"id": this.id,
								"name": this.name,

								"zonesGeneralTotals": (this.zonesGeneralTotals),
								"zonesTowns": (this.zonesTowns),

								"cycleTotals": (this.cycleTotals),
								"zonesDataG": this.zonesDataG,
								"zonesDataC2": this.zonesDataC2,
								"zonesDataC3": this.zonesDataC3,
								"zonesDataC4": this.zonesDataC4,
								"zonesDataC5": this.zonesDataC5,
								"zonesDataC6": this.zonesDataC6,
								"from": "projects"
							}

							localStorage.setItem("params", JSON.stringify(this.params));
							Tools.hideMask();


						},
						error => {
							console.log("Error@Projects", error);
							Tools.hideMask();
						}
					);
					break;
				}

				case "teachers": {
					Tools.showMask();
					//CONSOLE.LOG("esto es from", this.from);
					this.name = params["name"];
					this.id = params["id"];

					this.listService.projectsHistorial(params["id"]).subscribe(
						result => {
							this.zonesTowns = result["towns"];
							this.zonesGeneralTotals = result["generalTotals"];
							this.cycleTotals = result["cycleTotals"];

							////////////////////////////////////////////////////////////////////////
							//PARA TOTAL ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataG = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [(this.zonesGeneralTotals["Competence1"] * 0.05)] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [(this.zonesGeneralTotals["Competence2"] * 0.05)] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [(this.zonesGeneralTotals["Competence3"] * 0.05)] }
							];

							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 2 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC2 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle2"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle2"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle2"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 3 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC3 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle3"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle3"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle3"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 4 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC4 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle4"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle4"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle4"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 5 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC5 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle5"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle5"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle5"]["Competence3"]) * 0.05] }
							];
							////////////////////////////////////////////////////////////////////////
							//PARA CICLO 6 ZONAS
							////////////////////////////////////////////////////////////////////////
							this.zonesDataC6 = [
								{ label: "Lectura crítica", backgroundColor: "#E87587", data: [parseInt(this.cycleTotals["Cycle6"]["Competence1"]) * 0.05] },
								{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [parseInt(this.cycleTotals["Cycle6"]["Competence2"]) * 0.05] },
								{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [parseInt(this.cycleTotals["Cycle6"]["Competence3"]) * 0.05] }
							];

							//Se le asigna al objeto zoneContent, los id ordenados de las zonas traidas en zones.
							// this.zonesContent= Object.keys(this.zones);

							//Se imprime en consola el objeto con los id ordenados.

							Tools.hideMask();


						},
						error => {
							console.log("Error@Teachers", error);
							Tools.hideMask();
						}
					);
					break;
				}
			}

			//-------------------------------------------------------------------------------------------
		});



	}
	onMenu_toggle() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		document.getElementById("menu").classList.toggle("ocultar");
		document.getElementById("menu-black-background").classList.toggle("hide");
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		//CONSOLE.LOG("que me llego de row: ", buffer);
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		var today = new Date();
		var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '_';
		// var time = today.getHours() + "/" + today.getMinutes() + "/" + today.getSeconds();
		var name = fileName + "_" + date;
		FileSaver.saveAs(data, "MUNICIPIO_" + name + EXCEL_EXTENSION);
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		//CONSOLE.LOG("excelbuffeR: ", excelBuffer);
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}


	//-------------------------------------------------------------------------------------------------------------
	///////////////////////////////////////////BOLIVAR CAUCA///////////////////////////////////////////////////////
	// downloadBolivarInstitution(bolivarInstitutions:any, townsPlayer:any, allPlayers:any, playerhavePlayed:any){
	// 	// //CONSOLE.LOG("lo que me llego de result: ", bolivarInstitutions);
	// 	// //CONSOLE.LOG("bolivar solamente: ", bolivarInstitutions);
	// 	for (const key in townsPlayer) {
	// 		if (townsPlayer.hasOwnProperty(key)) {
	// 			const element = townsPlayer[key];
	// 			let institutionName = element["institutionName"];
	// 			let playerBelongToInstitution = this.bolivar["institutions"][institutionName] = element;

	// 			if (typeof this.bolivarInstitutionStudents[institutionName] === 'undefined') {
	// 				this.bolivarInstitutionStudents[institutionName] = [];
	// 			}

	// 			this.bolivarInstitutionStudents[institutionName].push(playerBelongToInstitution);

	// 			// //CONSOLE.LOG("que es esto: ", playerBelongToInstitution);
	// 			// //CONSOLE.LOG("prueba instituciones bolivar: ", institutionName);


	// 		}
	// 	}
	// 	//CONSOLE.LOG(this.bolivarInstitutionStudents);
	// 	// //CONSOLE.LOG("instituciones Bolivar: ", this.bolivarInstitutionStudents);





	// 	for (let key in this.bolivarInstitutionStudents) {
	// 		if (this.bolivarInstitutionStudents.hasOwnProperty(key)) {
	// 			let playerOfInstitution = this.bolivarInstitutionStudents[key];

	// 			let rowsito = [];

	// 			let institutionName;
	// 			let contador = 0;
	// 			for (let key in playerOfInstitution) {
	// 				contador+=1;
	// 				if (playerOfInstitution.hasOwnProperty(key)) {
	// 					let element = playerOfInstitution[key];

	// 					let firstName = element["firstName"];
	// 					let secondName = element["secondName"];
	// 					let surname = element["surname"];
	// 					let secondSurname = element["secondSurname"];
	// 					let gameCompetence = element["gameCompetence"];
	// 					let gameCycle = element["groupCycleId"];
	// 					let gameType = element["gameType"];
	// 					let headquartersName = element["headquartersName"];
	// 					institutionName = element["institutionName"];
	// 					let groupIdGroup = element["groupIdGroup"];
	// 					rowsito.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, secondName, surname, secondSurname, gameCompetence, gameType]);	
	// 				}
	// 			}
	// 			// //CONSOLE.LOG("contador", contador);
	// 			// //CONSOLE.LOG("rowsito", rowsito);


	// 			let doc = new jsPDF('p', 'pt');

	// 			let titleInstitutionBolivar = "Municipio Bolivar - Institución: " + institutionName;
	// 			let splitTitle = doc.splitTextToSize(titleInstitutionBolivar, 380);
	// 			doc.text(40, 30, splitTitle);

	// 			let dataExcel = [];
	// 			for (let key in rowsito) {
	// 				if (rowsito.hasOwnProperty(key)) {
	// 					let element = rowsito[key];
	// 					dataExcel.push(
	// 							{
	// 							ciclo: element[0],
	// 							grupo: element[1],
	// 							institución: element[2],
	// 							sede: element[3],
	// 							nombre1: element[4],
	// 							nombre2: element[5],
	// 							apellido1: element[6],
	// 							apellido2: element[7],
	// 							competencia: element[8],
	// 							tipo: element[9]
	// 						}

	// 					);
	// 				}
	// 			}
	// 			//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 			this.exportAsExcelFile(dataExcel, institutionName);
	// 			doc.autoTable(this.columns, rowsito, {

	// 				// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 				styles: {
	// 					cellPadding: 5, // a number, array or object (see margin below)
	// 					fontSize: 5,
	// 					font: "helvetica", // helvetica, times, courier
	// 					lineColor: 200,
	// 					lineWidth: 0,
	// 					fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 					overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 					// fillColor: [92,111,254], // false for transparent or a color as described below
	// 					// textColor: 20,
	// 					halign: 'center', // left, center, right
	// 					valign: 'middle', // top, middle, bottom
	// 					columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 				},
	// 				margin: { top: 80 }
	// 			});
	// 			// doc.text(this.titleList);
	// 			doc.save('Lista de estudiantes DPA ' + institutionName + '.pdf');
	// 		}
	// 	}

	// }
	// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// downloadBolivarInstitutionHaveNotPlayedDiagnostic(bolivarInstitutions:any, townsPlayer:any, allPlayers:any, playerhavePlayed:any){
	// 	// //CONSOLE.LOG("lo que me llego de result: ", bolivarInstitutions);
	// 	// //CONSOLE.LOG("bolivar solamente: ", bolivarInstitutions);
	// 	for (const key in townsPlayer) {
	// 		if (townsPlayer.hasOwnProperty(key)) {
	// 			const element = townsPlayer[key];
	// 			let institutionName = element["institutionName"];
	// 			let playerBelongToInstitution = this.bolivar["institutions"][institutionName] = element;

	// 			if (typeof this.bolivarInstitutionStudents[institutionName] === 'undefined') {
	// 				this.bolivarInstitutionStudents[institutionName] = [];
	// 			}

	// 			this.bolivarInstitutionStudents[institutionName].push(playerBelongToInstitution);

	// 			// //CONSOLE.LOG("que es esto: ", playerBelongToInstitution);
	// 			// //CONSOLE.LOG("prueba instituciones bolivar: ", institutionName);


	// 		}
	// 	}
	// 	//CONSOLE.LOG(this.bolivarInstitutionStudents);
	// 	// //CONSOLE.LOG("instituciones Bolivar: ", this.bolivarInstitutionStudents);





	// 	for (let key in this.bolivarInstitutionStudents) {
	// 		if (this.bolivarInstitutionStudents.hasOwnProperty(key)) {
	// 			let playerOfInstitution = this.bolivarInstitutionStudents[key];

	// 			let rowsito = [];

	// 			let institutionName;
	// 			let contador = 0;
	// 			for (let key in playerOfInstitution) {
	// 				contador+=1;
	// 				if (playerOfInstitution.hasOwnProperty(key)) {
	// 					let element = playerOfInstitution[key];

	// 					let firstName = element["firstName"];
	// 					let secondName = element["secondName"];
	// 					let surname = element["surname"];
	// 					let secondSurname = element["secondSurname"];
	// 					let gameCompetence = element["gameCompetence"];
	// 					let gameCycle = element["groupCycleId"];
	// 					let gameType = element["gameType"];
	// 					let headquartersName = element["headquartersName"];
	// 					institutionName = element["institutionName"];
	// 					let groupIdGroup = element["groupIdGroup"];
	// 					let lc = "";
	// 					let rc = "";
	// 					let ce = "";

	// 					if (gameCompetence == "LECTURA CRÍTICA" && gameType == "Diagnóstico") {
	// 						// //CONSOLE.LOG("entro LC");
	// 						lc = "X";
	// 						rc = "";
	// 						ce = ""

	// 					} else if (gameCompetence == "RAZONAMIENTO CUANTITATIVO" && gameType == "Diagnóstico") {
	// 						// //CONSOLE.LOG("entro RC");
	// 						lc = "X";
	// 						rc = "X";
	// 						ce = "";

	// 					} else if (gameCompetence == "Comunicación ESCRITA" && gameType == "Diagnóstico") {
	// 						// //CONSOLE.LOG("entro CE");
	// 						lc = "X";
	// 						rc = "X";
	// 						ce = "X";
	// 					}
	// 					rowsito.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname,  lc, rc,ce]);	
	// 				}
	// 			}
	// 			// //CONSOLE.LOG("contador", contador);
	// 			// //CONSOLE.LOG("rowsito", rowsito);


	// 			let doc = new jsPDF('p', 'pt');

	// 			let titleInstitutionBolivar = "Municipio Bolivar - Institución: " + institutionName;
	// 			let titleTypeInstitutionBolivar = "Tipo: Diagnóstico";
	// 			let splitTitle = doc.splitTextToSize(titleInstitutionBolivar, 380);
	// 			doc.text(40, 30, splitTitle);
	// 			let splitTitle2 = doc.splitTextToSize(titleTypeInstitutionBolivar, 380);
	// 			doc.text(40, 80, splitTitle2);

	// 			let dataExcel = [];
	// 			for (let key in rowsito) {
	// 				if (rowsito.hasOwnProperty(key)) {
	// 					let element = rowsito[key];
	// 					dataExcel.push(
	// 						{
	// 							ciclo: element[0],
	// 							grupo: element[1],
	// 							institución: element[2],
	// 							sede: element[3],
	// 							nombre1: element[4],
	// 							appelido1: element[5],
	// 							lc: element[6],
	// 							rc: element[7],
	// 							ce: element[8]
	// 						}
	// 					);
	// 				}
	// 			}
	// 			//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 			let institutionNameForExcel = institutionName+"-"+"Diagnóstico";
	// 			this.exportAsExcelFile(dataExcel, institutionNameForExcel);
	// 			doc.autoTable(this.columns2, rowsito, {

	// 				// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 				styles: {
	// 					cellPadding: 5, // a number, array or object (see margin below)
	// 					fontSize: 5,
	// 					font: "helvetica", // helvetica, times, courier
	// 					lineColor: 200,
	// 					lineWidth: 0,
	// 					fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 					overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 					// fillColor: [92,111,254], // false for transparent or a color as described below
	// 					// textColor: 20,
	// 					halign: 'center', // left, center, right
	// 					valign: 'middle', // top, middle, bottom
	// 					columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 				},
	// 				margin: { top: 100 }
	// 			});
	// 			// doc.text(this.titleList);
	// 			doc.save('Lista de estudiantes DPA ' + institutionName + '.pdf');
	// 		}
	// 	}

	// }
	// //////////////////////////////////////////////////////////////////////////////////////////////////
	// downloadBolivarInstitutionHaveNotPlayedAdvance(bolivarInstitutions:any, townsPlayer:any, allPlayers:any, playerhavePlayed:any){
	// 	// //CONSOLE.LOG("lo que me llego de result: ", bolivarInstitutions);
	// 	// //CONSOLE.LOG("bolivar solamente: ", bolivarInstitutions);
	// 	for (const key in townsPlayer) {
	// 		if (townsPlayer.hasOwnProperty(key)) {
	// 			const element = townsPlayer[key];
	// 			let institutionName = element["institutionName"];
	// 			let playerBelongToInstitution = this.bolivar["institutions"][institutionName] = element;

	// 			if (typeof this.bolivarInstitutionStudents[institutionName] === 'undefined') {
	// 				this.bolivarInstitutionStudents[institutionName] = [];
	// 			}

	// 			this.bolivarInstitutionStudents[institutionName].push(playerBelongToInstitution);

	// 			// //CONSOLE.LOG("que es esto: ", playerBelongToInstitution);
	// 			// //CONSOLE.LOG("prueba instituciones bolivar: ", institutionName);


	// 		}
	// 	}
	// 	//CONSOLE.LOG(this.bolivarInstitutionStudents);
	// 	// //CONSOLE.LOG("instituciones Bolivar: ", this.bolivarInstitutionStudents);





	// 	for (let key in this.bolivarInstitutionStudents) {
	// 		if (this.bolivarInstitutionStudents.hasOwnProperty(key)) {
	// 			let playerOfInstitution = this.bolivarInstitutionStudents[key];

	// 			let rowsito = [];

	// 			let institutionName;
	// 			let contador = 0;
	// 			for (let key in playerOfInstitution) {
	// 				contador+=1;
	// 				if (playerOfInstitution.hasOwnProperty(key)) {
	// 					let element = playerOfInstitution[key];

	// 					let firstName = element["firstName"];
	// 					let secondName = element["secondName"];
	// 					let surname = element["surname"];
	// 					let secondSurname = element["secondSurname"];
	// 					let gameCompetence = element["gameCompetence"];
	// 					let gameCycle = element["groupCycleId"];
	// 					let gameType = element["gameType"];
	// 					let headquartersName = element["headquartersName"];
	// 					institutionName = element["institutionName"];
	// 					let groupIdGroup = element["groupIdGroup"];
	// 					let lc = "";
	// 					let rc = "";
	// 					let ce = "";

	// 					if (gameCompetence == "LECTURA CRÍTICA" && gameType == "Avance") {
	// 						// //CONSOLE.LOG("entro LC");
	// 						lc = "X";
	// 						rc = "";
	// 						ce = ""

	// 					} else if (gameCompetence == "RAZONAMIENTO CUANTITATIVO" && gameType == "Avance") {
	// 						// //CONSOLE.LOG("entro RC");
	// 						lc = "X";
	// 						rc = "X";
	// 						ce = "";

	// 					} else if (gameCompetence == "Comunicación ESCRITA" && gameType == "Avance") {
	// 						// //CONSOLE.LOG("entro CE");
	// 						lc = "X";
	// 						rc = "X";
	// 						ce = "X";
	// 					}
	// 					rowsito.push([gameCycle, groupIdGroup, institutionName, headquartersName, firstName, surname,  lc, rc,ce]);	
	// 				}
	// 			}
	// 			// //CONSOLE.LOG("contador", contador);
	// 			// //CONSOLE.LOG("rowsito", rowsito);


	// 			let doc = new jsPDF('p', 'pt');

	// 			let titleInstitutionBolivar = "Municipio Bolivar - Institución: " + institutionName;
	// 			let titleTypeInstitutionBolivar = "Tipo: Avance";
	// 			let splitTitle = doc.splitTextToSize(titleInstitutionBolivar, 380);
	// 			doc.text(40, 30, splitTitle);
	// 			let splitTitle2 = doc.splitTextToSize(titleTypeInstitutionBolivar, 380);
	// 			doc.text(40, 80, splitTitle2);

	// 			let dataExcel = [];
	// 			for (let key in rowsito) {
	// 				if (rowsito.hasOwnProperty(key)) {
	// 					let element = rowsito[key];
	// 					dataExcel.push(
	// 						{
	// 							ciclo: element[0],
	// 							grupo: element[1],
	// 							institución: element[2],
	// 							sede: element[3],
	// 							nombre1: element[4],
	// 							appelido1: element[5],
	// 							lc: element[6],
	// 							rc: element[7],
	// 							ce: element[8]
	// 						}
	// 					);
	// 				}
	// 			}
	// 			//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 			let institutionNameForExcel = institutionName +"- "+ "Avance";
	// 			this.exportAsExcelFile(dataExcel, institutionNameForExcel);



	// 			doc.autoTable(this.columns2, rowsito, {

	// 				// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 				styles: {
	// 					cellPadding: 5, // a number, array or object (see margin below)
	// 					fontSize: 5,
	// 					font: "helvetica", // helvetica, times, courier
	// 					lineColor: 200,
	// 					lineWidth: 0,
	// 					fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 					overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 					// fillColor: [92,111,254], // false for transparent or a color as described below
	// 					// textColor: 20,
	// 					halign: 'center', // left, center, right
	// 					valign: 'middle', // top, middle, bottom
	// 					columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 				},
	// 				margin: { top: 100 }
	// 			});
	// 			// doc.text(this.titleList);
	// 			// doc.save('Lista de estudiantes DPA ' + institutionName + '.pdf');
	// 		}
	// 	}

	// }
	//////////////////////////////////////////////////////////////////////////////////////////////////

	params: any;
	sendChildData() {


		let bigJson1 = {
			"name": this.name,
			"institutionTowns": this.institutionsTowns,
			"general": this.townsPlayer,
			"diagnostico": this.rowDIAGNOSTICO,
			"avance1": this.rowAVANCE1,
			"avance2": this.rowAVANCE2,
			"avance3": this.rowAVANCE3,
			"avance4": this.rowAVANCE4,
			"avance5": this.rowAVANCE5,
			"avance6": this.rowAVANCE6,
			"cycleTotals": this.cycleTotals,
			"institutionTownCycles": this.institutionTownCycles,
			"evaluacion": this.rowEVALUACION,
			"evaluacionGeneral": this.rowEVALUACIONGENERAL
		}

		window.localStorage.setItem('bigJson', JSON.stringify(bigJson1));
		this.router.navigate(['/getReport']);
		//CONSOLE.LOG("VE GRAN HIJUEPUTA", bigJson1);

	}

	////////////////////////////////////////////////////
	// //Download ListStudent
	// downloadListStudent(townName: any, allPlayer: any, playersHavePlayed:any) {
	// 	//CONSOLE.LOG("esto tiene townName: ", townName);
	// 	//CONSOLE.LOG("esto tiene all players: ", allPlayer);
	// 	//CONSOLE.LOG("esto tiene playerHavePlayed: ", playersHavePlayed);
	// 	let doc = new jsPDF('p', 'pt');
	// 	let titleFinal = this.titleList.concat(townName);
	// 	let allPlayerTextFinal = this.allPlayerText.concat(allPlayer);
	// 	let playersHavePlayedFinal = this.playerHavePlayedText.concat(playersHavePlayed);
	// 	// jsPDFAPI.addImage = function (imageData, format, x, y, w, h, alias, compression, rotation)


	// 	// let image = doc.addImage(this.image, 'PNG', 40, 20);
	// 	let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 	doc.text(40, 30, splitTitle);


	// 	let splitTitle2 = doc.splitTextToSize(allPlayerTextFinal, 280);
	// 	doc.text(splitTitle2, 40, 50);


	// 	let splitTitle3 = doc.splitTextToSize(playersHavePlayedFinal, 280);
	// 	doc.text(splitTitle3, 40, 70);

	// 	// //CONSOLE.LOG("esto es row lleno con el municipio: ",this.row);

	// 	let dataExcel = [];
	// 	for (let key in this.printAll) {
	// 		if (this.printAll.hasOwnProperty(key)) {
	// 			let element = this.printAll[key];
	// 			dataExcel.push(
	// 				{ ciclo: element[0],
	// 					 grupo: element[1],
	// 					 institución: element[2],
	// 					 sede: element[3],
	// 					 nombre1: element[4],
	// 					 nombre2: element[5],
	// 					 apellido1: element[6],
	// 					 apellido2: element[7],
	// 					 competencia: element[8],
	// 					 tipo: element[9]}
	// 			);
	// 		}
	// 	}
	// 	// //CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 	this.exportAsExcelFile(dataExcel, townName);

	// 	doc.autoTable(this.columns, this.printAll, {
	// 		// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 		styles: {
	// 			cellPadding: 5, // a number, array or object (see margin below)
	// 			fontSize: 5,
	// 			font: "helvetica", // helvetica, times, courier
	// 			lineColor: 200,
	// 			lineWidth: 0,
	// 			fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 			overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 			// fillColor: [92,111,254], // false for transparent or a color as described below
	// 			// textColor: 20,
	// 			halign: 'center', // left, center, right
	// 			valign: 'middle', // top, middle, bottom
	// 			columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 		},
	// 		margin: { top: 80 }
	// 	});
	// 	// doc.text(this.titleList);
	// 	doc.save('Lista de estudiantes DPA '+townName+'.pdf');
	// 	// this.location.back();

	// }

	//FUNCIÓN PARA TODAS LAS DESCARGAS
	// downloadListAdvanceAndDiagnostic(townName:string, allPlayer:any, type:string){
	// 	//CONSOLE.LOG("esto es name: ", townName);
	// 	//CONSOLE.LOG("esto es players: ", allPlayer);
	// 	//CONSOLE.LOG("esto es tipo: ",type);

	// 	if (type=="diagnostico") {
	// 		//CONSOLE.LOG("entró diagnostico");
	// 		//CONSOLE.LOG("printDiagnostico:", this.printDIAGNOSTICO);
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Diágnostico";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printDIAGNOSTICO) {
	// 			if (this.printDIAGNOSTICO.hasOwnProperty(key)) {
	// 				let element = this.printDIAGNOSTICO[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Diagnóstico";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printDIAGNOSTICO, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 		////////////////////////////////////////////////////////////
	// 	}else if(type=="avance1"){
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE1) {
	// 			if (this.printAVANCE1.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE1[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE1, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 		////////////////////////////////////////////////////////////
	// 	}else if(type == "avance2"){
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE2) {
	// 			if (this.printAVANCE2.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE2[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE2, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 	}else if(type == "avance3") {
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE3) {
	// 			if (this.printAVANCE3.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE3[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE3, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 	}else if(type == "avance4") {
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE4) {
	// 			if (this.printAVANCE4.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE4[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE4, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 	}else if(type == "avance5") {
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE5) {
	// 			if (this.printAVANCE5.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE5[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE5, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');	
	// 	}else if(type == "avance6") {
	// 		let doc = new jsPDF('p', 'pt');
	// 		let titleFinal = this.titleList.concat(townName);
	// 		let titleGameCompetence: string = "Tipo: Avance";
	// 		let splitTitle = doc.splitTextToSize(titleFinal, 380);
	// 		doc.text(40, 30, splitTitle);
	// 		let splitTitle3 = doc.splitTextToSize(titleGameCompetence, 280);
	// 		doc.text(splitTitle3, 40, 70);
	// 		let dataExcel = [];
	// 		for (let key in this.printAVANCE6) {
	// 			if (this.printAVANCE6.hasOwnProperty(key)) {
	// 				let element = this.printAVANCE6[key];
	// 				dataExcel.push(
	// 					{
	// 						ciclo: element[0],
	// 						grupo: element[1],
	// 						institución: element[2],
	// 						sede: element[3],
	// 						nombre1: element[4],
	// 						apellido1: element[5],
	// 						lc: element[6],
	// 						rc: element[7],
	// 						ce: element[8]
	// 					}
	// 				);
	// 			}
	// 		}
	// 		//CONSOLE.LOG("así quedo el dataExcel: ", dataExcel);
	// 		let townNameForExcel = townName + "-" + "Avance";
	// 		this.exportAsExcelFile(dataExcel, townNameForExcel);
	// 		doc.autoTable(this.columns2, this.printAVANCE6, {
	// 			// theme: 'striped', // 'striped', 'grid' or 'plain'
	// 			styles: {
	// 				cellPadding: 5, // a number, array or object (see margin below)
	// 				fontSize: 5,
	// 				font: "helvetica", // helvetica, times, courier
	// 				lineColor: 200,
	// 				lineWidth: 0,
	// 				fontStyle: 'normal', // normal, bold, italic, bolditalic
	// 				overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
	// 				// fillColor: [92,111,254], // false for transparent or a color as described below
	// 				// textColor: 20,
	// 				halign: 'center', // left, center, right
	// 				valign: 'middle', // top, middle, bottom
	// 				columnWidth: 'auto' // 'auto', 'wrap' or a number
	// 			},
	// 			margin: { top: 80 }
	// 		});
	// 		doc.save('Lista de estudiantes DPA ' + townName + '.pdf');
	// 	}
	// }

	//Funciones para enviar los parámetros desde el mismo chart
	//recibe un id de (zones)  como variable de tipo número
	onSelectZone(selectname: string) {
		let params: NavigationExtras = {
			queryParams: {
				"name": selectname,
				"from": "zones"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//Funciones para enviar los parámetros desde el mismo chart
	//recibe un id towns como variable de tipo número
	onSelectTown(selectedId: number) {
		let params: NavigationExtras = {
			queryParams: {
				"id": selectedId,
				"from": "towns"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//Funciones para enviar los parámetros desde el mismo chart
	//recibe un id de institution como variable de tipo número
	onSelectInstitution(selectedId: number) {
		let params: NavigationExtras = {
			queryParams: {
				"id": selectedId,
				"from": "institutions"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//Funciones para enviar los parámetros desde el mismo chart
	//recibe un id de headquarter como variable de tipo número
	onSelectHeadquarter(selectedId: number) {
		let params: NavigationExtras = {
			queryParams: {
				"id": selectedId,
				"from": "headquarters"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//Funciones para enviar los parámetros desde el mismo chart
	//recibe un id de cycle como variable de tipo número
	onSelectCycle(selectedId: number) {
		let params: NavigationExtras = {
			queryParams: {
				"id": selectedId,
				"from": "cycles"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}

	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	onSelectGroup(selected: any) {
		let params: NavigationExtras = {
			queryParams: {
				"id": selected["idGroup"],
				"cycle": selected["cycleId"],
				"headquartersCode": selected["headquartersCode"],
				"from": "groups"
			}
		};
		this.router.navigate(['/home/chart'], params);
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	onSelectStudent(selectedId: number, username: string) {
		let params: NavigationExtras = {
			queryParams: {
				"username": username,
				"id": selectedId,
				"from": "students"
			}
		};
		this.router.navigate(['/home/chart'], params);

	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//---------------------------------------------------------------------------------------------------------------
	// Permite la funcionalidad de volver atrás
	goBack() {
		this.location.back();
	}
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	ngOnInit() { }

	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	//---------------------------------------------------------------------------------------------------------------


	//arreglo con los colores que se le asignarán a las columnas de las gráficas
	public chartColors: any[] = [{
			backgroundColor: ["#E87587", "#54BCCF", "#BFCC3D"]
		}];
	////////////////////////////////////////////////////////////////////////
	// Tipo de columnas a utilizar para representar la información de las gráficas
	public type: string = 'bar';
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	public labels: string[];
	public zonesLabelsG: string[];
	public zonesLabelsC2: string[];
	public zonesLabelsC3: string[];
	public zonesLabelsC4: string[];
	public zonesLabelsC5: string[];
	public zonesLabelsC6: string[];
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	public zonesDataG: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	public zonesDataC2: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	public zonesDataC3: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	public zonesDataC4: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	public zonesDataC5: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	public zonesDataC6: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	public data1: any = [
		{ label: "Lectura crítica", backgroundColor: "#E87587", data: [0] },
		{ label: "Razonamiento cuantitativo", backgroundColor: "#54BCCF", data: [0] },
		{ label: "Comunicación escrita", backgroundColor: "#BFCC3D", data: [0] }
	];
	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////
	public options1: any = {

		tooltips: {
			display: true
		},
		legend: {
			display: true,

		},
		responsive: true,
		responsiveAnimationDuration: 0,
		maintainAspectRatio: false,

		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					max: 5,
					min: 0,
					fontSize: 13,


					callback: function (value, index, values) {

						var valueDesc = '';
						switch (value) {
							case 5:
								valueDesc = 'Superior';
								break;
							case 4:
								valueDesc = 'Alto';
								break;
							case 3:
								valueDesc = 'Medio';
								break;
							case 2:
								valueDesc = 'Básico';
								break;
							case 1:
								valueDesc = 'Bajo';
								break;
							default:
								valueDesc = null;
								break;
						}
						return valueDesc;
					}
				}
			}],
			xAxes: [{

				display: true,
				ticks: {
					fontSize: 8
				}
			}]
		}
	};

}