import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material';
import Tools from '../tools';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Popupcarga } from '../msg/popupcarga';
import { Location } from '@angular/common';
import { ListService } from '../_services/list.service';
import * as _ from 'lodash';

// PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

@Component({
	selector: 'students',
	templateUrl: './students.component.html',
	styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
	excelInput: any;
	fileInput: any;
	excelReader: FileReader;
	dialogRef: MatDialogRef<Popupcarga, any>;
	title: String;
	option: number;
	allIds: Array<String>;
	activeIds: Array<String>;
	auxIds: Array<String>;
	pageSize: number = 50;
	length: number;
	searchText: string = '';
	pageEvent: PageEvent;

	params: NavigationExtras;
	paramsPrint: any;

	rolname: string;
	userInstitution: number;
	students = [];
	studentsAux: any = [];
	constructor(
		private listService: ListService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		public dialog: MatDialog,
		private location: Location,
		) {

		Tools.showMask();
		this.listService.getStudents(localStorage.getItem('username')).subscribe(
			result => {
				// let papi=[];
				this.students = result["players"];
				this.studentsAux = result["players"];
				this.length = this.students.length;
				this.students = this.studentsAux.slice(0, this.pageSize);
				if (this.pageEvent) {
					this.pageEvent.pageIndex = 0;
				}
				// console.log("Que tipo es? = ", typeof (this.students));

				console.log("los players son: ", this.students);


				Tools.hideMask();
			},
			error => {
				console.log(<any>error);
				Tools.hideMask();
			}
		);

		this.activeRoute.queryParams.subscribe(params => {
			this.option = Tools.homeOptions[params.option];

		});

		let uid = localStorage.getItem("user");
		this.params = {
			queryParams: [String(this.userInstitution), String(this.rolname)],
		};


	}
	ngOnInit() { }
	docDefinition: any = {
		content: [
			{
				layout: 'noBorders', // optional
				width: 'auto',
				table: {
					headerRows: 1,
					body: [
						[{
							columns: [
								{ width: '*', text: '' },
								{
									image: Tools.header,
									width: 500,
									alignment: 'center'
								},
								{ width: '*', text: '' },
							]
						}],
						[{
							text: 'Listado de estudiantes',
							style: 'header',
							alignment: 'center'
						}],
						[{
							columns: [
								{ width: '*', text: '' },
								{
									layout: 'lightHorizontalLines', // optional
									width: 'auto',
									table: {
										headerRows: 1,
										body: [
											['Nombre', 'Usuario']
										]
									}
								},
								{ width: '*', text: '' },

							]

						}],
					]
				}
			}
		],
		styles: {
			header: {
				fontSize: 18,
				bold: true,
				alignment: 'justify'
			},
			tableExample: {
				margin: [0, 5, 0, 15]
			},
		}
	};
	async downloadPdf() {
		Tools.showMask();
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
		console.log("doc", this.docDefinition);

		this.studentsAux.forEach(element => {
			this.docDefinition['content'][0]['table']['body'][2][0]['columns'][1]['table']['body'].push([`${element.firstName} ${element.secondName} ${element.surname} ${element.secondSurname}`.replace(/null/g, ''), element.username]);
		});

		pdfMake.createPdf(this.docDefinition).download("Estudiantes.pdf");
		Tools.hideMask();

	}

	onSelectStudent(selectedId: number, username: string) {
		console.log("este es el id del estudiante", selectedId);
		console.log("este es el username del estudiante", username);
		var self = this;
		let params: NavigationExtras = {
			queryParams: {
				"username": username,
				"id": selectedId,
				"from": "students"
			}
		};
		self.router.navigate(['/home/chart'], params);

	}

	onChangePage(event: PageEvent) {
		let self = this,
			size = self.pageSize,
			begin = size * event.pageIndex,
			end = begin + size;
		self.pageEvent = event;
		this.students = this.studentsAux.slice(begin, end);
	}
	hideSearch() {
		// document.getElementById("menu").classList.toggle("ocultar-search");
		document.getElementById("searchSection").style.display = "none";
		document.getElementById("arrow-btn").style.display = "none";
	}

	searchMobile() {
		document.getElementById("searchSection").style.display = "flex";
		document.getElementById("arrow-btn").style.display = "inline-block";
	}


	onMenu_toggle() {
		document.getElementById("menu").classList.toggle("ocultar");
		document.getElementById("menu-black-background").classList.toggle("hide");
		// document.getElementById("btn-menu").classList.toggle("btn-izq");
		// document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
	}

	onTapSearch() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		// console.log("width",w);
		// console.log("height",h);
		if (w >= 768) {
			// console.log("Desktop");
			var self = this,
				hideCls = "hide-field",
				field = document.getElementById("search-field"),
				input = field.getElementsByTagName('input')[0],
				btn = document.getElementById("search-btn");
			btn.className = hideCls;
			field.className = "";
			input.focus();
			input.onblur = function () {
				if (!self.searchText.trim()) {
					btn.className = "";
					field.className = hideCls;
				}

			}
		}

	}

	editSearch: boolean = false;

	DoSearch() {

		let self = this,
			filter = [],
			searchText = self.searchText.trim();

		if (!searchText && self.editSearch) {
			self.students = self.studentsAux;
		} else {
			self.editSearch = true;
		}

		for (let position in this.studentsAux) {
			if (this.studentsAux.hasOwnProperty(position)) {
				let element = this.studentsAux[position];
				let fullName = "";

				if (element["firstName"]) {
					fullName += element["firstName"];
				}
				if (element["secondName"]) {
					fullName += " " + element["secondName"];
				}
				if (element["surname"]) {
					fullName += " " + element["surname"];
				}
				if (element["secondSurname"]) {
					fullName += " " + element["secondSurname"];
				}

				if (fullName.toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
					filter.push(element);
				} else if (element["username"].toUpperCase().indexOf(searchText.toUpperCase()) != -1) {
					filter.push(element);
				}
			}
		}
		self.students = filter;
		self.length = filter.length;
		self.students = filter.slice(0, self.pageSize);
		if (self.pageEvent) {
			self.pageEvent.pageIndex = 0;
		}

	}



}
