import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef} from '@angular/material';
import { Popupcarga } from '../msg/popupcarga';
import Tools from '../tools';
import * as XLSX from 'xlsx';
import { ListService } from '../_services/list.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']

})
export class UserProfileComponent implements OnInit {

  excelInput: any;
	fileInput: any;
	excelReader: FileReader;
	dialogRef: MatDialogRef<Popupcarga, any>;

  identityCard: string;
  username: string;
  name: string
  email: string;
  typeUserName: string;
  

  oldPassModel: string;
  newPassModel: string;
  newPass2Model: string;

  res: object;
  form: FormGroup;



  oldPass: FormControl;

  newPass: FormControl;

  newPass2: FormControl;

  // oldPassFormControl = new FormControl('', [
  //   Validators.required]);

  // newPassFormControl = new FormControl('', [
  //   Validators.required]);

  // newPass2FormControl = new FormControl('', [
  //   Validators.required]);


  constructor(private listServices: ListService) {
    this.username = localStorage.getItem("username");
    this.name = localStorage.getItem("name");
    this.email = localStorage.getItem("email");
    this.identityCard = localStorage.getItem("identityCard");
    this.typeUserName = localStorage.getItem("typeUserName");
    console.log("tipo de usuario: ", this.typeUserName);

  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.oldPass = new FormControl('', Validators.required);

    this.newPass = new FormControl('', Validators.required);

    this.newPass2 = new FormControl('', Validators.required);
  }

  createForm() {
    this.form = new FormGroup({
      changePass: new FormGroup({
        oldPass: this.oldPass,
        newPass: this.newPass,
        newPass2: this.newPass2

      })
    });
  }

  hideSearch() {
    // document.getElementById("menu").classList.toggle("ocultar-search");
    document.getElementById("searchSection").style.display = "none";
    document.getElementById("arrow-btn").style.display = "none";
  }

  searchMobile() {
    document.getElementById("searchSection").style.display = "flex";
    document.getElementById("arrow-btn").style.display = "inline-block";
  }


  onMenu_toggle() {
    document.getElementById("menu").classList.toggle("ocultar");
    document.getElementById("menu-black-background").classList.toggle("hide");
    // document.getElementById("btn-menu").classList.toggle("btn-izq");
    // document.getElementById("btn-menu").innerHTML = (document.getElementById("btn-menu").innerHTML == "menu") ? "close" : "menu";
  }

  onSubmit() {

    let self = this;
    this.listServices.updatePassword(this.username, this.oldPassModel, this.newPassModel).subscribe(

      result => {
        Tools.hideMask();
        // let papi=[];
        this.res = result;
        console.log("que me trajo res: ", this.res);
        this.listServices.showMsg(this.res["msg"]);
        // self.oldPass='';
        // self.newPass='';
        // self.newPass2='';
        self.form.reset();

        // this.listServices.showMsg("Error en el inicio de sesión.");
        // document.getElementById("myForm").reset();
      },
      error => {
        console.log(<any>error);
        this.listServices.showMsg("Error en el inicio de sesión.");

      }
    );

    // return false;

  }

  clickInput(id:string){
    document.getElementById(id).click();
  }

  /// SIMAT --------------------------------------------------------

	prepareUpload(changeEvent: any, to: string){
		
		this.excelReader = new FileReader();
    this.fileInput = changeEvent.currentTarget;

    this.chargeSimat(to);
  }
  
  chargeSimat(to: string){
    // Carga el archivo seleccionado
		console.log("cargando...");
		// Tools.showMask();
		let self = this;
		this.excelReader.onload = function(loadEvent: any) {
			var data = loadEvent.target.result;
			// Tools.hideMask();
			var workbook = XLSX.read(data, { type: 'binary' });
			//   console.log(workbook);
			var first_sheet_name = workbook.SheetNames[0];
			/* Get worksheet */
			var worksheet = workbook.Sheets[first_sheet_name];
			// self.students = XLSX.utils.sheet_to_json(worksheet, { raw: true });
			var SIMAT = XLSX.utils.sheet_to_json(worksheet, { raw: true });
			// console.log("SIMAT:");
			// console.log(SIMAT);
      // console.log("Procesando datos:");
      Tools.showMask();
      if(to == "teachersCauca"){
        self.processTeachersCauca(SIMAT);
      }else if(to == "playersCauca"){
        self.processPlayersCauca(SIMAT);
      } else if (to == "teachersValle"){
        console.log("Aun no existe esta funcionalidad");
        Tools.hideMask();
        // self.processTeachersValle(SIMAT);
      } else if (to == "playersValle") {
        self.processPlayersValle(SIMAT);
      }
		};
		this.excelReader.readAsBinaryString(this.fileInput.files[0]);
  }

  cycleOption: any;


  //Simat docentes de cauca sacando informacion para la tabla de teachers y teachers group
  processTeachersCauca(SIMAT) {
    console.log("Simat docentes cauca",SIMAT);
    let zones = [];
    let towns = [];
    let institutions = [];
    let headquarters = [];
    let groups = [];
    let teachers = [];
    let teacherHasGroups = [];
    let players = [];
    
   
    
    SIMAT.forEach(toAdd => {
      //for zones
      if(zones.find( zone => zone.name === toAdd["ZONA"]) == undefined){
        zones.push({
          name:String(toAdd["ZONA"])
        });
      }
      //for TOWNS
      if(towns.find( town => town.name === toAdd["MUNICIPIO"]) == undefined){
        towns.push({
          name:String(toAdd["MUNICIPIO"])
        });
      }
      //FOR INSTITUTIONS
      if(institutions.find( institution => institution.code === toAdd["CODIGO_DANE"]) == undefined){
        institutions.push({
          code:String(toAdd["CODIGO_DANE"]),
          name:String(toAdd["INSTITUCIÓN EDUCATIVA"])
        });
      }
      //For headquarters
      if(headquarters.find( headquarter => headquarter.code === toAdd["CODIGO_DANE_SEDE"]) == undefined){
        headquarters.push({
          code:String(toAdd["CODIGO_DANE_SEDE"]),
          name:String(toAdd["SEDE"]),
          institutionCode:String(toAdd["CODIGO_DANE"]),
          townName:String(toAdd["MUNICIPIO"]),
          zoneName:String(toAdd["ZONA"])
        });
      }
      //FOR GROUPS
      // let cycle = parseInt(String(toAdd.CICLO).split("")[1]);
      if (String(toAdd.CICLO).length >1) {
        this.cycleOption = parseInt(String(toAdd.CICLO).split("")[1]);
      }else{
        this.cycleOption = parseInt(String(toAdd.CICLO).split("")[0]);
      }
      let groupCode = String(toAdd["GRUPO"]);
      let groupName = String(toAdd["CODIGO GRUPO"]);
      let cycle = this.cycleOption;
      let groupToAdd = {
        idGroup:groupCode,
        cycleId: cycle,
        headquartersCode:String(toAdd["CODIGO_DANE_SEDE"]),
        name:groupName
      };
      if(groups.find( group => group === groupToAdd) == undefined){
        groups.push(groupToAdd);
      }

      //FOR TEACHERS
      if(teachers.find( teacher => teacher.identityCard === toAdd["NUMERO DE CÉDULA"]) == undefined){
        teachers.push({
          identityCard:String(toAdd["NUMERO DE CÉDULA"]),
          name: String(toAdd["NOMBRES Y APELLIDOS"]),
          email: String(toAdd["CORREO"]),
          cellphone: String(toAdd["TELEFONO"])
        });
      }

      //FOR TEACHERS HAS GROUP
      let teacherHasGroupsAdd = {
        teacherIdentityCard:String(toAdd["NUMERO DE CÉDULA"]),
        groupIdGroup:groupCode,
        groupCycleId: cycle,
        groupHeadquartersCode:String(toAdd["CODIGO_DANE_SEDE"]),
        groupHeadquartersName: String(toAdd["SEDE"])
      };
      if(teacherHasGroups.find( teacherHasGroup => teacherHasGroup === teacherHasGroupsAdd) == undefined){
        teacherHasGroups.push(teacherHasGroupsAdd);
      }

      //FOR USERNAME TEACHERS
      let teacherField = String(toAdd["NOMBRES Y APELLIDOS"]);
      let identityCard = String(toAdd["NUMERO DE CÉDULA"]);
      let split = teacherField.split(" ");
      let firstName0 = (split[0] == undefined) ? "" : String(split[0])[0];
      let secondName0 = (split[1] == "-") ? "" : String(split[1])[0];
      let firstName = (split[0] == undefined) ? "" : split[0];
      let secondName = (split[1] == "-") ? "" : split[1];
      let surname = (split[2] == undefined) ? "" : split[2];
      let secondSurname = (split[3] == "-") ? "" : split[3];
      let username = firstName0 +
        secondName0 +
        surname +
        identityCard.slice(identityCard.length - 4, identityCard.length);
      if (players.find(player => player.username === username) == undefined) {
        players.push({
          username: username,
          firstName: firstName,
          secondName: secondName,
          surname: surname,
          secondSurname: secondSurname,
          groupIdGroup: groupCode,
          groupCycleId: cycle,
          groupHeadquartersCode: null
        });
      }
    });

    // console.log("zones:",zones);
    // console.log("towns:",towns);
    // console.log("institutions:",institutions);
    // console.log("headquarters:",headquarters);
    // console.log("groups:",groups);
    // console.log("teachers:",teachers);
    // console.log("teacherHasGroups :",teacherHasGroups);
    // console.log("players", players);
    // console.log ("contador", contador);
    // console.log ("contador2", contador2);
    // console.log("el arreglo de subnormales ", peopleSubNormal);

    let bigJson = {
      players: players,
      zones: zones,
      towns: towns,
      institutions: institutions,
      headquarters: headquarters,
      groups: groups,
      teachers: teachers,
      teacherHasGroups: teacherHasGroups

    }
    this.sendData(bigJson, "saveTeachers");

  }

  //Simat docentes de cauca que ya trae en el excel el username y es de tipo "ZNCAUCA1"
  processNewTeachersCauca(SIMAT) {
    console.log("Simat docentes cauca con usuario ya definido", SIMAT);
    let zones = [];
    let towns = [];
    let institutions = [];
    let headquarters = [];
    let groups = [];
    let players = [];



    SIMAT.forEach(toAdd => {

      //for zones
      if (zones.find(zone => zone.name === toAdd["ZONA"]) == undefined) {
        zones.push({
          name: String(toAdd["ZONA"])
        });
      }
      //for TOWNS
      if (towns.find(town => town.name === toAdd["JERARQUIA"]) == undefined) {
        towns.push({
          name: String(toAdd["JERARQUIA"])
        });
      }
      //FOR INSTITUTIONS
      let headquartersCode = (toAdd["CONSECUTIVO"] == undefined) ? "0" : String(toAdd["CONSECUTIVO"]);
      let institutionCode = (headquartersCode == "0") ? "0" : headquartersCode.slice(0, headquartersCode.length - 2);
      if (institutions.find(institution => institution.code === institutionCode) == undefined) {
        institutions.push({
          code: institutionCode,
          name: String(toAdd["INSTITUCION"])
        });
      }
      //For headquarters
      if (headquarters.find(headquarter => headquarter.code === headquartersCode) == undefined) {
        headquarters.push({
          code: headquartersCode,
          name: String(toAdd["SEDE"]),
          institutionCode: institutionCode,
          townName: String(toAdd["JERARQUIA"]),
          zoneName: String(toAdd["ZONA"])
        });
      }

      //FOR GROUPS
      let cycle = parseInt(String(toAdd["GRADO"]).split("")[1]);
      let groupCode = String(toAdd["GRUPO"]);
      let groupName = String(toAdd["GRUPO"]);
      let groupToAdd = {
        idGroup: groupCode,
        cycleId: cycle,
        headquartersCode: headquartersCode,
        name: groupName
      };
      if (groups.find(group => {
        if (group.idGroup == groupToAdd.idGroup && group.cycleId == groupToAdd.cycleId && group.headquartersCode == groupToAdd.headquartersCode) {
          return true;
        } else {
          return undefined;
        }
      }) == undefined) {
        groups.push(groupToAdd);
      }
      let teacherField = String(toAdd["DOCENTE"]).replace("      ", " ").replace("     ", " ").replace("    ", " ").replace("   ", " ").replace("  ", " ");
      // let identityCard = String(toAdd["NUMERO DE CÉDULA"]);
      let split = teacherField.split(" ");
      if (split.length==2) {
        console.log("entró");
        // contador +=1;
        // let username = "ZCCAUCA"+contador;
        let username = String(toAdd["USERNAME"]);
        if (players.find(player => player.username === username) == undefined) {
        players.push({
          username: username,
          firstName: (split[0] == undefined) ? "" : String(split[0]),
          secondName: null,
          surname: (split[1] == undefined) ? "" : split[1],
          secondSurname: null,
          groupIdGroup: groupCode,
          groupCycleId: cycle,
          groupHeadquartersCode: headquartersCode
        });
      }
      }else if (split.length ==3){
        // contador +=1;
        // let username = "ZCCAUCA" + contador;
        let username = String(toAdd["USERNAME"]);
        if (players.find(player => player.username === username) == undefined) {
          players.push({
            username: username,
            firstName: (split[0] == undefined) ? "" : String(split[0]),
            secondName: null,
            surname: (split[1] == undefined) ? "" : split[1],
            secondSurname: (split[2] == undefined) ? "" : split[2],
            groupIdGroup: groupCode,
            groupCycleId: cycle,
            groupHeadquartersCode: headquartersCode
          });
        }
      }else if (split.length==4){
        // contador += 1;
        let username = String(toAdd["USERNAME"]);
        // let username = "ZCCAUCA" + contador;
        if (players.find(player => player.username === username) == undefined) {
          players.push({
            username: username,
            firstName: (split[0] == undefined) ? "" : String(split[0]),
            secondName: (split[1] == "-") ? "" : String(split[1]),
            surname: (split[2] == undefined) ? "" : split[2],
            secondSurname: (split[3] == undefined) ? "" : split[3],
            groupIdGroup: groupCode,
            groupCycleId: cycle,
            groupHeadquartersCode: headquartersCode
          });
        }
      } else if (split.length > 4) {
        // contador += 1;
        let username = String(toAdd["USERNAME"]);
        // let username = "ZCCAUCA" + contador;
        if (players.find(player => player.username === username) == undefined) {
          players.push({
            username: username,
            firstName: (split[0] == undefined) ? "" : String(split[0]),
            secondName: (split[1] == "-") ? "" : String(split[1]),
            surname: (split[2] == undefined) ? "" : split[2],
            secondSurname: (split[3] == undefined) ? "" : split[3],
            groupIdGroup: groupCode,
            groupCycleId: cycle,
            groupHeadquartersCode: headquartersCode
          });
        }
      
      }
    });


    let bigJson = {
      players: players,
      zones: zones,
      towns: towns,
      institutions: institutions,
      headquarters: headquarters,
      groups: groups
    }
    this.sendData(bigJson, "saveTeachers");

  }

  //Simat estudiantes de cauca
  processPlayersCauca(SIMAT) {
    console.log("simat estudiantes cauca",SIMAT);
    let zones = [];
    let towns = [];
    let institutions = [];
    let headquarters = [];
    let groups = [];
    let players = [];
    let playersElse = [];
    
    
    SIMAT.forEach(toAdd => {

      //for zones
      if(zones.find( zone => zone.name === toAdd["ZONA_SEDE"]) == undefined){
        zones.push({
          name:String(toAdd["ZONA_SEDE"])
        });
      }
      //for TOWNS
      if(towns.find( town => town.name === toAdd["JERARQUIA"]) == undefined){
        towns.push({
          name:String(toAdd["JERARQUIA"])
        });
      }
      //FOR INSTITUTIONS
      let headquartersCode = (toAdd["CONSECUTIVO"]==undefined)?"0":String(toAdd["CONSECUTIVO"]);
      let institutionCode = (headquartersCode=="0")?"0":headquartersCode.slice(0,headquartersCode.length-2);
      if(institutions.find( institution => institution.code === institutionCode) == undefined){
        institutions.push({
          code:institutionCode,
          name:String(toAdd["INSTITUCION"])
        });
      }
      //For headquarters
      if(headquarters.find( headquarter => headquarter.code === headquartersCode) == undefined){
        headquarters.push({
          code:headquartersCode,
          name:String(toAdd["SEDE"]),
          institutionCode:institutionCode,
          townName:String(toAdd["JERARQUIA"]),
          zoneName:String(toAdd["ZONA_SEDE"])
        });
      }
      
      //FOR GROUPS
      // let cycle = parseInt(String(toAdd["GRADO_COD"]).split("")[1]);
      let cycle:any;
      if (String(toAdd["GRADO_COD"]).length>1) {
        cycle = parseInt(String(toAdd["GRADO_COD"]).split("")[1]);
        
      }else{
        cycle = parseInt(String(toAdd["GRADO_COD"]));
      }
      // let cycle = parseInt(String(toAdd["GRADO_COD"]).split("")[1]);
      let groupCode = String(toAdd["GRUPO"]);
      let groupName = String(toAdd["GRUPO"]);
      let groupToAdd = {
        idGroup:groupCode,
        cycleId:cycle,
        headquartersCode:headquartersCode,
        name:groupName
      };
      if(groups.find( group => {
        if(group.idGroup == groupToAdd.idGroup && group.cycleId == groupToAdd.cycleId && group.headquartersCode == groupToAdd.headquartersCode){
          return true;
        }else{
          return undefined;
        }
      }) == undefined){
        groups.push(groupToAdd);
      }
      
    
      let identityCard = String(toAdd["DOC"]);
      let firstName0 = (toAdd["NOMBRE1"]==undefined)?"":toAdd["NOMBRE1"][0];
      let secondName0 = (toAdd["NOMBRE2"]==undefined)?"":toAdd["NOMBRE2"][0];
      let surname = (toAdd["APELLIDO1"]==undefined)?"":toAdd["APELLIDO1"];
      let username =  firstName0 +
                      secondName0 +
                      surname +
                      identityCard.slice(identityCard.length -4 ,identityCard.length);
      if(players.find( player => player.username === username) == undefined){
        players.push({
          username:username,
          firstName:(toAdd["NOMBRE1"]==undefined)?null:String(toAdd["NOMBRE1"]),
          secondName:(toAdd["NOMBRE2"]==undefined)?null:String(toAdd["NOMBRE2"]),
          surname:(toAdd["APELLIDO1"]==undefined)?null:String(toAdd["APELLIDO1"]),
          secondSurname:(toAdd["APELLIDO2"]==undefined)?null:String(toAdd["APELLIDO2"]),
          groupIdGroup:groupCode,
          groupCycleId:cycle,
          groupHeadquartersCode:headquartersCode
        });
      }
      // else{
      //   playersElse.push({
      //     username: username,
      //     firstName: (toAdd["NOMBRE1"] == undefined) ? null : String(toAdd["NOMBRE1"]),
      //     secondName: (toAdd["NOMBRE2"] == undefined) ? null : String(toAdd["NOMBRE2"]),
      //     surname: (toAdd["APELLIDO1"] == undefined) ? null : String(toAdd["APELLIDO1"]),
      //     secondSurname: (toAdd["APELLIDO2"] == undefined) ? null : String(toAdd["APELLIDO2"]),
      //     groupIdGroup: groupCode,
      //     groupCycleId: cycle,
      //     groupHeadquartersCode: headquartersCode
      //   });
      // }
    });


    let bigJson = {
      players:players,
      zones:zones,
      towns:towns,
      institutions:institutions,
      headquarters:headquarters,
      groups:groups
    }
    this.sendData(bigJson, "saveSimat");


  }

  //Simat estudiantes de valle
  processPlayersValle(SIMAT) {
    console.log("simat estudiantes valle",SIMAT);
    console.log("tamaño", SIMAT.length);
    let zones = [];
    let towns = [];
    let institutions = [];
    let headquarters = [];
    let groups = [];
    let players = [];
    let duplicatedUsers = [];
    // let contador = 0;
    
    SIMAT.forEach(toAdd => {
      
      
      //for zones
      let zoneName="V-"+ toAdd["ZONA"];
      if(zones.find( zone => zone.name === zoneName) == undefined){
        zones.push({
          name:String(zoneName)
        });
      }
      //for TOWNS
      if (towns.find(town => town.name === toAdd["MUNICIPIO"]) == undefined){
        towns.push({
          name:String(toAdd["MUNICIPIO"])
        });
      }
      //FOR INSTITUTIONS
      let headquartersCode = (toAdd["ID_SEDE"]==undefined)?"0":String(toAdd["ID_SEDE"]);
      let institutionCode = (toAdd["ID_INSTITUCION"] == undefined) ? "0" : String(toAdd["ID_INSTITUCION"]);
      if(institutions.find( institution => institution.code === institutionCode) == undefined){
        institutions.push({
          code:institutionCode,
          name:String(toAdd["INSTITUCION"])
        });
      }
      //For headquarters
      if(headquarters.find( headquarter => headquarter.code === headquartersCode) == undefined){
        headquarters.push({
          code:headquartersCode,
          name:String(toAdd["SEDE"]),
          institutionCode:institutionCode,
          townName:String(toAdd["MUNICIPIO"]),
          zoneName:String(zoneName)
        });
      }
      
      
      //FOR GROUPS
      let cycle = parseInt(toAdd["CICLO"]);
      let groupCode = String(toAdd["ID_GRUPO"]);
      let groupName = String(toAdd["ID_GRUPO"]);
      let groupToAdd = {
        idGroup:groupCode,
        cycleId:cycle,
        headquartersCode:headquartersCode,
        name:groupName
      };
      if(groups.find( group => {
        if(group.idGroup == groupToAdd.idGroup && group.cycleId == groupToAdd.cycleId && group.headquartersCode == groupToAdd.headquartersCode){
          return true;
        }else{
          return undefined;
        }
      }) == undefined){
        groups.push(groupToAdd);
      }
      
    
      // let identityCard = String(toAdd["DOCUMENTO"]);

      let identityCard = (toAdd["DOCUMENTO"] == undefined) ? "" : String(toAdd["DOCUMENTO"]).trim();
      let firstName = (toAdd["PRIMER_NOMBRE"] == undefined) ? "" : String(toAdd["PRIMER_NOMBRE"]).trim();
      let secondName = (toAdd["SEGUNDO_NOMBRE"] == undefined) ? "" : String(toAdd["SEGUNDO_NOMBRE"]).trim();
      let surname = (toAdd["PRIMER_APELLIDO"] == undefined) ? "" : String(toAdd["PRIMER_APELLIDO"]).trim();
      let secondSurname = (toAdd["SEGUNDO_APELLIDO"] == undefined) ? "" : String(toAdd["SEGUNDO_APELLIDO"]).trim();



      let firstName0 = (toAdd["PRIMER_NOMBRE"]==undefined)?"":firstName[0];
      let secondName0 = (toAdd["SEGUNDO_NOMBRE"]==undefined)?"":secondName[0];

  
      let username =  firstName0 +
                      secondName0 +
                      surname +
                      identityCard.slice(identityCard.length -4 ,identityCard.length);
      if(players.find( player => player.username === username) == undefined){
        players.push({
          username:username,
          firstName:firstName,
          secondName:secondName,
          surname:surname,
          secondSurname:secondSurname,
          groupIdGroup:groupCode,
          groupCycleId:cycle,
          groupHeadquartersCode:headquartersCode
        });

      }else{
        // do {
        //   aux =username + String(count);

        //   if (players.find(player => player.username === aux) == undefined) {
        //     players.push({
        //       username: aux,
        //       firstName: firstName,
        //       secondName: secondName,
        //       surname:  surname,
        //       secondSurname: secondSurname,
        //       groupIdGroup: groupCode,
        //       groupCycleId: cycle,
        //       groupHeadquartersCode: headquartersCode
        //     });
        //     added = true;
        //   }
        //   count +=1;
          
        // } while (!added);
        // duplicatedUsers.push({
        //   identityCard: identityCard,
        //   username: aux
        // });
        // console.log("estos son los hptas usuarios que se repiten: ",aux);
        
      }
    });


    let bigJson = {
      players:players,
      zones:zones,
      towns:towns,
      institutions:institutions,
      headquarters:headquarters,
      groups:groups
    }
    this.sendData(bigJson, "saveSimat");

  }

  sendData(bigJson:any, to:any){
    let weAreTesting = true;

    if (weAreTesting) {
      console.log("big json", bigJson);
      Tools.hideMask();
    } else {
      if (to == "saveTeachers") {
        this.listServices.saveTeachers(bigJson).subscribe(
          result => {
            Tools.hideMask();console.log("Resultado del servicio...");
            console.log(result);
            console.log("...");
            this.listServices.showMsg("Jugadores agregados correctamente.");
          },
          error => {
            console.log(<any>error);
            this.listServices.showMsg("Error al intentar guardar a los jugadores.");
          }
        );
      } else if (to == "saveSimat"){
        this.listServices.saveSimat(bigJson).subscribe(
          result => {
            Tools.hideMask();console.log("Resultado del servicio...");
            console.log(result);
            console.log("...");
            this.listServices.showMsg("Jugadores agregados correctamente.");
          },
          error => {
            console.log(<any>error);
            this.listServices.showMsg("Error al intentar guardar a los jugadores.");
          }
        );
      }
    }


  }
}
