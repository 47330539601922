import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mask',
  templateUrl: './mask.component.html',
  styleUrls: ['./mask.component.scss']
})
export class MaskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
